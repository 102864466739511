import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AlertDialogComponent } from './alert-dialog.component';

@Injectable()
export class AlertDialogService {


  constructor(private modalService: NgbModal) { }

  public confirm(



    title: string,
    message: string,
    noOfButton:number ,
    btnOkText: string ='Yes',
    btnCancelText: string = 'No',


    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {

      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        size: dialogSize ,
      };

    const modalRef = this.modalService.open(AlertDialogComponent, ngbModalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.numberOfButton = noOfButton;

    return modalRef.result;
  }

}
