import { Injectable } from '@angular/core';
import { HttpServiceProvider } from '.';
import { City } from '../models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpServiceProvider: HttpServiceProvider) { }



  loginUser(userPostData) {

    //  let url = appServerResources.SERVER_PATH + "GlobalLoginService" + appServerResources.VERSION + "/login";
    // return this.httpServiceProvider.postForUrlEncodedType("/GlobalLoginService", "/login", userPostData);
    return this.httpServiceProvider.postForUrlEncodedType("/GlobalLoginService", "/CustomerService/login", userPostData);
  }

  signupUser(userPostData) {

    //  let url = appServerResources.SERVER_PATH + "UserManagementService" + appServerResources.VERSION + "/addNirvanaXPUser";
    return this.httpServiceProvider.post("/UserManagementService", "/CustomerService/addNirvanaXPUserForWebsite", userPostData, 0);
  }
  updateNirvanaXPUserForWebsite(userPostData) {
    return this.httpServiceProvider.post("/UserManagementService", "/CustomerService/updateNirvanaXPUserForWebsite", userPostData, 0);
  }
  getLocalUserByEmail(email) {
    return this.httpServiceProvider.get("/UserManagementService", "/CustomerService/getLocalUserByEmail/" + email, 0);
  }
  getLocalUserByPhoneNumber(phone) {
    return this.httpServiceProvider.get("/UserManagementService", "/CustomerService/getLocalUserByPhoneNumber/" + phone, 0);
  }
  getUserByEmail(email) {
    return this.httpServiceProvider.get("/UserManagementService", "/CustomerService/getUserByEmail/" + email, 1);
  }
  getUserByPhoneNumber(phone) {
    return this.httpServiceProvider.get("/UserManagementService", "/CustomerService/getUserByPhoneNumber/" + phone, 1);
  }

  getUserByGlobalUsersId(globalUserId, locationId) {
    return this.httpServiceProvider.get<any>(
      "/UserManagementService",
      "/CustomerService/getUserByGlobalUsersIdForWebsite/" + globalUserId + "/" + locationId, 0
    );
  }

  sendSMSForVerificationCode(phone, locationId,propertyId) {
    return this.httpServiceProvider.get("/UserManagementService", "/CustomerService/sendSMSForVerificationCode/" + phone + "/" + locationId+ "/" + propertyId, 0);
  }
  // sendSMSVerificationCode(phone,propertyId) {
  //   return this.httpServiceProvider.get("/UserManagementService", "/CustomerService/sendSMSForVerificationCodeForWebsite/" + phone + "/" + propertyId, 0);
  // }
  sendSMSVerificationCode(phone,propertyId) {
    return this.httpServiceProvider.get("/UserManagementService", "/CustomerService/sendSMSVerificationCode/" + phone + "/" + propertyId, 0);
  }
  validateUserAuthCode(otp) {
    return this.httpServiceProvider.get("/GlobalAccountService", "/CustomerService/validateUserAuthCode/" + otp, 0);
  }

  updateUserPassword(userPostData) {
    return this.httpServiceProvider.post("/GlobalAccountService", "/CustomerService/updateUserPassword", userPostData, 0);
  }
  addNirvanaXPUserForWebsiteByPhone(userPostData) {
    return this.httpServiceProvider.post("/UserManagementService", "/CustomerService/addNirvanaXPUserForWebsiteByPhone", userPostData, 0);
  }

  deleteAddressByAddressId(addressId,userId)
  {
    return this.httpServiceProvider.get("/UserManagementService", "/CustomerService/deleteAddress/" + addressId + "/" + userId, 0);
  }

  signupForBusiness(user) {
    return this.httpServiceProvider.get("/GlobalBusinessService", "/CustomerService/sendBusinessData/" + user.firstName +"/" + user.lastName + "/"+user.email+"/"+ user.phone + "/"+ user.area +"/"+user.restaurant, 0);
    }

    
  
}

