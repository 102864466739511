import { HttpServiceProvider } from './http.service';
import { Injectable } from '@angular/core';

@Injectable()
export class OrderService {
  constructor(private httpServiceProvider: HttpServiceProvider) { }

  getAllCategoriesForWebsite(locationId) {
    return this.httpServiceProvider.get('/CatalogService', '/CustomerService/getAllCategoriesForWebsite/' + locationId, 0);
  }
  getAllCategoriesForWebsiteContactlessMenu(locationId) {
    return this.httpServiceProvider.get('/CatalogService', '/CustomerService/getAllCategoriesForWebsiteContactlessMenu/' + locationId, 0);
  }
  getAllOrderSouceGroupDisplayByLocationId(locationId) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/getAllOrderSouceGroupDisplayByLocationId/' + locationId, 0);
  }

  getOSGNameAndOSNameByOrderSourceId(sourceID) {
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getOSGNameAndOSNameByOrderSourceId/' + sourceID, 0);
  }

  getAllDataByBusinessId(businessId) {
    return this.httpServiceProvider.get('/LocationsService', '/CustomerService/getAllDataByBusinessId/' + businessId, 0);
  }

  getAllLocationToFunctionForCustomerByLocationId(locationId){
    return this.httpServiceProvider.get('/LocationsService', '/CustomerService/getAllLocationToFunctionForCustomerByLocationId/' + locationId, 0);
  }

  getOnlineOrderPaidById(orderId){
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getOnlineOrderPaidById/' + orderId, 0);
  }

  getBusinessHoursByLocationId(locationId) {
    return this.httpServiceProvider.get('/LocationsService', '/CustomerService/getBusinessHoursByLocationId/' + locationId, 0);
  }
  getShiftSlotsByOrderSourceId(orderSourceId, date, fromTime) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/getShiftSlotsByOrderSourceId/' + orderSourceId + "/" + date + "/" + fromTime, 0);
  }

  holdShiftSlotForSlotId(slotPost) {
    return this.httpServiceProvider.post('/LookupService', '/CustomerService/holdShiftSlotForSlotId', slotPost, 0);
  }

  addUpdateOnlineOrder(orderPost) {
    return this.httpServiceProvider.post('/OrderManagementService', '/CustomerService/addUpdateOnlineOrder', orderPost, 0);
  }
  updateOrderAfterSave(orderPost) {
    return this.httpServiceProvider.post('/OrderManagementService', '/CustomerService/updateOrderAfterSave', orderPost, 0);
  }

  getCustomerCard(orderSourceId, userId) {
    return this.httpServiceProvider.get('/POSNProcessPaymentService', '/PaymentService/getCustomerCard/' + orderSourceId + "/" + userId, 0);
  }

  getUserWithAddressById(userId) {
    return this.httpServiceProvider.get('/UserManagementService', '/CustomerService/getUserWithAddressById/' + userId, 0);
  }

  getAllStatesByCountryId(stateId) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/getAllStatesByCountryId/' + stateId, 0);
  }

  getAllCityByStateId(cityId) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/getAllCityByStateId/' + cityId, 0);
  }

  getOrdersByUserIdAndLocationIdAndPaidStatus(locationsId, userId) {
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getOrdersByUserIdAndLocationIdAndPaidStatus/' + userId + "/" + locationsId, 0);
  }

  getOnlineOrderById(orderId){
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getOnlineOrderById/' + orderId, 0);
  }
  getExperienceOrderId(orderId){
    return this.httpServiceProvider.get('/CustomerExperienceService', '/CustomerService/getExperienceOrderId/' + orderId, 0);
  }
  getAllOrderStatusByLocationIdAndOrderSourceGroupId(locationsId, orderSourceGroupId) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/getAllOrderStatusByLocationIdAndOrderSourceGroupId/' + locationsId + "/" + orderSourceGroupId, 0);
  }

  getOrderStatusHistoryByOrderId(order_id) {
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getOrderStatusHistoryByOrderId/' + order_id, 0);
  }

  getDeliveryPartnerByOrderId(order_id){
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getDeliveryPartnerByOrderId/' + order_id, 0);
  }

  getBraintreeTokenForWebsite(orderSourceId, userId) {
    return this.httpServiceProvider.get('/POSNProcessPaymentService', '/PaymentService/getBraintreeTokenForWebsite/' +  userId+"/"+orderSourceId, 0);
  }

  addRemoveDiscount(orderPost) {
    return this.httpServiceProvider.post('/OrderManagementService', '/CustomerService/addRemoveDiscount', orderPost, 0);
  }
  updateCustomer(userPost) {
    return this.httpServiceProvider.post('/UserManagementService', '/CustomerService/update', userPost, 0);
  }

  addForWebsite(reviewPostObject){
    return this.httpServiceProvider.post('/CustomerExperienceService', '/CustomerService/addForWebsite', reviewPostObject, 0);
  }

  getAllPaidOrderByEmailOrPhone(locationId,userId){
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getAllPaidOrderByEmailOrPhone/' +  userId+"/"+locationId, 0);
  }

  getOrdersByUserIdAndLocationId(locationId,userId){
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getOrdersByUserIdAndLocationId/' +  userId+"/"+locationId, 0);
  }
  
  authorizeOrderForBrainTreeForWebsite(orderPost, nounce, orderSourceId, token, locationId) {
    return this.httpServiceProvider.post('/POSNProcessPaymentService', '/PaymentService/authorizeOrderForBrainTreeForWebsite/'+nounce+'/'+orderSourceId+'/'+token+'/'+locationId, orderPost, 0);
  }

  authorizeOrderForStripeForWebsite(orderPost, nounce, orderSourceId, token, locationId) {
    return this.httpServiceProvider.post('/POSNProcessPaymentService', '/PaymentService/authorizeOrderForStripeForWebsite/'+orderSourceId+'/'+nounce+'/'+locationId+'/'+token, orderPost, 0);
  }

  unHoldShiftSlotForClientId(holdSlotId) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/unHoldShiftSlotForClientId/' + holdSlotId, 0);
  }

  getStripeKeyForWebsite(orderSourceId) {
    return this.httpServiceProvider.get('/POSNProcessPaymentService', '/PaymentService/getStripeKeyForWebsite/' +  orderSourceId, 0);
  }

  authorize3DAuthenticationForWebsite(orderSourceId,locationId,orderPostPacket, isRazorpay) {
    return this.httpServiceProvider.post('/POSNProcessPaymentService', '/PaymentService/authorizeAuthenticationForWebsite/'+orderSourceId+'/'+locationId+"/"+isRazorpay, orderPostPacket, 0);
  }

  authorizeSuccessfulForWebsite(orderSourceId,locationId,isOrderAhead,paymentIntentId,orderPostPacket) {
    return this.httpServiceProvider.post('/POSNProcessPaymentService', '/PaymentService/authorizeSuccessfulForWebsite/'+orderSourceId+"/"+locationId+"/"+isOrderAhead+"/"+paymentIntentId, orderPostPacket, 0);
  }

  authorizeSuccessfulForRazorPayWebsite(orderSourceId,locationId,isOrderAhead,razorPayOrderId,razorPayPaymentId,razorPaySignature,orderPostPacket) {
    return this.httpServiceProvider.post("/POSNProcessPaymentService","/PaymentService/authorizeSuccessfulForRazorPay/"+orderSourceId+"/"+locationId+"/"+isOrderAhead+"/"+razorPayOrderId+"/"+razorPayPaymentId+"/"+razorPaySignature, orderPostPacket, 0);
  }

  getDeliveryRuleByLocationId(locationId) {
    return this.httpServiceProvider.get('/LocationsService', '/CustomerService/deliveryRule/'+locationId, 0);
  }
  //getDeliveryQuote/{isTest}/{locationId}/{name}/{pickup_lat}/{pickup_lng}/{drop_lat}/{drop_lng}/{category_id}
  getDeliveryChargeByDunzoApi(isTest, locationId, name, orderid, pickup_lat, pickup_lng, drop_lat, drop_lng, category_id) {
    return this.httpServiceProvider.get('/OrderManagementService', '/CustomerService/getDeliveryQuote/'+ isTest + "/" + locationId+ "/" + name+ "/" + orderid+ "/"+ pickup_lat+ "/" + pickup_lng+ "/" + drop_lat+ "/" + drop_lng+ "/" + category_id, 0);
  }

  getDeliveryQuoteForUberDirect(locationId,orderId,orderPostPacket) {
    return this.httpServiceProvider.post('/OrderManagementService','/CustomerService/getDeliveryQuoteForUberDirect/' + locationId + '/' +orderId ,orderPostPacket,0);
  }
  
}
