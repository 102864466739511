import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ScheduleModalService } from './schedule-modal.service';
import { OrderService, LocationService } from '../../../core';
import * as moment from 'moment';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-modal-schedule',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.css']
})
export class ScheduleModalComponent implements OnInit, OnDestroy {
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter();
  @Input() id: string;
  private element: any;
  @Input()
  selectedOrderGroup;
  model: NgbDateStruct;
  gmt: string;
  date: { year: number, month: number, day: number };
  locationDetails: any;
  businessTime:any;
  businessStartTime:any;
  businessEndTime:any;


  currentDate: string = null; 
  @ViewChild('scheduleModal', { static: true }) basicModal: any;
  @ViewChild('dp', { static: true }) dp: NgbDatepicker;


  slots: any = [];
  constructor(private modalService: ScheduleModalService, private el: ElementRef,
    private orderService: OrderService, private locationService: LocationService, private calendar: NgbCalendar, private spinnerService: Ng4LoadingSpinnerService) {
    this.element = el.nativeElement;

    this.gmt = localStorage.getItem('gmt');
  }


  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }
  ngOnInit(): void {
    let modal = this;


    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {

 
   this.businessTime = JSON.parse(localStorage.getItem('businessTime'));
   this.businessStartTime = JSON.parse(localStorage.getItem('businessStartTime'));
   this.businessEndTime = JSON.parse(localStorage.getItem('businessEndTime'));
  
    let now = moment().utcOffset(this.gmt).format('YYYY-MM-DD');
    this.currentDate = moment().utcOffset(this.gmt).format('YYYY-MM-DD');
    this.date = {
      year: parseInt(moment().utcOffset(this.gmt).format('YYYY')),
      month: parseInt(moment().utcOffset(this.gmt).format('MM')),
      day: parseInt(moment().utcOffset(this.gmt).format('DD'))
    }

    let isBusinessClosed = true;
    let time = moment().utcOffset(localStorage.getItem('gmt')).format('HH:mm:ss');
    let currentTime = moment().utcOffset(localStorage.getItem('gmt')).format('hh:mm a');

    console.log(this.selectedOrderGroup);

     for (let i in this.businessTime) {
      var format = 'hh:mm a'
      let str = this.businessTime[i].split('-');
      // var time = moment() gives you current time. no format required.
      var timeCheck = moment(currentTime, format),
        beforeTime = moment(str[0], format),
        afterTime = moment(str[1], format);
      if (timeCheck.isBetween(beforeTime, afterTime)) {
        isBusinessClosed = false
      }
      else {
        isBusinessClosed = true
      }
    }

    

let businessHours = JSON.parse(localStorage.getItem("businessHours"));

  console.log("businessHours",businessHours) ; 


let dateD = moment(now); 
var dayofWeek = dateD.day()+ 1;
      console.log("dayofWeek",dayofWeek) ; 


     let businessHourList = [];
     for (let index = 0; index < businessHours.length; index++) {
        const element = businessHours[index];
        if (dayofWeek == element.day.id) {

          
          if (element.isClosed != 1) {
             businessHourList.push(element);
             isBusinessClosed = false;
           
          }

        }
      }

    this.slots = [];
    if(!isBusinessClosed)
    this.locationService.getShiftSlotsByOrderSourceId(this.selectedOrderGroup.orderSouceDisplayPackets.id, now, time).subscribe((data: any) => {
      let responseSlot = data;
      for(let slot of responseSlot) {
      for (let hour of businessHourList){
      let format = 'HH:mm:ss'
      // var time = moment() gives you current time. no format required.
     
      let timeCheck = moment(slot.slotTime,format);

     let  beforeTime = moment(hour.timeFrom, format);
      let  afterTime = moment(hour.timeTo, format);
      if (timeCheck.isBetween(beforeTime, afterTime)) {
        this.slots .push(slot)
      }
      }
      }

      let uniqueSet = new Set(this.slots);
      this.slots = [];
      this.slots = [...uniqueSet];

    });
    this.basicModal.show();
  }

  convertTime(slot) {
    var str = moment.utc(slot.date + " " + slot.slotTime);
    var now = moment(str).format('hh:mm A');
    var now1 = moment(str).add(slot.slotInterval, 'minutes').format('hh:mm A');

    return now + '-' + now1
  }

  selectedSlotId = 0;
  holdShiftSlot(slot) {
    this.selectedSlotId = slot.id;
    console.log('slot', slot)
    var slotObj = {
      'id': slot.id,
      'updatedBy': 1,
      'createdBy': 1,
      'status': 'A',
      'slotTime': 0,
      'slotInterval': 0,
      'locationId': 0,
      'created': 0,
      'updated': 0,
    };
    var shiftSlots = {
      "shiftSlots": slotObj
    };
    var holdslot = {
      "HoldShiftSlotPacket": shiftSlots
    };
    if (localStorage.getItem("idOfHoldingClientObj") != "undefined" && localStorage.getItem("idOfHoldingClientObj")) {
      this.locationService.unHoldShiftSlotForClientId(localStorage.getItem("idOfHoldingClientObj")).subscribe((data: any) => {
        console.log('unhold', data)
      });
    }
    this.spinnerService.show();
    this.locationService.holdShiftSlotForSlotId(holdslot).subscribe((data: any) => {
      this.spinnerService.hide();
      if (data > 0) {
        var str = moment.utc(slot.date + " " + slot.slotTime);
        var now = moment(str).format('YYYY-MM-DD HH:mm:ss');
        localStorage.setItem("idOfHoldingClientObj", data);
        localStorage.setItem("required_pickuptime", now);
        var countDownDate = new Date();
        countDownDate.setMinutes(countDownDate.getMinutes() + 10);
        localStorage.slotId = slot.id;
        localStorage.countDown = countDownDate;
        localStorage.startDate = countDownDate;
      }
    });
  }

  onDateSelect(event) {
    this.slots =[];
    // console.log("event ===================================================",event.next);
    if (event.month < 10) {
      event.month = '0' + event.month;
    }
    if (event.day < 10) {
      event.day = '0' + event.day;
    }
    let now = event.year + '-' + event.month + '-' + event.day;
    var time = "";
    if (now == moment().utcOffset(this.gmt).format('YYYY-MM-DD'))
      time = moment().utcOffset(localStorage.getItem('gmt')).format('HH:mm:ss');
    else
      time = "00:00:00";

let businessHours = JSON.parse(localStorage.getItem("businessHours"));

  console.log("businessHours",businessHours) ; 


let dateD = moment(now); 
var dayofWeek = dateD.day()+ 1;
      console.log("dayofWeek",dayofWeek) ; 
let currentTime = moment().utcOffset(localStorage.getItem('gmt')).format('hh:mm a');
let isBusinessClosed = true;

console.log(this.businessTime);
 for (let i in this.businessTime) {
      var format = 'hh:mm a'
      let str = this.businessTime[i].split('-');
      // var time = moment() gives you current time. no format required.
      var timeCheck = moment(currentTime, format),
        beforeTime = moment(str[0], format),
        afterTime = moment(str[1], format);
      if (timeCheck.isBetween(beforeTime, afterTime)) {
        isBusinessClosed = false
      }
      else {
        isBusinessClosed = true
      }
    }


    let businessHourList = [];
     for (let index = 0; index < businessHours.length; index++) {
        const element = businessHours[index];
        if (dayofWeek == element.day.id) {

          
          if (element.isClosed != 1) {
             businessHourList.push(element);
             isBusinessClosed = false;
           
          }

        }
      }

    if(!isBusinessClosed)
    this.locationService.getShiftSlotsByOrderSourceId(this.selectedOrderGroup.orderSouceDisplayPackets.id, now, time).subscribe((data: any) => {
     
      let responseSlot = data;
      for(let slot of responseSlot) {
      for (let hour of businessHourList){
      let format = 'HH:mm:ss'
      // var time = moment() gives you current time. no format required.
     
      let timeCheck = moment(slot.slotTime,format);

     let  beforeTime = moment(hour.timeFrom, format);
      let  afterTime = moment(hour.timeTo, format);
      if (timeCheck.isBetween(beforeTime, afterTime)) {
        this.slots .push(slot)
      }
      }
      }
      let uniqueSet = new Set(this.slots);
      this.slots = [];
      this.slots = [...uniqueSet];
    });
  }

  // close modal
  close(): void {
    this.basicModal.hide();
    this.onClose.emit(true);
  }


}