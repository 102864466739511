import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UserModalService } from './user-modal.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PageProperty, UserService, LocationService, City, Country } from '../../../core';


@Component({
    selector: 'app-modal-user',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    private element: any;
    title: string = "Login";
    pageProperty: PageProperty;

    redColorHex: string = "#ff0016";
    compareWith: any;
    loginForm: FormGroup;
    isSubmitted: boolean = false;
    loginInvalid: boolean = false;
    loading: any;
    cities: City[];
    isHomePage: boolean = false;
    toSelect: Country;
    countryData: Country[];
    filteredOptions: Country[];

    @ViewChild('userModal', { static: true }) basicModal: any;


    constructor(private modalService: UserModalService, private el: ElementRef, public formBuilder: FormBuilder, private userService: UserService
        , private locationService: LocationService) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        let modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
        

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
        this.loginForm = this.formBuilder.group({
            countryCode: ['', [Validators.required]],
            uname: ['', [Validators.required, Validators.minLength(10)]],
            password: ['', [Validators.required, Validators.minLength(2)]],
        })

        this.cities = JSON.parse(localStorage.getItem("cities"));
        this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
        this.countryData = JSON.parse(localStorage.getItem("countries"));
        this.filteredOptions = this.countryData;
        console.log("countryData",this.countryData);

        if (!this.countryData || this.countryData.length == 0)
            this.locationService.getAllCountries()
                .subscribe((response: any) => {
                    this.countryData = response;
                    this.filteredOptions = response;
                    console.log(response);
                    localStorage.setItem("countries", JSON.stringify(this.countryData));


                });

        this.toSelect = this.countryData.find(c => c.phoneCode == 1);
        this.loginForm.get('countryCode').setValue(this.toSelect.phoneCode);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.basicModal.show();
    }

    // close modal
    close(): void {
        this.basicModal.hide();
    }

    get errorControl() {
        return this.loginForm.controls;
    }

    onKey(value) {
        console.log(value);
        value = value.toLowerCase()
        if (!value) {
    
          console.log('fulldayta');
    
          this.countryData = this.filteredOptions;
        }
        else {
          console.log('dayta');
          this.countryData = this.filteredOptions;
          this.countryData = this.search(value);
        }
      }

    search(value: any): Country[] {
        let filter = value.toLowerCase();
        return this.countryData.filter(country => {
            return (
                country.displayName
                    .toLowerCase()
                    .includes(value) ||
                country.phoneCode.toString()
                    .toLowerCase()
                    .includes(value)
            );
        });





    }

    onChange(deviceValue) {
        console.log(deviceValue);
    }

    submitLoginForm() {
        this.isSubmitted = true;

        if (!this.loginForm.valid) {
            console.log('Please provide all the required values!')
            return false;
        } else {

            // this.presentLoading();

            console.log(this.loginForm.value)


            let deviceId = "nirvana-customer";
            let deviceName = "nirvana-customer";

            let user = {
                'username': this.loginForm.value.countryCode + "-" + this.loginForm.value.uname,
                'password': this.loginForm.value.password,
                'rolesName': "POS Customer",
                'deviceId': deviceId,
                'deviceTypeId': '4',
                'deviceName': deviceName,
                'ipAddress': deviceId,
                'versionInfo': "v1.0.0"
            }

            this.userService.loginUser(user)
                .subscribe((res: any) => {
                    console.log(res);
                    // this.loading.dismiss();
                    if (res.displayMessage)
                        alert(res.displayMessage);
                    else {
                        // this.presentAlert('User Logged In Successfully')
                        localStorage.setItem('currentUser', JSON.stringify(res));
                        // this.router.navigateByUrl('home');
                    }
                }, err => {
                    console.log(err);
                    //this.loading.dismiss();
                    alert(err)
                    this.loginInvalid = false;

                });

        }
    }

    async presentAlert(message) {

    }

    async presentLoading() {

    }



}