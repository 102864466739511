import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrackOrderRoutingModule } from './track-order-routing.module';
import { TrackOrderComponent } from './track-order.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SharedModule } from '../shared';
import { MainHeaderModule } from '../shared/layout/main-header.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonFooterModule } from '../shared/layout/common-footer.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';


@NgModule({
  declarations: [TrackOrderComponent],
  imports: [
    CommonModule,
    TrackOrderRoutingModule,
    MDBBootstrapModule.forRoot(),
    SharedModule,
    CommonModule,
    MainHeaderModule,
    NgbModule,Ng2SearchPipeModule,
  ]
})
export class TrackOrderModule { }
