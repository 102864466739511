<div mdbModal #scheduleModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h6 class="modal-title w-100" id="myModalLabel">
          <mdb-icon far icon="clock"></mdb-icon>
          Please select a slot for ordering later
        </h6>
      </div>
      <div class="modal-body">

        <div class="row justify-content-start" style="margin-top: 20px; text-align: center;">
          <div class="col-12 justify-content-start">
            Select Date
            <!-- Material inline form -->
            <label class="sr-only" for="inlineFormInputGroupUsername2">Select Date</label>
            <div class="input-group mb-2 mr-sm-2" style="margin: 0px 30px;width: 86%;">

              <input class="form-control" name="dp" [value]="currentDate"
               ngbDatepicker #d="ngbDatepicker"  (click)="d.toggle()" (dateSelect)="onDateSelect($event)" 
               [minDate]="date">
               
              <div class="input-group-prepend">
                <div class="input-group-text"  (click)="d.toggle()">
                  <mdb-icon far icon="calendar-alt"></mdb-icon>
                </div>
              </div>
            </div>
            <p>Available Slots</p>
            <div style="max-height: 200px;overflow-y: scroll;" class="row justifycontent-center">
              <div class="col-md-6" *ngFor="let slot of slots; let i = index" >
              <button type="button" mdbBtn [ngClass]="selectedSlotId==slot.id?'relative btn-outline slot-button':'relative slot-button'" style="width:100%" (click)="holdShiftSlot(slot)">
                <span *ngIf="slot.isBlocked!=1">{{convertTime(slot)}}</span>
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="close()" mdbBtn class="relative waves-light " mdbWavesEffect style="background-color:var(--background-color,red);
          color:#fff;width: 100%; border-radius: var(--border-Radius);
    text-transform: none;">Start Ordering</button>
      </div>
    </div>
  </div>
</div>