<div class="text-center">
<h1 mat-dialog-title>Please select time</h1>
<div mat-dialog-content >

  <ngb-timepicker style="width: 125px;float: center;
  height: 125px;"[(ngModel)]="time" [formControl]="ctrl" [minuteStep]="minuteStep"  required></ngb-timepicker>
  <div *ngIf="ctrl.valid" class="small form-text text-success">Great choice</div>
  <div *ngIf="ctrl.errors" class="small form-text text-danger">
    <div *ngIf="ctrl.errors['required']">Select some time during lunchtime</div>
    <div *ngIf="ctrl.errors['tooLate']">Oh no, it's way too late</div>
    <div *ngIf="ctrl.errors['tooEarly']">It's a bit too early</div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="time" cdkFocusInitial>Ok</button>
</div>
</div>




