import { Component, OnInit, Input, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef  } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-conditions-modal',
  templateUrl: './terms-conditions-modal.component.html',
  styleUrls: ['./terms-conditions-modal.component.css'],
})
export class TermsConditionsModalComponent implements OnInit {
  id: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<TermsConditionsModalComponent>) {
    console.log('Data received in constructor:', this.data);
    this.id = this.data.id;
   }

  ngOnInit() {
    console.log(this.id);
  }

  close() {
    this.dialogRef.close();
  }


}
