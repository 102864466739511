export class City {
cityImage: string;
cityName: string;
countryId: number
created: Date;
createdBy: number;
id: number;
isOnlineCity: 1
latitude: number;
longitude: number;
stateId: number;
status: string;
updated: Date;
updatedBy: number;

}
