import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';

import { RouterModule, Routes } from '@angular/router';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CommonFooterComponent } from '../shared';
import { CommonFooterModule } from '../shared/layout/common-footer.module';
import { MainHeaderModule } from '../shared/layout/main-header.module';
import { AngularMaterialModule } from '../angular-material.module';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  }
];



@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
     FormsModule,
    ReactiveFormsModule,
    [RouterModule.forChild(routes)],
   AngularMaterialModule,
    MDBBootstrapModule.forRoot(),
    CommonFooterModule,
    MainHeaderModule
  ],

  exports: [RouterModule]
})
export class LoginModule { }
