import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFooterComponent } from '..';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [CommonFooterComponent],
  imports: [
    CommonModule,

  ],
  exports: [
    CommonFooterComponent,
    RouterModule
  ]


})
export class CommonFooterModule { }
