import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule, Routes } from '@angular/router';
import { AngularMaterialModule } from '../angular-material.module';
import { MainHeaderModule } from '../shared/layout/main-header.module';
import { CommonFooterModule } from '../shared/layout/common-footer.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

const routes: Routes = [
  {
    path: '',
    component: SignupComponent
  }
];

@NgModule({
  declarations: [SignupComponent],
  imports: [
    MDBBootstrapModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule,
    [RouterModule.forChild(routes)],
    MainHeaderModule,
    CommonFooterModule,


  ],

  exports: [RouterModule]

})
export class SignupModule { }
