import { Component, ElementRef, NgZone, Input, OnInit, OnChanges, ViewChild, OnDestroy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CheckoutModalService } from './checkout-modal.service';
import { OrderService, UserService, PageProperty } from 'src/app/core';
import * as moment from 'moment';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ChangeDetectionStrategy } from '@angular/core';
import { WindowRef } from './window-ref.service';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from 'ngx-stripe';
import { MapsAPILoader } from '@agm/core';
import { AlertDialogService } from '../../alert-msg-dialog/alert-dialog.service';
import { UserModalService } from '../user-modal/user-modal.service';
import { TermConditionsService } from '../terms-conditions-modal/term-conditions.service';

declare var Stripe;

declare let $: any;

@Component({
    selector: 'app-modal-checkout',
    templateUrl: './checkout-modal.component.html',
    styleUrls: ['./checkout-modal.component.css'],
    providers: [WindowRef]
    // changeDetection: ChangeDetectionStrategy.OnPush    
})
export class CheckoutModalComponent implements OnInit, OnDestroy, OnChanges {
    @Input() id: string;
    @Output()
    onCloseCheckout: EventEmitter<boolean> = new EventEmitter();
    @Output()
    stopTimer: EventEmitter<boolean> = new EventEmitter();
    @Input()
    selectedOrderGroup;
    @Input()
    currentDateTime;
    @Input()
    scheduleWay;
    private element: any;
    currentUser: any;
    orderHeader: any;
    globalUserId: any;
    localUserDetail: any;
    currentDate: any;
    currentTime: any;
    tip1: any;
    tip2: any;
    tip3: any;
    tip: any;
    otherTip: any;
    selectedCardVisa = null;
    selectedFourDigit: string = "";
    selectExpirationDate: string;
    clientToken: string;
    cartItems: any;
    isOrderLoaded: boolean = false;
    discountCode: any = "";
    cardDetails: any;
    selectedCardToken: any;
    razorPayKey: any
    error: any = { isDisplay: false, Message: "" }
    @ViewChild('checkoutModal', { static: true }) basicModal: any;
    @ViewChild('alert', { static: true }) alert: ElementRef;
    cardholdersName: string;
    nonce: any;
    showEditPayment: boolean = true;
    isRazorPayGateway: boolean = true;
    DisableContactless: boolean = false;
    isDeliveryChargeCalculated:boolean=true;
    keepFieldsDisabled:boolean=true;
    isReachableAddress:boolean=true;
    showEditComment: boolean = false;
    showEditTime: boolean = false;
    showNewCard: boolean = false;
    showSaveButton: boolean = true;
    mymodel = "";
    comment = "";
    selectedAddress: any = "";
    address: any;
    addressForm = new FormGroup({
        address1: new FormControl('', Validators.required),
        address2: new FormControl(''),
        zipcode: new FormControl('', Validators.required),
        country: new FormControl(''),
        state: new FormControl(''),
        city: new FormControl(''),
    });
    showNewAddress: boolean = false;
    countries: any;
    states: any;
    cities: any;
    locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    elements: Elements;
    card: any;
    cardInfo: boolean = false;
    token: any;
    currency: any;
    businessLong:any;
    businessLat:any;
    addressList: any;
    showEditAdd: boolean = false;
    pageProperty: PageProperty;
    businessId: any;
    convenienceName: any;
    distance: any;
    public searchControl: FormControl;
    deliveryCharge: any;
    deliveryTax: any;
    fromLoc: any;
    deliveryRule: any;
    // optional parameters
    elementsOptions: ElementsOptions = {
        locale: 'en'
    };
    autofillAddress2;
    formattedDate;

    stripeTest: FormGroup;
    @ViewChild("search", { static: true })
    public searchElementRef: ElementRef;

    constructor(private modalService: CheckoutModalService, private el: ElementRef, private orderService: OrderService,
        private userService: UserService, private spinnerService: Ng4LoadingSpinnerService, private fb: FormBuilder,
        private stripeService: StripeService, private winRef: WindowRef,
        private mapsAPILoader: MapsAPILoader, private alertDialogService: AlertDialogService,
        private ngZone: NgZone,private TermsService: TermConditionsService) {
        this.element = el.nativeElement;
        this.currency = localStorage.getItem('currency');
        this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
        this.businessId = localStorage.getItem("business_id");
        this.businessLat=localStorage.getItem("bizLat");
        this.businessLong=localStorage.getItem("bizLong");

        

        this.orderService.getDeliveryRuleByLocationId(this.locationDetails.location.id).subscribe((res: any) => {
            console.log("delivery rule --------------------------------------", res)
            this.deliveryRule = res;
        });


    }

    ngOnInit(): void {
        let modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
        var localUserId = localStorage.getItem("localUserId");

        this.countries = JSON.parse(localStorage.getItem("countries"));
        this.addressForm.get('country').setValue(this.countries.find(c => c.id == this.locationDetails.location.address.countryId));
        this.getStateByCountry(this.locationDetails.location.address.countryId, this.locationDetails.location.address.stateId);
       
       
        this.stripeTest = this.fb.group({
            name: ['', [Validators.required]]
        });
        this.stripeService.elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                if (!this.card) {
                    this.card = this.elements.create('card', {
                        style: {
                            base: {
                                iconColor: '#666EE8',
                                color: '#31325F'
                            }
                        }
                    });
                    this.card.mount('#card-element');
                }
            });

        //create search FormControl
        this.searchControl = new FormControl();

        //load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
            var countryName;
            if (this.locationDetails.location.address.countryId == 99)
                countryName = "IN";
            else
                countryName = "US";

            var address = this.locationDetails.location.address.address1 + ", " + this.locationDetails.location.address.address2 + ", " + this.locationDetails.location.address.state + ", " + this.locationDetails.location.address.city + ", " + this.locationDetails.location.address.zip;
            var geocoder = new google.maps.Geocoder();
            
            var latitude;
            var longitude;
            geocoder.geocode({ 'address': address }, function (results, status) {

                if (status == google.maps.GeocoderStatus.OK) {
                    latitude = results[0].geometry.location.lat();
                    longitude = results[0].geometry.location.lng();
                }

                console.log(latitude);
                console.log(longitude);
            });

            var cityBounds = new google.maps.LatLngBounds(new google.maps.LatLng(latitude, longitude), new google.maps.LatLng(latitude, longitude));
            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                bounds: cityBounds,
                types: [],
                componentRestrictions: { country: countryName }
            });

            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    console.log("place -----------------------------------------------------------", place);
                    var lat = place.geometry.location.lat(),
                        lng = place.geometry.location.lng();
                    this.fromLoc = new google.maps.LatLng(latitude, longitude);
                    var dest = new google.maps.LatLng(lat, lng);
                    this.getDeliveryInfo(this.fromLoc, dest, place, countryName, latitude, longitude, lat, lng);

                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    // //set latitude, longitude and zoom
                    // this.latitude = place.geometry.location.lat();
                    // this.longitude = place.geometry.location.lng();
                    // this.zoom = 12;

                    this.autofillAddress2 = place.formatted_address.split(',')[0]
                });
            });
        });
    }

    getDeliveryInfo(from, dest, place, countryName, locationLat, locationLng, userLat, userLng) {
        this.isDeliveryChargeCalculated=false;
        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [from],
                destinations: [dest],
                travelMode: google.maps.TravelMode['DRIVING'],
            }, (results: any) => {
                if (this.deliveryRule) {
                    if (this.deliveryRule.unitName == "Mile")
                        this.distance = Math.ceil(parseInt(results.rows[0].elements[0].distance.value) * 0.00062137);
                    else
                        this.distance = Math.ceil(parseInt(results.rows[0].elements[0].distance.value) / 1000);
                    console.log("distance  rule --------------------------------------", this.deliveryRule);

                    console.log("distance1  --------------------------------------", this.distance);
                    if (this.distance > this.deliveryRule.distance) {
                        alert('Sorry! We currently do not deliver to this location!');
                        this.keepFieldsDisabled=true;
                        this.selectedAddress='';
                        this.isReachableAddress=false;
                    } else {

                        this.keepFieldsDisabled=false;

                        this.isReachableAddress=true;
                        if (this.deliveryRule.deliveryPartner != null) {
                            if (this.deliveryRule.deliveryPartner.name == 'Dunzo') {
                                this.orderService.getDeliveryChargeByDunzoApi(1, this.locationDetails.location.id,
                                    'Dunzo', this.orderHeader.id, locationLat, locationLng, userLat, userLng, "pickup_drop")
                                    .subscribe((res: any) => {

                                        setTimeout(() => {
                                            if (res.displayMessage) {
                                                this.presentAlert(res.displayMessage);
                                                this.isReachableAddress=false;
                                                this.keepFieldsDisabled=true;

                                                return;
                                            }
                                            else if (res.estimated_price) {
                                                // "category_id": "pickup_drop",
                                                //   "distance": 6.17,
                                                //     "estimated_price": 60.50,
                                                //       "eta": {
                                                //   "pickup": 12,
                                                //     "dropoff": 45
                                                this.deliveryCharge = parseFloat(res.estimated_price);
                                                this.deliveryTax = 0;

                                                if (this.deliveryRule.tax1 != null) {
                                                    this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax1.rate;
                                                } if (this.deliveryRule.tax2 != null) {
                                                    this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax2.rate;
                                                } if (this.deliveryRule.tax3 != null) {
                                                    this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax3.rate;
                                                } if (this.deliveryRule.tax4 != null) {
                                                    this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax4.rate;
                                                }
                                                let message = 'Total Delivery Charge is ' + this.currency + (this.deliveryCharge + this.deliveryTax).toFixed(2);
                                                alert(message);
                                                this.keepFieldsDisabled=false;
                                                this.fillAddressForm(place, countryName);
                                            }
                                            else {
                                                this.presentAlert('Sorry! We currently do not deliver to this location!');
                                                this.keepFieldsDisabled=true;
                                            }
                                        }, 200);
                                    }, (err) => {
                                        console.log(err);
                                    });
                                return;
                            }
                            if (this.deliveryRule.deliveryPartner.name == 'Uber Direct') {
                                const [streetAddress, city, state, zipCode] = place.formatted_address.split(",").map(part => part.trim());

                                let orderPostPacket: any = {
                                    "QuoteRequest": {
                                        "dropoff_address": `{"street_address":["${streetAddress} ${city}"],"city":"${city}","state":"${state}","zip_code":"${zipCode}","country":"US"}`,
                                        "pickup_latitude": locationLat,
                                        "pickup_longitude": locationLng,
                                        "dropoff_latitude":userLat,
                                        "dropoff_longitude":userLng,
                                        "deliverableAction":"deliverable_action_meet_at_door"
                                    }
                                }

                                this.orderService.getDeliveryQuoteForUberDirect(this.locationDetails.location.id,this.orderHeader.id,orderPostPacket)
                                    .subscribe((res: any) => {

                                        setTimeout(() => {
                                            if (res.displayMessage) {
                                                this.presentAlert(res.displayMessage);
                                                this.isReachableAddress=false;

                                                return;
                                            }
                                            else if (res.estimated_price) {
                                                // "category_id": "pickup_drop",
                                                //   "distance": 6.17,
                                                //     "estimated_price": 60.50,
                                                //       "eta": {
                                                //   "pickup": 12,
                                                //     "dropoff": 45
                                                this.deliveryCharge = parseFloat(res.estimated_price);
                                                this.deliveryTax = 0;

                                                const inputDateString = res.dropoff_eta;
                                                const date = new Date(inputDateString);
                                                const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
                                                const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
                                                const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                                                const hours = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);

                                                this.formattedDate = `${month} ${day}th ${year} ${hours}`;
                                                console.log(this.formattedDate);

                                                if (this.deliveryRule.tax1 != null) {
                                                    this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax1.rate;
                                                } if (this.deliveryRule.tax2 != null) {
                                                    this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax2.rate;
                                                } if (this.deliveryRule.tax3 != null) {
                                                    this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax3.rate;
                                                } if (this.deliveryRule.tax4 != null) {
                                                    this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax4.rate;
                                                }
                                                let message = 'Total Delivery Charge is ' + this.currency + (this.deliveryCharge + this.deliveryTax).toFixed(2) + '\nYour Order will be delievered by ' + (this.formattedDate);
                                                alert(message);
                                                localStorage.setItem("delieveryEstimate",message);

                                            }
                                            else {
                                                this.presentAlert('Sorry! We currently do not deliver to this location!');
                                                this.isReachableAddress=false;
                                            }
                                        }, 200);
                                    }, (err) => {
                                        console.log(err);
                                    });
                                return;
                            }
                        } else {
                            var fixedDeliveryCharge = this.deliveryRule.price;
                            if (this.deliveryRule.isPerDistanceTypeCharge == 1) {
                                var deliveryChargeForPerdistance = 0;
                                if (this.distance > parseFloat(this.deliveryRule.fixedDistance)) {
                                    var perDistanceValue = this.distance - this.deliveryRule.fixedDistance;
                                    deliveryChargeForPerdistance = perDistanceValue * this.deliveryRule.perDistanceTypeCharge;
                                }
                                this.deliveryCharge = fixedDeliveryCharge + deliveryChargeForPerdistance;
                            } else {
                                if (this.deliveryRule.deliveryRuleToDistanceUnits.length > 0) {
                                    var toDistanceRule = this.deliveryRule.deliveryRuleToDistanceUnits.sort((a, b) => (parseInt(a['distance']) > parseInt(b['distance'])) ? 1 : -1);
                                    for (var i = 0; i < this.deliveryRule.deliveryRuleToDistanceUnits.length; i++) {
                                        if (this.distance <= this.deliveryRule.deliveryRuleToDistanceUnits[i].distance) {
                                            var upToDeliveryCharge = this.deliveryRule.deliveryRuleToDistanceUnits[i].price;
                                            this.deliveryCharge = upToDeliveryCharge;
                                            break;
                                        }
                                    }

                                }
                            }
                            this.deliveryTax = 0;

                            if (this.deliveryRule.tax1 != null) {
                                this.deliveryTax = this.deliveryTax + (this.deliveryCharge / 100) * this.deliveryRule.tax1.rate;
                            } if (this.deliveryRule.tax2 != null) {
                                this.deliveryTax = this.deliveryTax + (this.deliveryCharge / 100) * this.deliveryRule.tax2.rate;
                            } if (this.deliveryRule.tax3 != null) {
                                this.deliveryTax = this.deliveryTax + (this.deliveryCharge / 100) * this.deliveryRule.tax3.rate;
                            } if (this.deliveryRule.tax4 != null) {
                                this.deliveryTax = this.deliveryTax + (this.deliveryCharge / 100) * this.deliveryRule.tax4.rate;
                            }
                            let message = 'Total Delivery Charge is ' + this.currency + (this.deliveryCharge + this.deliveryTax).toFixed(2);
                            alert(message);
                            this.fillAddressForm(place, countryName);
                        }
                    }
                } else {
                    this.fillAddressForm(place, countryName);
                }
            });
    }
    buy() {
        const name = this.stripeTest.get('name').value;
        this.stripeService
            .createSource(this.card)
            .subscribe(result => {
                if (result) {
                    // Use the token to create a charge or a customer
                    // https://stripe.com/docs/charges
                    if (result.source) {
                        console.log(result);
                        this.cardInfo = true;
                        this.token = result.source.id;
            //            this.selectedCardVisa = result.source.card.brand;
              //          this.selectedFourDigit = result.source.card.last4;
                //        this.selectExpirationDate = result.source.card.exp_month + "/" + result.source.card.exp_year;
                        this.showEditPayment = false;
                    }

                } else if (result.error) {
                    // Error creating the token
                    console.log(result.error.message);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let property in changes) {
            if (property === 'scheduleWay') {
                this.scheduleWay = changes[property].currentValue;
            }
            if (property === 'currentDateTime') {
                this.currentDateTime = changes[property].currentValue;
            }
        }
        if (localStorage.getItem("required_pickuptime") != undefined && localStorage.getItem("required_pickuptime")) {
            var str = moment.utc(localStorage.getItem("required_pickuptime"));
            this.currentDate = moment(str).format('DD MMM, YYYY');
            this.currentTime = moment(str).format('hh:mm a');
        }
    }
    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        console.log('modal is removed2')
        this.selectedAddress='';
        this.modalService.remove(this.id);
        this.element.remove();
        this.onCloseCheckout.emit(true);
    }

    // open modal
    open(): void {
        this.basicModal.show();
        this.getStripeTokenKey();
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let orderpacket = (localStorage.getItem("orderHeader") != undefined) ? JSON.parse(localStorage.getItem("orderHeader")) : null;
        this.orderHeader = (orderpacket) ? orderpacket.onlineOrderHeader : null;
        this.cartItems = JSON.parse(localStorage.getItem("cartItems"));
        var str = moment.utc(this.currentDateTime);
        this.currentDate = moment(str).format('DD MMM, YYYY');
        this.currentTime = moment(str).format('hh:mm a');
        console.log('current date time', this.currentDate, this.currentTime)
        console.log(this.selectedOrderGroup)
        this.convenienceName = this.selectedOrderGroup.orderSouceDisplayPackets.convenienceName;;

        if (this.orderHeader)
            this.orderService.getOnlineOrderPaidById(this.orderHeader.id)
                .subscribe((res: any) => {
                    console.log('payment response is====', res)
                    if (!res.result) {
                        this.createOrder();
                    } else {
                        alert('Order is already paid.');
                        this.basicModal.hide();
                        this.onCloseCheckout.emit(false);
                    }
                });
        else
            this.createOrder();
    }

    remove(): void {
        console.log('modal is removed')
    }

    // close modal
    close(): void {
        console.log('modal is removed1')
        this.selectedAddress='';
        this.basicModal.hide();
        this.showNewAddress=false;
        this.showEditAdd=false;
        this.onCloseCheckout.emit(true);
    }

    createOrder() {
        let onlineOrderDetailItems = [];
        let items = JSON.parse(localStorage.getItem("cartItems"));
        for (var i in items['items']) {
            console.log('items===', items['items'][i])
            let item = {
                "id": items['items'][i]['orderDetailId'],
                "name": items['items'][i]['displayName'],
                "itemsId": items['items'][i]['id'],
                "price": items['items'][i]['priceSelling'],
                "itemsQty": items['items'][i]['quantity'],
                "specialRequest": items['items'][i]['comment'],
                "isItemRemoved": items['items'][i]['isItemRemoved'],
                "onlineOrderDetailAttributes": []
            }
            for (var j in items['items'][i]['addedAttribute']) {
                for (var k in items['items'][i]['addedAttribute'][j]['attr']) {
                    let attr = items['items'][i]['addedAttribute'][j]['attr'][k];
                    let attribute = {
                        "name": attr['displayName'],
                        "id": attr['orderDetailAttributeId'],
                        "attributeId": attr['id'],
                        "price": attr['sellingPrice'],
                    }
                    item.onlineOrderDetailAttributes.push(attribute)
                }
            }
            onlineOrderDetailItems.push(item)
        }
        console.log('items packet', onlineOrderDetailItems, this.selectedOrderGroup, this.currentDateTime)
        this.globalUserId = localStorage.getItem('globalUserId');


        this.spinnerService.show();
        this.userService.getUserByGlobalUsersId(this.globalUserId, this.locationDetails.location.id).subscribe((response: any) => {
            if (response == null || response.displayMessage) {
                // service to add user on local 
                this.localUserDetail = null;
                // alert();
            } else {
                localStorage.setItem('localUserId', response.id);
                this.localUserDetail = {
                    "id": response.id,
                    "dateofbirth": response.dateofbirth,
                    "email": response.email,
                    "firstName": response.firstName,
                    "lastName": response.lastName,
                    "phone": response.phone,
                };
                if (this.selectedOrderGroup.name == "Delivery") {
                    this.address = response.addressesSet;
                    if (response.addressesSet.length > 0) {
                        // this.addressList = response.addressesSet;
                        // for managing D status address
                        let addressList = response.addressesSet;
                        this.addressList = addressList.filter(c => c.status != "D");


                        // console.log("new1-------------------------------------------------------", this.addressList);
                        // this.selectedAddress = response.addressesSet[0];
                        // this.localUserDetail.customerAddress = {
                        //     "id": this.selectedAddress.id,
                        //     "addressLine1": this.selectedAddress.address1,
                        //     "addressLine2": this.selectedAddress.address2,
                        //     "country": this.selectedAddress.country,
                        //     "state": this.selectedAddress.state,
                        //     "city": this.selectedAddress.city,
                        //     "zip": this.selectedAddress.zip
                        // }
                    } else {
                        this.addressList = [];
                    }
                }

                if (this.orderHeader) {
                    this.orderService.getOnlineOrderById(this.orderHeader.id).subscribe((orderHeader: any) => {
                        console.log("Order header--------------------------------", orderHeader);
                        this.orderHeader = orderHeader;
                        if (orderHeader.customerInfo.customerAddress) {
                            this.selectedAddress = orderHeader.customerInfo.customerAddress;
                            this.selectedAddress.address1 = orderHeader.customerInfo.customerAddress.addressLine1;
                            this.selectedAddress.address2 = orderHeader.customerInfo.customerAddress.addressLine2;
                        }
                        this.mergeArray();
                        this.proceedOrder(onlineOrderDetailItems);
                    });
                } else {
                    this.proceedOrder(onlineOrderDetailItems);
                }

            }



        });
        console.log("customer infooooooo", this.localUserDetail);
    }

    proceedOrder(onlineOrderDetailItems) {
        let onlineOrderHeader = {
            "id": (this.orderHeader) ? this.orderHeader.id : 0,
            "orderSourceId": this.selectedOrderGroup.orderSouceDisplayPackets.id,
            "comment": "",
            "locationsId": this.locationDetails.location.id,
            "customerInfo": this.localUserDetail,
            "payment": null,
            "onlineDiscounts": null,
            "onlineOrderDetailItems": onlineOrderDetailItems,
            "scheduleDateTime": this.currentDateTime,
            "thirdPartyId": 0,
            "partnerReferenceNumber": null,
            "orderStatusId": 0,
            "deliveryCharges": (this.orderHeader) ? this.orderHeader.deliveryCharges : null,
            "deliveryTax": (this.orderHeader) ? this.orderHeader.deliveryTax : null,
            "serviceCharges": null,
            "convenienceCharge": null,
            "reservationsId": 0,
            "pointOfServiceCount": 0,
            "futureOrder": (this.scheduleWay == "Later") ? true : false
        }

        if (localStorage.getItem('currentReservation')) {
            let reservation = JSON.parse(localStorage.getItem('currentReservation'));
            console.log("============================================================", reservation);
            onlineOrderHeader.pointOfServiceCount = reservation.reservation.partySize;
            onlineOrderHeader.scheduleDateTime = reservation.reservation.date + " " + reservation.reservation.time;
            onlineOrderHeader.reservationsId = reservation.reservation.id;
            onlineOrderHeader.futureOrder = true;
            onlineOrderHeader.customerInfo.firstName = reservation.reservation.firstName;
            onlineOrderHeader.customerInfo.lastName = reservation.reservation.lastName;
        }
        let orderPacket = {
            "merchantId": Number(localStorage.getItem("account_id")),
            "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
            "clientId": localStorage.getItem('deviceId'),
            "locationId": this.locationDetails.location.id,
            "schemaName": "",
            "sessionId": null,
            "idOfOrderHoldingClientObj": (this.scheduleWay == "Later") ? Number(localStorage.getItem("idOfHoldingClientObj")) : null,
            "onlineOrderHeader": onlineOrderHeader
        }
        console.log('current user', localStorage.getItem("currentUser"))
        let orderPost = { "OnlineOrderPacket": orderPacket };

        this.orderService.addUpdateOnlineOrder(orderPost).subscribe((response: any) => {
            this.spinnerService.hide();
            this.isOrderLoaded = true;
            console.log('im here')
            if (response.onlineOrderHeader != undefined) {
                console.log('yayy order created', response);
                localStorage.setItem('orderHeader', JSON.stringify(response));
                localStorage.setItem("idOfHoldingClientObj", "0");
                this.orderHeader = response.onlineOrderHeader;
                this.comment = response.onlineOrderHeader.comment;
                this.tip1 = (this.orderHeader.subTotal * 15) / 100;
                this.tip2 = (this.orderHeader.subTotal * 18) / 100;
                this.tip3 = (this.orderHeader.subTotal * 21) / 100;
                this.tip = this.tip1;
                let cartItems = [];
                let subTotal = 0;
                if (response.onlineOrderHeader.onlineOrderDetailItems) {
                    let orderDetailItems = response.onlineOrderHeader.onlineOrderDetailItems;
                    for (let index in orderDetailItems) {
                        // let currentItem;
                        // items.items.forEach(element => {
                        //     if (element.id == orderDetailItems[index].itemsId)
                        //         currentItem = element;
                        // });

                        let cartItem = {
                            "id": orderDetailItems[index].itemsId,
                            "displayName": orderDetailItems[index].name,
                            "priceSelling": orderDetailItems[index].price,
                            "subTotal": orderDetailItems[index].price,
                            "quantity": orderDetailItems[index].itemsQty,
                            "comment": orderDetailItems[index].specialRequest,
                            "orderDetailId": orderDetailItems[index].id,
                            "addedAttribute": [],
                            // "itemAttributeTypeDisplayPacketList": currentItem.itemAttributeTypeDisplayPacketList,
                            "isItemRemoved": orderDetailItems[index].isItemRemoved
                        };

                        let attributes = [];
                        let attr = orderDetailItems[index].onlineOrderDetailAttributes
                        for (let i in attr) {
                            let j
                            attributes.forEach(function (x, index1) {
                                if (x.itemAttributeTypeName === attr[i].name) {
                                    j = index1;
                                }
                            });
                            let attribute = {
                                "id": attr[i].attributeId,
                                "displayName": attr[i].name,
                                "orderDetailAttributeId": attr[i].id,
                                "sellingPrice": attr[i].sellingPrice
                            }
                            cartItem.subTotal += attribute.sellingPrice
                            if (j) {
                                attributes[j].attr.push(attribute)
                            } else {
                                attributes.push({ name: attr[i].itemAttributeTypeName, attr: [attribute] })
                            }
                        }
                        cartItem.addedAttribute = attributes;

                        // for (let j in orderDetailItems[index].onlineOrderDetailAttributes) {
                        //     let attrType: any = {};
                        //     for (let k in currentItem.itemAttributeTypeDisplayPacketList) {
                        //         for (let n in currentItem.itemAttributeTypeDisplayPacketList[k].itemAttributeDisplayPackets) {
                        //             if (currentItem.itemAttributeTypeDisplayPacketList[k].itemAttributeDisplayPackets[n].id == orderDetailItems[index].onlineOrderDetailAttributes[j].attributeId) {
                        //                 attrType = { id: currentItem.itemAttributeTypeDisplayPacketList[k].id, displayName: currentItem.itemAttributeTypeDisplayPacketList[k].displayName };
                        //                 break;
                        //             }
                        //         }
                        //         if (attrType.id)
                        //             break;
                        //     }

                        //     let attribute = {
                        //         "id": orderDetailItems[index].onlineOrderDetailAttributes[j].attributeId,
                        //         "displayName": orderDetailItems[index].onlineOrderDetailAttributes[j].name,
                        //         "orderDetailAttributeId": orderDetailItems[index].onlineOrderDetailAttributes[j].id,
                        //         "sellingPrice": orderDetailItems[index].onlineOrderDetailAttributes[j].sellingPrice
                        //     }


                        //     var attrTypeKey: any = -1;
                        //     for (var i in cartItem.addedAttribute) {
                        //         if (cartItem.addedAttribute[i].id == attrType.id) {
                        //             attrTypeKey = i;
                        //             break
                        //         }
                        //     }
                        //     if (attrTypeKey > -1) {
                        //         cartItem.addedAttribute[attrTypeKey].attr.push(attribute)
                        //         cartItem.subTotal += attribute.sellingPrice
                        //     } else {
                        //         cartItem.addedAttribute.push({ id: attrType.id, name: attrType.displayName, attr: [attribute] });
                        //         cartItem.subTotal += attribute.sellingPrice
                        //     }

                        // }
                        if (orderDetailItems[index].isItemRemoved == 0) {
                            subTotal += cartItem.subTotal * orderDetailItems[index].itemsQty;
                            cartItems.push(cartItem)
                        }


                    }
                }

                localStorage.setItem("cartItems", JSON.stringify({ subTotal: subTotal, items: cartItems }));

                this.cartItems = { subTotal: subTotal, items: cartItems };
                console.log("yayy cart items", this.cartItems);
            } else {

                this.error.isDisplay = true;
                this.error.Message = (response.displayMessage) ? response.displayMessage : "Something went wrong";
            }

        });
    }

    addTip(value) {
        if (value != '')
            this.tip = parseFloat(value);
        else
            this.tip = 0.00;
            this.otherTip = '';
    }
    applyDiscount() {
        let onlineOrderHeader = {
            "id": (this.orderHeader) ? this.orderHeader.id : 0,
            "locationsId": this.locationDetails.location.id,
            "orderSourceId": this.selectedOrderGroup.orderSouceDisplayPackets.id,
            "onlineDiscounts": [{
                "name": this.discountCode,
            }]
        }
        let orderPacket = {
            "merchantId": Number(localStorage.getItem("account_id")),
            "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
            "clientId": localStorage.getItem('deviceId'),
            "locationId": this.locationDetails.location.id,
            "onlineOrderHeader": onlineOrderHeader
        }
        let orderPost = { "OnlineOrderPacket": orderPacket };
        this.spinnerService.show();
        this.orderService.addRemoveDiscount(orderPost).subscribe((response: any) => {
            this.spinnerService.hide();
            if (response.onlineOrderHeader != undefined) {
                console.log('yayy order created', response);
                localStorage.setItem('orderHeader', JSON.stringify(response));
                this.orderHeader = response.onlineOrderHeader;
            } else {
                this.error.isDisplay = true;
                this.error.Message = (response.displayMessage) ? response.displayMessage : "Something went wrong";
            }
        });
    }

    removeDiscount() {
        this.discountCode = "";
        let onlineOrderHeader = {
            "id": (this.orderHeader) ? this.orderHeader.id : 0,
            "locationsId": this.locationDetails.location.id,
            "orderSourceId": this.selectedOrderGroup.orderSouceDisplayPackets.id,
            "onlineDiscounts": [{
                "name": null,
            }]
        }
        let orderPacket = {
            "merchantId": Number(localStorage.getItem("account_id")),
            "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
            "clientId": localStorage.getItem('deviceId'),
            "locationId": this.locationDetails.location.id,
            "onlineOrderHeader": onlineOrderHeader
        }
        let orderPost = { "OnlineOrderPacket": orderPacket };
        this.spinnerService.show();
        this.orderService.addRemoveDiscount(orderPost).subscribe((response: any) => {
            this.spinnerService.hide();
            if (response.onlineOrderHeader != undefined) {
                console.log('yayy order created', response);
                localStorage.setItem('orderHeader', JSON.stringify(response));
                this.orderHeader = response.onlineOrderHeader;
            } else {
                this.error.isDisplay = true;
                this.error.Message = response.displayMessage;
            }
        });
    }

    paymentProcess() {

        if (this.cardInfo) {
            let payment = {
                "payment_option": this.selectedCardVisa,
                "amount": this.orderHeader.total,
                "tip": this.tip
            }
            let orderPacket = {
                "merchantId": Number(localStorage.getItem("account_id")),
                "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
                "clientId": localStorage.getItem('deviceId'),
                "locationId": this.locationDetails.location.id,
                "payment": payment,
                "orderHeader": {
                    "id": this.orderHeader.id
                }
            }
            let orderPost = { "OrderPacket": orderPacket };

            this.orderService.authorizeOrderForStripeForWebsite(orderPost, this.token, this.selectedOrderGroup.orderSouceDisplayPackets.id, 0, this.locationDetails.location.id).subscribe((response: any) => {
                if (response.onlineOrderHeader != undefined) {
                    localStorage.setItem('orderHeader', JSON.stringify(response));
                    console.log('modal is removed1')
                    this.basicModal.hide();
                    this.onCloseCheckout.emit(false);

                    // this.orderHeader = response.onlineOrderHeader;
                } else {
                    this.error.isDisplay = true;
                    this.error.Message = (response.displayMessage) ? response.displayMessage : "Something went wrong";
                }
            });
        } else {
            this.error.isDisplay = true;
            this.error.Message = "Please Enter Card Details to Pay";
        }
    }

    convertDate() {
        var str = moment.utc(this.currentDateTime);
        let currentDate = moment(str).format('DD MMM, YYYY hh:mm a');
        return currentDate;
    }
    closeModal() {
        // localStorage.removeItem('orderHeader');
        // localStorage.removeItem('cartItems');
        // console.log('modal is removed1')
        this.basicModal.hide();
        this.onCloseCheckout.emit(false);
    }
    closeAlert() {
        this.error.isDisplay = false;
        this.alert.nativeElement.classList.remove('show');
    }
    showEditPay() {
        this.showEditPayment = true;
    }
    showEditCom() {
        this.showEditComment = true;
    }
    addComment() {
        this.updateOrder();
        this.showEditComment = false;
    }
    showEditTimeFunc() {
        this.showEditTime = true;
    }
    showEditAddFunc() {
        this.showEditAdd = true;
    }
    changeAddress(value) {
        if (value != "") {
            this.selectedAddress = this.addressList.find(c => c.address1 == value);
            this.isDeliveryChargeCalculated =false;
            let locationLatitude, locationLongitude, destinationLatitude, destinationLongitude
            let geocoder = new google.maps.Geocoder();
            var deliveryRule = this.deliveryRule;
            var businessAddress = this.locationDetails.location.address.address1 + ", " + this.locationDetails.location.address.address2 + ", " + this.locationDetails.location.address.state + ", " + this.locationDetails.location.address.city + ", " + this.locationDetails.location.address.zip;
            var destinationAddress = this.selectedAddress.address2 + ", " + this.selectedAddress.state + ", " + this.selectedAddress.city + ", " + this.selectedAddress.zip;

            geocoder.geocode({ 'address': businessAddress }, (results, status) => {

                if (status == google.maps.GeocoderStatus.OK) {
                    locationLatitude = results[0].geometry.location.lat();
                    locationLongitude = results[0].geometry.location.lng();

                    geocoder.geocode({ 'address': destinationAddress }, (results, status) => {

                        if (status == google.maps.GeocoderStatus.OK) {
                            destinationLatitude = results[0].geometry.location.lat();
                            destinationLongitude = results[0].geometry.location.lng();

                            let from = new google.maps.LatLng(locationLatitude, locationLongitude);
                            let dest = new google.maps.LatLng(destinationLatitude, destinationLongitude);
                            var service = new google.maps.DistanceMatrixService();
                            service.getDistanceMatrix(
                                {
                                    origins: [from],
                                    destinations: [dest],
                                    travelMode: google.maps.TravelMode['DRIVING'],
                                }, (resultDis: any) => {
                                    console.log(resultDis);
                                    if (this.deliveryRule) {
                                        console.log("meters ----------------------------------------------------", resultDis.rows[0].elements[0].distance.value)
                                        if (deliveryRule.unitName == "Mile")
                                            this.distance = Math.ceil(parseInt(resultDis.rows[0].elements[0].distance.value) * 0.00062137);
                                        else
                                            this.distance = Math.ceil(parseInt(resultDis.rows[0].elements[0].distance.value) / 1000);

                                        console.log("distance2  --------------------------------------", this.distance);
                                        if (this.distance > deliveryRule.distance) {
                                            alert('Sorry! We currently do not deliver to this location!');
                                            this.isReachableAddress=false;
                                        } else {
                                            this.isReachableAddress=true;
                                            if (this.deliveryRule.deliveryPartner != null) {
                                                if (this.deliveryRule.deliveryPartner.name == 'Dunzo') {
                                                    this.orderService.getDeliveryChargeByDunzoApi(1, this.locationDetails.location.id,
                                                        'Dunzo', this.orderHeader.id, locationLatitude, locationLongitude, destinationLatitude, destinationLongitude, "pickup_drop")
                                                        .subscribe((res: any) => {

                                                            setTimeout(() => {
                                                                if (res.displayMessage) {
                                                                    this.presentAlert(res.displayMessage);
                                                                    this.isReachableAddress=false;

                                                                    return;
                                                                }
                                                                else if (res.estimated_price) {
                                                                    // "category_id": "pickup_drop",
                                                                    //   "distance": 6.17,
                                                                    //     "estimated_price": 60.50,
                                                                    //       "eta": {
                                                                    //   "pickup": 12,
                                                                    //     "dropoff": 45
                                                                    this.deliveryCharge = parseFloat(res.estimated_price);
                                                                    this.deliveryTax = 0;

                                                                    if (this.deliveryRule.tax1 != null) {
                                                                        this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax1.rate;
                                                                    } if (this.deliveryRule.tax2 != null) {
                                                                        this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax2.rate;
                                                                    } if (this.deliveryRule.tax3 != null) {
                                                                        this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax3.rate;
                                                                    } if (this.deliveryRule.tax4 != null) {
                                                                        this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax4.rate;
                                                                    }
                                                                    let message = 'Total Delivery Charge is ' + this.currency + (this.deliveryCharge + this.deliveryTax).toFixed(2);
                                                                    alert(message);
                                                                   

                                                                }
                                                                else {
                                                                    this.presentAlert('Sorry! We currently do not deliver to this location!');
                                                                    this.isReachableAddress=false;
                                                                }
                                                            }, 200);
                                                        }, (err) => {
                                                            console.log(err);
                                                        });
                                                    return;
                                                }
                                                if (this.deliveryRule.deliveryPartner.name == 'Uber Direct') {
                                                    const [streetAddress, city, state, zipCode] = destinationAddress.split(",").map(part => part.trim());

                                                    let orderPostPacket: any = {
                                                        "QuoteRequest": {
                                                            "dropoff_address": `{"street_address":["${streetAddress} ${city}"],"city":"${city}","state":"${state}","zip_code":"${zipCode}","country":"US"}`,
                                                            "pickup_latitude": locationLatitude,
                                                            "pickup_longitude": locationLongitude,
                                                            "dropoff_latitude":destinationLatitude,
                                                            "dropoff_longitude":destinationLongitude,
                                                            "deliverableAction":"deliverable_action_meet_at_door"
                                                        }
                                                    }

                                                    this.orderService.getDeliveryQuoteForUberDirect(this.locationDetails.location.id,this.orderHeader.id,orderPostPacket)
                                                        .subscribe((res: any) => {

                                                            setTimeout(() => {
                                                                if (res.displayMessage) {
                                                                    this.presentAlert(res.displayMessage);
                                                                    this.isReachableAddress=false;

                                                                    return;
                                                                }
                                                                else if (res.estimated_price) {
                                                                    // "category_id": "pickup_drop",
                                                                    //   "distance": 6.17,
                                                                    //     "estimated_price": 60.50,
                                                                    //       "eta": {
                                                                    //   "pickup": 12,
                                                                    //     "dropoff": 45
                                                                    this.deliveryCharge = parseFloat(res.estimated_price);
                                                                    this.deliveryTax = 0;

                                                                    const inputDateString = res.dropoff_eta;
                                                                    const date = new Date(inputDateString);
                                                                    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
                                                                    const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
                                                                    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                                                                    const hours = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);

                                                                    this.formattedDate = `${month} ${day}th ${year} ${hours}`;
                                                                    console.log(this.formattedDate);

                                                                    if (this.deliveryRule.tax1 != null) {
                                                                        this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax1.rate;
                                                                    } if (this.deliveryRule.tax2 != null) {
                                                                        this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax2.rate;
                                                                    } if (this.deliveryRule.tax3 != null) {
                                                                        this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax3.rate;
                                                                    } if (this.deliveryRule.tax4 != null) {
                                                                        this.deliveryTax = this.deliveryTax + (parseFloat(res.estimated_price) / 100) * this.deliveryRule.tax4.rate;
                                                                    }
                                                                    let message = 'Total Delivery Charge is ' + this.currency + (this.deliveryCharge + this.deliveryTax).toFixed(2) + '\nYour Order will be delievered by ' + (this.formattedDate);
                                                                    alert(message);
                                                                    localStorage.setItem("delieveryEstimate",message);

                                                                }
                                                                else {
                                                                    this.presentAlert('Sorry! We currently do not deliver to this location!');
                                                                    this.isReachableAddress=false;
                                                                }
                                                            }, 200);
                                                        }, (err) => {
                                                            console.log(err);
                                                        });
                                                    return;
                                                }
                                            } else {
                                                var fixedDeliveryCharge = deliveryRule.price;
                                                if (deliveryRule.isPerDistanceTypeCharge == 1) {
                                                    var deliveryChargeForPerdistance = 0;
                                                    if (this.distance > parseFloat(deliveryRule.fixedDistance)) {
                                                        var perDistanceValue = this.distance - deliveryRule.fixedDistance;
                                                        deliveryChargeForPerdistance = perDistanceValue * deliveryRule.perDistanceTypeCharge;
                                                    }
                                                    this.deliveryCharge = fixedDeliveryCharge + deliveryChargeForPerdistance;
                                                } else {
                                                    if (deliveryRule.deliveryRuleToDistanceUnits.length > 0) {
                                                        var toDistanceRule = deliveryRule.deliveryRuleToDistanceUnits.sort((a, b) => (parseInt(a['distance']) > parseInt(b['distance'])) ? 1 : -1);
                                                        for (var i = 0; i < deliveryRule.deliveryRuleToDistanceUnits.length; i++) {
                                                            if (this.distance <= deliveryRule.deliveryRuleToDistanceUnits[i].distance) {
                                                                var upToDeliveryCharge = deliveryRule.deliveryRuleToDistanceUnits[i].price;
                                                                this.deliveryCharge = upToDeliveryCharge;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                                this.deliveryTax = 0;

                                                if (deliveryRule.tax1 != null) {
                                                    this.deliveryTax = this.deliveryTax + (this.deliveryCharge / 100) * deliveryRule.tax1.rate;
                                                } if (deliveryRule.tax2 != null) {
                                                    this.deliveryTax = this.deliveryTax + (this.deliveryCharge / 100) * deliveryRule.tax2.rate;
                                                } if (deliveryRule.tax3 != null) {
                                                    this.deliveryTax = this.deliveryTax + (this.deliveryCharge / 100) * deliveryRule.tax3.rate;
                                                } if (deliveryRule.tax4 != null) {
                                                    this.deliveryTax = this.deliveryTax + (this.deliveryCharge / 100) * deliveryRule.tax4.rate;
                                                }

                                                let message = 'Total Delivery Charge is ' + this.currency + (this.deliveryCharge + this.deliveryTax).toFixed(2);
                                                alert(message);

                                            }
                                        }
                                    }
                                });

                        }

                    });
                }
                this.addAddress();
            });
        } else {
            this.selectedAddress = "";
        }
    }
    alertMessage(message) {
        console.log("in alert");
        this.alertDialogService.confirm('', message, 1, "Close")
            .then((confirmed) => {
                return;
            })
            .catch(() => {
            });
    }
    addAddress() {
        console.log("addressssssssssssssssssssss=========================================", this.selectedAddress);
        this.updateOrder();
        if(this.isReachableAddress==false){
            this.presentAlert('Sorry! We currently do not deliver to this location!');
            return false;
        }
        if(this.isReachableAddress==true){
            this.isDeliveryChargeCalculated=true;

        }
        this.showEditAdd = false;
    }
    addScheduleTime() {
        this.updateOrder();
        this.showEditTime = false;
        this.stopTimer.emit(true);
    }

    updateOrder() {
        let onlineOrderDetailItems = [];
        let items = JSON.parse(localStorage.getItem("cartItems"));
        for (var i in items['items']) {
            console.log('items===', items['items'][i])
            let item = {
                "id": items['items'][i]['orderDetailId'],
                "name": items['items'][i]['displayName'],
                "itemsId": items['items'][i]['id'],
                "price": items['items'][i]['priceSelling'],
                "itemsQty": items['items'][i]['quantity'],
                "specialRequest": items['items'][i]['comment'],
                "isItemRemoved": items['items'][i]['isItemRemoved'],
                "onlineOrderDetailAttributes": []
            }
            for (var j in items['items'][i]['addedAttribute']) {
                for (var k in items['items'][i]['addedAttribute'][j]['attr']) {
                    let attr = items['items'][i]['addedAttribute'][j]['attr'][k];
                    let attribute = {
                        "name": attr['displayName'],
                        "id": attr['orderDetailAttributeId'],
                        "attributeId": attr['id'],
                        "price": attr['sellingPrice'],
                    }
                    item.onlineOrderDetailAttributes.push(attribute)
                }
            }
            onlineOrderDetailItems.push(item)
        }
        console.log('items packet', onlineOrderDetailItems, this.selectedOrderGroup, this.currentDateTime)
        this.globalUserId = localStorage.getItem('globalUserId');
        if (this.selectedOrderGroup.name == "Delivery") {
            this.localUserDetail.customerAddress = {
                "id": this.selectedAddress.id,
                "addressLine1": this.selectedAddress.address1,
                "addressLine2": this.selectedAddress.address2,
                "country": this.selectedAddress.country,
                "state": this.selectedAddress.state,
                "city": this.selectedAddress.city,
                "zip": this.selectedAddress.zip
            }

        }
        let onlineOrderHeader = {
            "id": (this.orderHeader) ? this.orderHeader.id : 0,
            "orderSourceId": this.selectedOrderGroup.orderSouceDisplayPackets.id,
            "comment": this.comment,
            "locationsId": this.locationDetails.location.id,
            "customerInfo": this.localUserDetail,
            "payment": null,
            "onlineDiscounts": null,
            "onlineOrderDetailItems": onlineOrderDetailItems,
            "scheduleDateTime": this.currentDateTime,
            "thirdPartyId": 0,
            "partnerReferenceNumber": null,
            "orderStatusId": 0,
            "deliveryCharges": null,
            "deliveryTax": null,
            "serviceCharges": null,
            "convenienceCharge": null,
            "futureOrder": (this.scheduleWay == "Later") ? true : false
        }
        if (this.selectedOrderGroup.name == "Delivery") {
            onlineOrderHeader.deliveryCharges = this.deliveryCharge;
            onlineOrderHeader.deliveryTax = this.deliveryTax;
        }
        let orderPacket = {
            "merchantId": Number(localStorage.getItem("account_id")),
            "locationId": this.locationDetails.location.id,
            "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
            "clientId": localStorage.getItem('deviceId'),
            "schemaName": "",
            "sessionId": null,
            "idOfOrderHoldingClientObj": (this.scheduleWay == "Later") ? Number(localStorage.getItem("idOfHoldingClientObj")) : null,
            "onlineOrderHeader": onlineOrderHeader
        }
        console.log('current user', localStorage.getItem("currentUser"))
        let orderPost = { "OnlineOrderPacket": orderPacket };
        console.log("order post packed------------------------------------", orderPost);
        this.orderService.updateOrderAfterSave(orderPost).subscribe((response: any) => {
            this.isOrderLoaded = true;
            if (response.onlineOrderHeader != undefined) {
                console.log('yayy order created', response);
                localStorage.setItem('orderHeader', JSON.stringify(response));
                localStorage.setItem("idOfHoldingClientObj", "0");
                this.orderHeader = response.onlineOrderHeader;

            } else {
                this.error.isDisplay = true;
                this.error.Message = response.displayMessage;
            }

        });


        console.log("customer infooooooo", this.localUserDetail);
    }

    changeCard(e) {
        if (e.target.value == "newCard") {
            this.showNewCard = true;
            this.showSaveButton = false;
            this.selectedCardToken = 0;
        } else {
            this.selectedCardToken = this.cardDetails.find(c => c.token == e.target.value);
            this.showNewCard = false;
            this.showSaveButton = true;
        }
    }

    //address setting 
    onAddressSubmit(): void {
        console.log("form submit==========================================");
        // console.log('Name:' + this.addressForm.get('name').value);
        // console.log('Age:' + this.addressForm.get('age').value);
        // console.log('College:' + this.addressForm.get('college').value);
        if(this.isReachableAddress==false){
            this.presentAlert('Sorry! We currently do not deliver to this location!');
        }
        if(this.isReachableAddress==true){
            this.isDeliveryChargeCalculated=true;

        }

        if (this.addressForm.valid) {
            var address = {
                "countryId": this.addressForm.value.country.id,
                "zip": this.addressForm.value.zipcode,
                "stateId": this.addressForm.value.state.id,
                "address1": this.addressForm.value.address1,
                "address2": this.addressForm.value.address2,
                "city": this.addressForm.value.city.cityName,
                "state": this.addressForm.value.state.stateName,
                "country": this.addressForm.value.country.displayName,
                "latValue": 0,
                "longValue": 0,
                "phone": this.localUserDetail.phone,
                "cityId": this.addressForm.value.city.id,
                "isDefaultAddress": 1
            };
            var user = {
                "id": this.localUserDetail.id,
                "dateofbirth": this.localUserDetail.dateofbirth,
                "email": this.localUserDetail.email,
                "firstName": this.localUserDetail.firstName,
                "phone": this.localUserDetail.phone,
                "lastName": this.localUserDetail.lastName,
                "updatedBy": 1,
            };
            console.log(this.addressList);
            this.addressList.push(address);
            var post_array = {
                "merchantId": Number(localStorage.getItem("account_id")),
                "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
                "clientId": localStorage.getItem('deviceId'),
                "locationId": this.locationDetails.location.id,
                "schemaName": "",
                "user": user,
                'addressList': this.addressList
            };
            var user_array = {
                "UserPostPacket": post_array
            };
            console.log("user packet ----------------------------------------------------------", user_array);
            this.orderService.updateCustomer(user_array).subscribe((response: any) => {
                console.log("address response============================================", response);
                if (response != undefined) {
                    this.selectedAddress = address;
                    // this.addressList = response.addressList;
                    this.updateOrder();
                    this.showNewAddress = false;
                    this.showEditAdd = false;
                } else {
                    this.error.isDisplay = true;
                    this.error.Message = response.displayMessage;
                }

            });


        } else {
            return;
        }

    }

    addNewAddress() {
        this.showNewAddress = true;
    }

    getStateByCountry(countryId, stateId) {
        this.orderService.getAllStatesByCountryId(countryId).subscribe((response: any) => {
            this.states = response;
            this.addressForm.get('state').setValue(response.find(c => c.id == stateId));
            this.getAllCityByStateId(stateId, this.locationDetails.location.address.cityId);
        });
    }

    getAllCityByStateId(stateId, cityId) {
        this.orderService.getAllCityByStateId(stateId).subscribe((response: any) => {
            this.cities = response;
            this.addressForm.get('city').setValue(response.find(c => c.id == cityId));
        });
    }
    get address1() {
        return this.addressForm.get('address1');
    }
    get zipcode() {
        return this.addressForm.get('zipcode');
    }

    stripe: any
    temporaryStripeKey: any;

    getStripeTokenKey() {
        // this.presentLoading('Loading...');
        console.log(this.selectedOrderGroup.name);

        if (this.selectedOrderGroup.name == "In Store" && this.locationDetails.locationSetting.hideContactlessPay) {
            this.DisableContactless = true;
        }
        this.orderService.getStripeKeyForWebsite(this.selectedOrderGroup.orderSouceDisplayPackets.id)
            .subscribe((res: any) => {
                // ;

                if (res.displayMessage) {
                    this.presentAlert(res.displayMessage);
                    return;
                }
                if (res.paymentGatewayTypeName == 'Razorpay') {
                    this.isRazorPayGateway = true;
                    this.razorPayKey = res.parameter2;
                }
                else {
                    this.isRazorPayGateway = false;
                    let stripeKey = res.parameter2;
                    this.temporaryStripeKey = res.parameter4;
                    this.stripe = Stripe(stripeKey);
                    this.setupStripe()
                }
            },
                (err) => {
                    // ;
                    console.log(err)
                })
    }

    setupStripe() {
        let elements = this.stripe.elements();
        var style = {
            base: {
                color: '#32325d',
                lineHeight: '24px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        this.card = elements.create('card', { hidePostalCode: true, style: style });
        this.card.mount('#card-element');

        this.card.addEventListener('change', event => {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        var form = document.getElementById('payment-form');
        form.addEventListener('submit', event => {
            event.preventDefault();

            this.getClientSecretKeyFromServer()

            // this.presentLoading('Processing...')

            // this.stripe.createSource(this.card).then(result => {
            //   if (result.error) {
            //     
            //     var errorElement = document.getElementById('card-errors');
            //     errorElement.textContent = result.error.message;
            //   } else {
            //     
            //      this.stripeDetails = result;

            //     this.getClientSecretKeyFromServer()

            //     // console.log(result);
            //     // this.isPaymentEditing = false;
            //   }
            // })
            // .catch(error=>{
            //   console.log(error)
            // });
        });
    }

    payWithRazorPay() {
        this.getClientSecretKeyFromServer()
    }
    getClientSecretKeyFromServer() {
        if(this.selectedOrderGroup.name!="Delivery"){
            this.isDeliveryChargeCalculated=true;
            this.isReachableAddress=true;
        }
        if(this.isReachableAddress==false){
            this.presentAlert('Sorry! We currently do not deliver to this location!');
            return false;
        }
       
        if (this.selectedOrderGroup.name == "Delivery") {
            if (this.selectedAddress == null || this.selectedAddress == '' || this.isDeliveryChargeCalculated ==false) {
                this.presentAlert('Please Save OR Select your Address');
                this.isPaymentInProcess = false;
                return false;
            }
        }
        console.log('form data', $('#payment-form').find('form').serialize())
        let tipVal = this.tip
        if (tipVal > 0)
            tipVal = tipVal.toFixed(2)
        else
            tipVal = '0.00'

        let orderPacket: any = {
            "OrderPacket": {
                "merchantId": Number(localStorage.getItem("account_id")),
                "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
                "clientId": localStorage.getItem('deviceId'),
                "locationId": this.locationDetails.location.id,
                "payment": {
                    "payment_option": null,
                    "id": this.orderHeader.id,
                    "amount": this.orderHeader.total.toString(),
                    "tip": tipVal,
                    "countryName": null,
                    "cardNumber": null
                },
                "orderHeader": {
                    "id": this.orderHeader.id
                }
            }
        }


        let isOrderAhead = 0;
        if (this.orderHeader.futureOrder == true)
            isOrderAhead = 1
        else
            isOrderAhead = 0

        let isRazorpay;

        if (this.isRazorPayGateway)
            isRazorpay = 1;
        else
            isRazorpay = 0;

        this.orderService.authorize3DAuthenticationForWebsite(this.selectedOrderGroup.orderSouceDisplayPackets.id,
            this.locationDetails.location.id, orderPacket, isRazorpay)
            .subscribe((res: any) => {


                if (res.displayMessage) {
                    this.presentAlert(res.displayMessage);
                    return;
                }

                if (this.isRazorPayGateway)
                    this.confirmPaymentToRazorPay(res)
                else
                    this.confirmPaymentToStripeGateway(res)


            },
                (err) => {

                    console.log(err);
                    this.presentAlert(err)
                })
    }

    confirmPaymentToRazorPay(res) {

        let options: any = {
            "key": this.razorPayKey,
            "amount": res.amount,
            "name": this.pageProperty.name,
            "description": this.pageProperty.subTitle,
            "image": this.pageProperty.logo,
            "order_id": res.clientSecret, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "modal": {
                "escape": false
            },
            "prefill": {
                "name": this.localUserDetail.firstName + " " + this.localUserDetail.lastName,
                "contact": this.localUserDetail.phone,
                "email": this.localUserDetail.email
            },
            "currency": 'INR',
            "theme": {
                "color": "#ff0016"
            },
            "notes": {
                "businessId": this.businessId
            }

        };
        options.handler = ((response) => {
            options['payment_response_id'] = response.razorpay_payment_id;
            this.updateRazorpayPaymentToServer(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature)
        });
        options.modal.ondismiss = (() => {
            // this.loginService.SetLoader = false;
            console.log('razorpay dismissed inside options')
        });
        let rzp = new this.winRef.nativeWindow.Razorpay(options);
        rzp.open();
    }

    updateRazorpayPaymentToServer(razorPayOrderId, razorPayPaymentId, razorPaySignitureId) {

        let tipVal = this.tip
        if (tipVal > 0)
            tipVal = tipVal.toFixed(2)
        else
            tipVal = '0.00'

        let orderPacket: any = {
            "OrderPacket": {
                "merchantId": Number(localStorage.getItem("account_id")),
                "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
                "clientId": localStorage.getItem('deviceId'),
                "locationId": this.locationDetails.location.id,
                "payment": {
                    "payment_option": null,
                    "id": this.orderHeader.id,
                    "amount": this.orderHeader.total.toString(),
                    "tip": tipVal,
                    "countryName": null,
                    "cardNumber": null
                },
                "orderHeader": {
                    "id": this.orderHeader.id
                }
            }
        }



        let isOrderAhead = 0;
        if (this.orderHeader.futureOrder == true)
            isOrderAhead = 1
        else
            isOrderAhead = 0

        this.orderService.authorizeSuccessfulForRazorPayWebsite(this.selectedOrderGroup.orderSouceDisplayPackets.id,
            this.locationDetails.location.id, isOrderAhead, razorPayOrderId, razorPayPaymentId, razorPaySignitureId, orderPacket)
            .subscribe((res: any) => {

                if (res.displayMessage) {
                    this.presentAlert(res.displayMessage);
                    return;
                }
                else {
                    console.log(res);
                    localStorage.setItem('orderHeader', JSON.stringify(res));

                    this.basicModal.hide();
                    this.onCloseCheckout.emit(false);
                }

            },
                (err) => {

                    console.log(err);
                    this.presentAlert(err)
                });


    }

    confirmPaymentToStripeGateway(res) {
        console.log('im here')
        this.stripe.confirmCardPayment(res.clientSecret, {
            payment_method: {
                card: this.card,
                // billing_details: {
                //   name: 'Jenny Rosen'
                // }
            }
        }).then(result => {
            if (result.error) {
                this.isPaymentInProcess = false;
                // Show error to your customer (e.g., insufficient funds)
                console.log(result.error.message);
                this.presentAlert(result.error.message)

            } else {
                console.log('im here1')
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    this.isPaymentInProcess = false;
                    this.updatePaymentToServer(result.paymentIntent.id)
                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.
                }
            }
        });
    }

    updatePaymentToServer(paymentIntentId) {
        let tipVal = this.tip
        if (tipVal > 0)
            tipVal = tipVal.toFixed(2)
        else
            tipVal = '0.00'

        let orderPacket: any = {
            "OrderPacket": {
                "merchantId": Number(localStorage.getItem("account_id")),
                "echoString": Number(localStorage.getItem("account_id")) + "updateOrderPaymentForDuplicateCheck_2-update Order Payment",
                "clientId": localStorage.getItem('deviceId'),
                "locationId": this.locationDetails.location.id,
                "payment": {
                    "payment_option": null,
                    "id": this.orderHeader.id,
                    "amount": this.orderHeader.total.toString(),
                    "tip": tipVal,
                    "countryName": null,
                    "cardNumber": null
                },
                "orderHeader": {
                    "id": this.orderHeader.id
                }
            }
        }



        let isOrderAhead = 0;
        if (this.orderHeader.futureOrder == true)
            isOrderAhead = 1
        else
            isOrderAhead = 0

        this.orderService.authorizeSuccessfulForWebsite(this.selectedOrderGroup.orderSouceDisplayPackets.id,
            this.locationDetails.location.id, isOrderAhead, paymentIntentId, orderPacket)
            .subscribe((res: any) => {

                if (res.displayMessage) {
                    this.presentAlert(res.displayMessage);
                    return;
                }
                else {
                    console.log(res);
                    localStorage.setItem('orderHeader', JSON.stringify(res));

                    this.basicModal.hide();
                    this.onCloseCheckout.emit(false);
                    //   this.router.navigateByUrl('/payment-success');
                }

            },
                (err) => {

                    console.log(err);
                    this.presentAlert(err)
                });

    }

    presentAlert(err) {
        this.error.isDisplay = true;
        this.error.Message = err;
    }

    isPaymentInProcess: boolean = false;
    authoriseCard() {
        if ( this.isDeliveryChargeCalculated==false){
            this.presentAlert('Please Save OR Select your Address');
            return false;
        }
        this.orderService.getOnlineOrderPaidById(this.orderHeader.id)
            .subscribe((res: any) => {
                console.log('payment response is====', res)
                if (!res.result) {
                    this.orderService.getOnlineOrderById(this.orderHeader.id).subscribe((orderHeader: any) => {
                        this.orderHeader = orderHeader;
                        if (orderHeader.amountPaid == orderHeader.balanceDue) {
                            alert('Order is already paid.');
                            this.basicModal.hide();
                            this.onCloseCheckout.emit(false);
                        } else {
                            this.mergeArray();
                            this.isPaymentInProcess = true;
                            $('#payButton').trigger('click')
                        }
                    });
                } else {
                    alert('Order is already paid.');
                    this.basicModal.hide();
                    this.onCloseCheckout.emit(false);
                }
            });

    }

    mergeArray() {
        let orderHeaderArray = this.orderHeader.onlineOrderDetailItems;
        for (let i in orderHeaderArray) {
            let found = false;
            for (let j in this.cartItems.items) {
                if (orderHeaderArray[i].id == this.cartItems.items[j].orderDetailId) {
                    this.cartItems.items[j] = this.createCartItem(orderHeaderArray[i]);
                    found = true;
                }
            }
            if (!found)
                this.cartItems.items.push(this.createCartItem(orderHeaderArray[i]))
        }
        console.log('order header', this.orderHeader, this.cartItems)
    }

    createCartItem(item) {

        let cartItem = {
            "id": item.itemsId,
            "displayName": item.name,
            "priceSelling": item.price,
            "subTotal": item.price,
            "quantity": item.itemsQty,
            "comment": item.specialRequest,
            "orderDetailId": item.id,
            "addedAttribute": [],
            "isItemRemoved": item.isItemRemoved
        };

        let attributes = [];
        let attr = item.onlineOrderDetailAttributes
        for (let i in attr) {
            let j
            attributes.forEach(function (x, index1) {
                if (x.itemAttributeTypeName === attr[i].name) {
                    j = index1;
                }
            });
            let attribute = {
                "id": attr[i].attributeId,
                "displayName": attr[i].name,
                "orderDetailAttributeId": attr[i].id,
                "sellingPrice": attr[i].sellingPrice
            }
            cartItem.subTotal += attribute.sellingPrice
            if (j) {
                attributes[j].attr.push(attribute)
            } else {
                attributes.push({ name: attr[i].itemAttributeTypeName, attr: [attribute] })
            }
        }
        cartItem.addedAttribute = attributes;
        return cartItem;

    }

    closeAddAddress() {
        this.showNewAddress = false;
        this.showEditAdd = false;
    }

    deleteAddress(address) {

        this.userService.deleteAddressByAddressId(address.id, this.localUserDetail.id)
            .subscribe((res: any) => {
                if (res.displayMessage) {
                    this.presentAlert(res.displayMessage);
                    return;
                }
                else {
                    this.userService.getUserByGlobalUsersId(this.globalUserId, this.locationDetails.location.id).subscribe((response: any) => {
                        if (response == null || response.displayMessage) {
                            // service to add user on local 
                            this.localUserDetail = null;
                            // alert();
                        } else {
                            localStorage.setItem('localUserId', response.id);
                            this.localUserDetail = {
                                "id": response.id,
                                "dateofbirth": response.dateofbirth,
                                "email": response.email,
                                "firstName": response.firstName,
                                "lastName": response.lastName,
                                "phone": response.phone,
                            };
                            if (this.selectedOrderGroup.name == "Delivery") {
                                this.address = response.addressesSet;
                                this.selectedAddress='';
                                if (response.addressesSet.length > 0) {
                                    let addressList = response.addressesSet;
                                    this.addressList = addressList.filter(c => c.status != "D");

                                    // console.log("new1-------------------------------------------------------", this.addressList);
                                    // this.selectedAddress = response.addressesSet[0];
                                    // this.localUserDetail.customerAddress = {
                                    //     "id": this.selectedAddress.id,
                                    //     "addressLine1": this.selectedAddress.address1,
                                    //     "addressLine2": this.selectedAddress.address2,
                                    //     "country": this.selectedAddress.country,
                                    //     "state": this.selectedAddress.state,
                                    //     "city": this.selectedAddress.city,
                                    //     "zip": this.selectedAddress.zip
                                    // }
                                } else {
                                    this.addressList = [];
                                }
                            }


                        }



                    });
                }
            }, err => {
                console.log(err);

            });

    }

    // google map ------------------------------//
    fillAddressForm(address, countryName) {
        let address1 = "";
        let zip, city, state, country, locality;
        if (countryName != "US")
            address1 += address.name + ', ';

        for (var i = 0; i < address.address_components.length; i++) {
            var types = address.address_components[i]['types'];
            if (types.indexOf('street_number') != -1)
                address1 += address.address_components[i]['short_name'] + ' ';
            if (types.indexOf('route') != -1 || types.indexOf('sublocality') != -1)
                address1 += address.address_components[i]['short_name'] + ',';
            if (types.indexOf('postal_code') != -1)
                zip = address.address_components[i]['short_name'];
            if (types.indexOf('locality') != -1)
                locality = address.address_components[i]['short_name'];
            if (types.indexOf('administrative_area_level_2') != -1)
                city = address.address_components[i]['short_name'];
            if (types.indexOf('administrative_area_level_1') != -1)
                state = address.address_components[i]['long_name'];
            if (types.indexOf('country') != -1)
                country = address.address_components[i]['long_name'];
        }

        address1 = address1.replace(/,+$/, '');

        this.addressForm.controls['zipcode'].setValue(zip);
        this.addressForm.controls['address2'].setValue(address1);

        /**set country */
        let tempSelectedCountry = this.countries.find(item => {
            return item.displayName.trim() == country.trim();
        });
        if (tempSelectedCountry.length > 0) {
            this.addressForm.get('country').setValue(tempSelectedCountry);
        }

        setTimeout(() => {
            this.getAllStatesByCountryIdAfterGoogleAutocomplete(tempSelectedCountry.id, state, locality)
        }, 200);

    }

    checkLocationSearched(event){
        console.log("we are checking whethere location got searched or Not")
        if(this.keepFieldsDisabled==false){
            alert('Please Search you Location/Address !');
            return false;
        }
        return;
    }

    getAllStatesByCountryIdAfterGoogleAutocomplete(countryId, state, city) {
        this.orderService.getAllStatesByCountryId(countryId)
            .subscribe(async (res: any) => {
                if (res.displayMessage) {
                    return;
                }

                this.states = res;
                var stateId = res.find(c => c.stateName.trim() == state.trim()).id;
                this.addressForm.get('state').setValue(res.find(c => c.stateName.trim() == state.trim()));
                setTimeout(() => {
                    this.getAllCitiesByStateIdAfterGoogleAutoComplete(stateId, city)
                }, 200);

            });
    }

    getAllCitiesByStateIdAfterGoogleAutoComplete(stateId, locality) {
        this.orderService.getAllCityByStateId(stateId)
            .subscribe(async (res: any) => {
                this.cities = res;
                let tempSelectedCity = this.cities.find(item => {
                    return item.cityName.trim() == locality.trim();
                });

                if (tempSelectedCity) {
                    this.addressForm.get('city').setValue(tempSelectedCity)
                }
                else
                    this.addressForm.get('city').setValue(this.cities[0]);
            });
    }

    openTermsModal(id:string) {
        this.TermsService.openDialog(id)
    }


    /**GOOGLE API ADDRESS AUTOCOMPLETE END */
}