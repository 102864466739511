import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { appServerConstants } from '../../app-url-constants';
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  token: any = "";
  ws: any;
  locationDetails:any
  constructor() { }

  public create() {
    this.closeConnection();
    this.token = localStorage.getItem('nxp-access-token');
    document.cookie = "sessionId=" + this.token;
    console.log('token', this.token)
    this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    var url = 'wss://test.nirvanaxp.com:443/POSNirvanaSocketV6/POSNWebSocketServlet?clientId='+localStorage.getItem(appServerConstants.AUTH_TOKEN)+ "&ClientType=JS&locationsId=" + this.locationDetails.location.id;
    this.ws = new WebSocket(url, this.token);
    this.ws.onopen = function() {
      console.log('open websocket');
    }
    this.ws.onmessage = function(evnt){console.log('message received');};
    return Observable.create(observer => {
      this.ws.onmessage = function(){console.log('message received');observer.next.bind(observer)};
      this.ws.onerror = observer.error.bind(observer);
      // this.ws.onclose = observer.complete.bind(observer);
      this.ws.onclose = function () {
        observer.error.bind(observer);
        observer.complete();
      }
      return this.ws.close.bind(this.ws);
    });
  }

  closeConnection() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }
}
