import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BusinessComponent } from './business.component';
import { BusinessRoutingModule } from './business-routing.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SharedModule } from '../shared';
import { CoreModule } from '../core/core.module';
import { CommonFooterModule } from '../shared/layout/common-footer.module';
import { MainHeaderModule } from '../shared/layout/main-header.module';
import { SliderModule } from 'angular-image-slider';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoaderComponent } from '../shared/loader/loader.component';



@NgModule({
  imports: [
    BusinessRoutingModule,SharedModule,CommonFooterModule,Ng2SearchPipeModule,
    MDBBootstrapModule.forRoot(),SliderModule,
    MainHeaderModule
  ],
  declarations: [
    BusinessComponent,
  ],
  providers: [
  ]
})
export class BusinessModule {}
