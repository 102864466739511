import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy, Output, EventEmitter,SimpleChanges } from '@angular/core';
import { ThankYouModalService } from './thank-you-modal.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you-modal',
  templateUrl: './thank-you-modal.component.html',
  styleUrls: ['./thank-you-modal.component.css']
})
export class ThankYouModalComponent implements OnInit {

  @Input() id: string;
  element;
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter();
  @Input()
  scheduleWay;
  @Input()
  currentDateTime;
  @Input()
  selectedOrderGroup;
  orderHeader: any;
  cartItems: any;
  currency:any;
  convenienceName: any;
  delieveryEstimate;
  @ViewChild('thankYouModal', { static: true }) basicModal: any;
  constructor(private modalService: ThankYouModalService, private el: ElementRef,private router:Router) {
    this.element = el.nativeElement;
    this.currency = localStorage.getItem('currency');

  }

  ngOnInit(): void {
    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
        if (property === 'scheduleWay') {
            this.scheduleWay = changes[property].currentValue;
        }
        if (property === 'currentDateTime') {
            this.currentDateTime = changes[property].currentValue;
        }
    }
  }

  

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    console.log('modal is removed2')
    this.modalService.remove(this.id);
    this.element.remove();
    this.onClose.emit(true);
  }

  open(): void {
    this.basicModal.show();
    let orderpacket = (localStorage.getItem("orderHeader") != undefined) ? JSON.parse(localStorage.getItem("orderHeader")) : null;
    this.orderHeader = (orderpacket) ? orderpacket.onlineOrderHeader : null;
    this.cartItems = JSON.parse(localStorage.getItem("cartItems"));
    this.delieveryEstimate = localStorage.getItem("delieveryEstimate");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("orderHeader");
    localStorage.removeItem('idOfHoldingClientObj');
    localStorage.removeItem('required_pickuptime');
    localStorage.removeItem('slotId');
    localStorage.removeItem('countDown');
    localStorage.removeItem('startDate');
    localStorage.removeItem('currentReservation');
    console.log('order header', this.orderHeader)
    this.convenienceName = this.selectedOrderGroup.orderSouceDisplayPackets.convenienceName;;
  }

  remove(): void {
    console.log('modal is removed')
  }

  // close modal
  close(): void {
    console.log('modal is removed1')
    this.basicModal.hide();
    this.onClose.emit(true);
    // this.router.navigate(['track-order']);

  }

  getAddress(orderHeader) {
    let location = JSON.parse(localStorage.getItem('locationDetails'));
    if (this.selectedOrderGroup && this.selectedOrderGroup.name == "Delivery") {
      if(orderHeader.customerInfo.customerAddress) {
        orderHeader.customerInfo.customerAddress.address1 = orderHeader.customerInfo.customerAddress.addressLine1;
        orderHeader.customerInfo.customerAddress.address2 = orderHeader.customerInfo.customerAddress.addressLine2;
      }
      return orderHeader.customerInfo.customerAddress;
      // return (orderHeader.addressShipping)?orderHeader.addressShipping.address1+","+orderHeader.addressShipping.city+","+orderHeader.addressShipping.state+","+orderHeader.addressShipping.zip:"";
    } else {
      return location.location.address;
      // return (location.address)?location.address.address1+","+location.address.city+","+location.address.state+","+location.address.zip:"";
    }
  }


  convertDate() {
    var str = moment.utc(this.orderHeader.scheduleDateTime);
    var now = moment(str).utcOffset(localStorage.getItem('gmt')).format('DD MMM, YYYY - ddd, hh:mm A');
    // var now = moment(str).format('DD MMM, YYYY - ddd, hh:mm A');
    return now;
  }

  closeModal() {
    this.basicModal.hide();
    this.onClose.emit(true);
    this.router.navigate(['track-order']);
  }

}
