export class CusineType {
    id: number;
    sortSequence: number;
    created: string;
    createdBy: number;
    updated: string;
    updatedBy: number;
    imageName: string;
    displayName: string;
    status: string;
    name: string;
}
