export class Business {
    id: number;
    cuisinesName: string;
    addressText: string;
    phone: string;
    ratings: string;
    logo: string;
    businessHours: string;
    businessStartTime: string;
    businessEndTime: string;
    name: string;
}
