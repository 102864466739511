import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuRoutingModule } from './menu-routing.module';
import { MenuComponent } from './menu.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SharedModule } from '../shared';
import { CoreModule } from '../core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleModalComponent } from '../shared/layout/schedule-modal/schedule-modal.component';
import { ScheduleModalService } from '../shared/layout/schedule-modal/schedule-modal.service';
import { AngularMaterialModule } from '../angular-material.module';
import { MainHeaderModule } from '../shared/layout/main-header.module';

import { CheckoutModalComponent } from '../shared/layout/checkout-modal/checkout-modal.component';
import { CheckoutModalService } from '../shared/layout/checkout-modal/checkout-modal.service';

import { UserModalComponent } from '../shared/layout/user-modal/user-modal.component';
import { UserModalService } from '../shared/layout/user-modal/user-modal.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ThankYouModalComponent } from '../shared/layout/thank-you-modal/thank-you-modal.component';
import { ThankYouModalService } from '../shared/layout/thank-you-modal/thank-you-modal.service';
import { CommonFooterModule } from '../shared/layout/common-footer.module';

@NgModule({
  declarations: [MenuComponent, ScheduleModalComponent, CheckoutModalComponent, UserModalComponent,ThankYouModalComponent],
  imports: [
    CommonModule,Ng2SearchPipeModule,
    MenuRoutingModule,SharedModule,
    NgbModule,MainHeaderModule,CommonFooterModule,
    AngularMaterialModule,
    MDBBootstrapModule.forRoot(),
  ],
  providers: [ScheduleModalService, CheckoutModalService, UserModalService,ThankYouModalService]
})
export class MenuModule { }
