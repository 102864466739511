import { Injectable } from "@angular/core";
import { RequestType, ContactPreference, ReservationSlot } from "../models";
import { HttpServiceProvider } from ".";

@Injectable({
  providedIn: "root",
})
export class ReservationService {
  constructor(private httpServiceProvider: HttpServiceProvider) {}

  getAllRequestTypeByLocationId(locationId: Number) {
    return this.httpServiceProvider.get<RequestType[]>(
      "/LookupService",
      "/CustomerService/getAllRequestTypeByLocationId/" + locationId,0
    );
  }

  getAllContactPreferenceByLocationId(locationId: Number) {
    return this.httpServiceProvider.get<ContactPreference[]>(
      "/LookupService",
      "/CustomerService/getAllContactPreferenceByLocationId/" + locationId,0
    );
  }

  getReservationSlotForDateAndLocationId(
    locationId: Number,
    dateString: String,
    time: String
  ) {
    return this.httpServiceProvider.get<ReservationSlot[]>(
      "/ReservationService",
      "/CustomerService/getReservationSlotForDateTimeAndLocationIdForWebsite/" +
        dateString +
        "/" +
        time +
        "/" +
        locationId,0
    );
  }


  holdReservationSlot(slotPost) {
    return this.httpServiceProvider.post('/ReservationService', '/CustomerService/holdReservationSlotForSlotId', slotPost,0);
  }

  getReservationWithOrderIdByUserId(userId) {
    return this.httpServiceProvider.get('/ReservationService', '/CustomerService/getReservationWithOrderIdByUserId/'+userId,0);
  }

  getReservationWithOrderIdByUserIdAndLocationId(locationId,userId) {
    return this.httpServiceProvider.get('/ReservationService', '/CustomerService/getReservationWithOrderIdByUserIdAndLocationId/'+userId+'/'+locationId,0);
  }

  unHoldReservationSlot(holdSlotId) {

      return this.httpServiceProvider.get('/ReservationService', '/CustomerService/unHoldReservationSlotForClientId/' + holdSlotId,0);

  }

  addForWebsite(reservationObj)
  {
    return this.httpServiceProvider.post('/ReservationService', '/CustomerService/addForWebsite', reservationObj,0);

  }

  updateReservationStatus(reservationObj)
  {
    return this.httpServiceProvider.post('/ReservationService', '/CustomerService/updateReservationStatus', reservationObj,0);

  }

  getReservationStatus(locationId) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/syncCdoByCdoNameAndUpdatedDate/reservations_status/2014-09-03%2008:56:10/'+locationId,0);
  }
}
