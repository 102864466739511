import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { PageProperty, City, LocationService ,Area} from 'src/app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appServerConstants } from '../../app-url-constants';
import { AlertDialogService } from '../alert-msg-dialog/alert-dialog.service';
import { SupportService } from 'src/app/support/support.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit, OnChanges {

  pageProperty: PageProperty;

  @Input()
  isHomePage: boolean = true;
  @Input()
  pageName: string;

  @Input('isshowlogoimage')
  isshowlogoimage:boolean;

  @Input()
  logoUrl:string;

  @Input() showSearch;

  cities: City[];


  @Input()
  areas:any;

  @Input('ratings')
  ratings:any;

  @Input('numberOfRatings')
  numberOfRatings:any;

  @Input('currentTimeDisplay')
  currentTimeDisplay:any;

  @Input('time')
  time:any;
  
  @Input('businessTime')
  businessTime:any;

  userDetails: any;
  @Input()
  countItem: number;

  @Output() selectCity = new EventEmitter<string>();
  @Output() searchText = new EventEmitter<string>();
  locationDetails: any;
  locationShow: boolean;
  isUserLogin: string;
  SelectedCity: any;
  SelectedArea:any;
  isCollapsed: boolean = true;
  constructor(private locationService: LocationService, private router: Router, private alertDialogService:AlertDialogService, private support:SupportService) {



  }

  ngOnInit(): void {
    this.areas = JSON.parse(localStorage.getItem("areas"));
    this.cities = JSON.parse(localStorage.getItem("cities"));
    
    this.SelectedCity = JSON.parse(localStorage.getItem("SelectedCity"));
    this.SelectedArea = JSON.parse(localStorage.getItem("SelectedArea"));

   if (appServerConstants.PROPERTY_ID ==1 && this.SelectedArea == undefined ){

   
      this.locationService.getAreaByCityId(this.cities[0].id)
      .subscribe((response: any) => {
        this.areas = response;
        localStorage.setItem("areas", JSON.stringify(this.areas));

        console.log(response);
        localStorage.setItem("SelectedArea", JSON.stringify( this.areas));
     this.SelectedArea = JSON.parse(localStorage.getItem("SelectedArea"));
     
      });
      console.log("receiving value from menu"+this.isshowlogoimage)



    
  }

    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
    if (!localStorage.getItem("pageProperty")) {
      this.locationService.getAllOnPageProperty(appServerConstants.PROPERTY_ID)
        .subscribe(tags => {
          this.pageProperty = <PageProperty>tags;
          localStorage.setItem("pageProperty", JSON.stringify(this.pageProperty));
        });
    }
    this.isUserLogin = localStorage.getItem('isUserLogin');
    this.userDetails = JSON.parse(localStorage.getItem("currentUser"));
    this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    if (this.locationDetails)
      this.locationShow = true;
    else
      this.locationShow = false;

    console.log("page name --------------------------------", this.pageProperty);
  }
  ngOnChanges(changes: SimpleChanges) {

    for (let property in changes) {
      if (property === 'countItem') {
        this.countItem = changes[property].currentValue;
      }else if(property === 'isshowlogoimage') {
        this.isshowlogoimage = changes[property].currentValue;
      }
      else if(property === 'logoUrl') {
        this.logoUrl = changes[property].currentValue;
      }


    }
  }


  logoutButtonClick() {
    this.alertDialogService.confirm('', "Are you sure you want to Logout?", 2, "Yes")
              .then((confirmed) => {
                if(confirmed){
                localStorage.removeItem("globalUserId");
                localStorage.removeItem("currentUser");
                localStorage.removeItem("isUserLogin");
                this.isUserLogin = localStorage.getItem('isUserLogin');
                }
              });

    // this.router.navigate(['/']);
    // console.log("logoutButtonClick3");
  }

  cityHomeCliked(area: Area) {
    // localStorage.setItem("SelectedCity", JSON.stringify(city));
    localStorage.setItem("SelectedArea", JSON.stringify(area));

    this.SelectedArea = area;

    this.selectCity.emit(JSON.stringify(area));

    this.router.navigate(['/business', area.id]);
  }

  keyup(event) {
    this.searchText.emit(event);
  }

  SupportPopup() {
    console.log("Opening Support");
    this.support.openDialog();
  }

}