<div class="pull-right">
<button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button> </div>
<div *ngIf="id=='Terms'" class="terms-modal">
        <p><b>Vegaschow.com Terms and Conditions: Refund Policy</b> <br/></p>
        <p>Welcome to Vegaschow.com! We are delighted to serve as your preferred online food ordering platform, <br/>connecting you with a diverse range of restaurants and eateries. Before you embark on this culinary <br/>journey, we want to make sure you're familiar with our refund policy. Please take a moment to read and <br/>understand the terms and conditions outlined below: <br/></p>
        <p>1. Refund Policy Overview: At Vegaschow.com, we strive to provide you with a seamless and satisfying <br/>food ordering experience. Our commitment is to ensure that you receive food orders that meet your <br/>expectations in terms of quality, taste, and presentation. However, there might be instances when you're <br/>dissatisfied with your order and wish to request a refund. <br/></p>
        <p>2. Refund Eligibility: Please note that refunds are subject to certain conditions. Vegaschow.com does not <br/>offer automatic refunds for food orders. Refunds will only be considered if you have raised a valid <br/>dispute with our customer service team within a specific timeframe. The eligibility for a refund is <br/>determined by the following factors: <br/></p>
        <p>&#8226; Quality Issue: If the received food order significantly deviates from the expected quality <br/>standards, such as being undercooked, overcooked, spoiled, or containing foreign objects. <br/></p>
        <p>&#8226; Incorrect Order: If you receive an order that is materially different from what you originally <br/>requested and ordered. <br/></p>
        <p>&#8226; Non-Delivery: If you have not received your order within the estimated delivery time and <br/>reasonable attempts to contact the restaurant and resolve the issue have failed. <br/></p>
        <p>3. Process for Requesting a Refund: To initiate a refund request, follow these steps: <br/></p>
        <p>&#8226; Step 1: Contact our customer service team immediately upon discovering the issue with your <br/>order. <br/></p>
        <p>&#8226; Step 2: Provide detailed information about the problem, including order number, description of <br/>the issue, and supporting evidence (photos, videos, etc.). <br/></p>
        <p>&#8226; Step 3: Our customer service team will evaluate your refund request and work with you to <br/>gather any necessary information. <br/></p>
        <p>&#8226; Step 4: If your refund request is approved, the refund amount and method will be determined <br/>based on the specific circumstances. <br/></p>
        <p>4. Timeframe for Refund Requests: Refund requests must be submitted to Vegaschow.com within 24 <br/>hours of receiving the order. After this period, refund requests will not be accepted, and the transaction <br/>will be considered final. <br/></p>
        <p>5. Discretion and Evaluation: All refund requests are subject to the discretion of Vegaschow.com. Our <br/>team will evaluate each case individually, considering the details provided by both the customer and the <br/>restaurant. We aim to ensure fairness and customer satisfaction in each decision. <br/></p>
        <p>6. Refund Process: Upon approval of a refund request, the refund amount will be processed through the <br/>original payment method used for the order. The timeframe for receiving the refunded amount may vary <br/>depending on your payment provider's policies. </p>
        <p>7. Customer Responsibility: As a customer, you have a responsibility to inspect your food order upon <br/>receipt. If you encounter any issues, it is essential to contact our customer service team promptly to <br/>initiate the refund process. <br/></p>
        <p>8. Feedback and Improvement: Your feedback is valuable to us. If you encounter any issues with your <br/>order, even if you don't intend to request a refund, please let us know. Your feedback helps us improve <br/>the overall experience for all users. <br/></p>
        <p>9. Exceptions: Please note that the following situations are not eligible for refunds: <br/></p>
        <p>&#8226; Change of Preference: If you change your mind about your order after it has been placed. <br/></p>
        <p>&#8226; Incorrect Address: If you provide an incorrect delivery address or fail to provide access to the <br/>delivery location. <br/></p>
        <p>&#8226; Force Majeure: If circumstances beyond our control, such as natural disasters or strikes, affect <br/>the delivery or quality of your order. <br/></p>
        <p>10. Contact Us: For any questions, concerns, or refund requests, please contact our customer service <br/>team through the provided channels on the Vegaschow.com website. <br/></p>
        <p>By using Vegaschow.com, you acknowledge that you have read and understood our refund policy. This <br/>policy is designed to ensure a fair and transparent process for both customers and restaurants. We <br/>appreciate your trust in our platform and look forward to serving you delicious meals from a variety of <br/>culinary establishments. <br/></p>
        
        
</div>

<div *ngIf="id=='Policy'" class="terms-modal">
    <p><b>Privacy Policy for VegasChow.com</b></p>

<p>Thank you for choosing VegasChow.com for your online ordering needs. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services.</p>

<p>By accessing or using VegasChow.com, you agree to the terms outlined in this Privacy Policy. Please take a moment to review the following information.</p>

<p>1. Information We Collect 1.1 Personal Information We may collect personal information, such as your name, email address, phone number, and delivery address when you create an account or place an order on VegasChow.com. We use this information to process your orders and communicate with you about your transactions.</p>

<p>1.2 Payment Information To facilitate secure transactions, we may collect and store payment information, including credit card details, through our payment processor. This information is encrypted and securely handled in compliance with industry standards.</p>

<p>1.3 Usage Information We gather information about how you interact with our website, including your IP address, browser type, device information, and pages visited. This data helps us improve our website, analyze trends, and enhance user experience.</p>

<p>2. How We Use Your Information 2.1 Order Processing We use your personal and payment information to process and fulfill your orders, including delivery and customer support.</p>

<p>2.2 Communication We may use your contact information to send transactional emails related to your orders, account updates, and promotional messages. You can opt-out of promotional communications at any time.</p>

<p>2.3 Analytics We use analytics tools to analyze user behavior, track website performance, and improve our services.</p>

<p>3. Information Sharing 3.1 Third-Party Service Providers We may share your information with third-party service providers (e.g., payment processors, delivery services) to facilitate order processing and enhance our services. These providers are obligated to maintain the confidentiality and security of your information.</p>

<p>3.2 Legal Compliance We may disclose your information as required by law, court order, or government regulation.</p>

<p>4. Data Security We implement industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.</p>

<p>5. Cookies and Tracking Technologies We use cookies and similar technologies to enhance your experience, analyze usage patterns, and improve our services. You can manage your cookie preferences through your browser settings.</p>

<p>6. Your Choices You can manage your account settings and preferences through your VegasChow.com account. You have the right to access, correct, or delete your personal information. If you have any concerns or requests, please contact us at [mailto:contact@vegaschow.com].</p>

<p>7. Changes to this Privacy Policy We reserve the right to update this Privacy Policy periodically. Any changes will be effective immediately upon posting the revised policy on our website.</p>

<p>8. Contact Us If you have questions or concerns about this Privacy Policy, please contact us at:</p>

<p>VegasChow.com Email: [mailto:contact@vegaschow.com]</p>

<p> Thank you for trusting VegasChow.com with your online ordering needs.</p>
    
    
</div>

