<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark  lighten-1"  [containerInside]="false" style="

  background-color: transparent !important;
  min-height: 60px;
  box-shadow: none !important;
  border-style: none;height: 60px;"
*ngIf="isHomePage ; else elseHeader">

  <!-- Navbar brand -->

  <mdb-navbar-brand>
    <a class="navbar-brand" >
   <img class="header-logo" [src]="pageProperty?.appLogo"  height="60" alt="logo" type="image/svg+xml"/> </a>

   </mdb-navbar-brand>
  <!-- Collapsible content -->
  <links>

    <!-- Links -->
    <ul class="navbar-nav mr-auto">
       <li class="nav-item">
          <!-- <a [href]="pageProperty?.facebookUrl" class="fa facebook">
            <span class="sr-only">(current)</span>
          </a> -->

          <a [href]="pageProperty?.facebookUrl">
            <img src="assets/image/facebook.png" alt="facebook" class="social" type="image/svg+xml">
          </a>

        </li>
        <li class="nav-item">
          <a [href]="pageProperty?.instagramUrl">
            <img src="assets/image/instagram.png" alt="instagram" class="social" type="image/svg+xml">
          </a>
          <!-- <a [href]="pageProperty?.instagramUrl" class="fa instagram"></a> -->
        </li>
        <li class="nav-item">

          <a [href]="pageProperty?.twitterUrl">
            <img src="assets/image/twitter.png" alt="twitter" class="social" type="image/svg+xml">
          </a>
        </li>
      <!-- Dropdown -->
      <!-- <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Basic dropdown<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
          <div class="divider dropdown-divider"></div>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
        </div>
      </li> -->

    </ul>
    <!-- Links -->


    <!-- <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="navbar-right" *ngIf="!isUserLogin"><button type="button" class="btn btn-light btn-rounded white-background"
          style="font-size: 16px;font-style: normal; color: #202020;padding: 7px; text-transform: none;"
          [routerLink]="['/loginasguest', 'home']" routerLinkActive="router-link-active">Login</button></li>
        <li class="navbar-right" *ngIf="!isUserLogin"><button type="button" class="btn btn-dark btn-rounded black-background"
          style="font-size: 16px; font-style: normal;color:#ffffff ;padding: 7px; text-transform: none;"  
           [routerLink]="['/signup',0,'home']" routerLinkActive="router-link-active">Customer Sign Up</button></li>
           <li class="navbar-right" *ngIf="!isUserLogin"><button type="button" class="btn btn-light btn-rounded white-background"
            style="font-size: 16px;font-style: normal; color: #202020;padding: 7px; text-transform: none;"
            [routerLink]="['/bizsignup']" routerLinkActive="router-link-active">Business Signup</button></li>
        <li class="navbar-right" *ngIf="isUserLogin"><button type="button" class="btn btn-dark btn-rounded black-background"
            style="font-size: 16px; font-style: normal;color:#ffffff ;padding: 7px; text-transform: none;"   (click)="logoutButtonClick()">Logout</button></li>

    </ul> -->
  </links>


  <!-- Collapsible content -->

</mdb-navbar>

<ng-template #elseHeader>
<nav class="navbar navbar-expand-md navbar-light fixed-top" style="background-color:#fff; border:1px solid lightgray;border-top:none;" *ngIf="!isHomePage">
  <a class="logo_class">
    <div style="width: 750px; height: 55px;margin-left: -10%;"*ngIf="isshowlogoimage ; else elseLogoImage">
      <div class="col-md-12" style="font-size: 12px; height: 60px;position: absolute;">
        <img class="lib-img-show rounded"
            style="width: 80PX;height: 60px;"
            [src]="(locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'">
      </div>
      <div style="margin-left: 125px; height: 60px;">
        <span class="lib-row lib-header" style="width: 50%;margin: 0%;font-size: 18px; font-weight: 800;">{{locationDetails.location.name}}<br/></span>
        <span class="lib-row lib-desc reduce-line-spacing" style="display: contents; margin-top: -5px;">
          <span class="col-xl-3 p-0" style="color:#ff1123; font-size: 11px;font-weight: 600;">Address: </span>
          <span class="col-xl-9 p-0" style="font-size: 11px;">{{locationDetails.location.address.address1}},<!--<br/> {{locationDetails.location.address.address2}},-->
            {{locationDetails.location.address.city}},
            {{locationDetails.location.address.state}},
            {{locationDetails.location.address.zip}}<br/>
          </span>
          <span class="col-xl-3 p-0" style="color: #ff1123; font-size: 11px; font-weight: 600;">Call Now: </span>
          <span class="col-xl-9 p-0" style="font-size: 11px;">{{locationDetails.location.address.phone}}<br/></span>
          <!-- <span class="col-xl-3 p-0" style="color: #ff1123;">Hours:</span>
          <span class="col-xl-9 p-0">
            <span *ngFor="let time of businessTime">{{time}}<br/></span>
            <span *ngIf="businessTime.length==0">{{currentTimeDisplay}}</span>
          </span> -->
        </span>
        <!-- <span style="color:#ff1123; font-size: 10px;">Real Reviews
          <span class="badge" style="background-color: #ff1123;color: #fff; font-size: 10px;">{{ratings}}</span><br/>
        </span>
        <span style="font-size: 10px;"> {{numberOfRatings}} ratings</span> -->
      </div>
    </div>
    <ng-template #elseLogoImage>
    <img [src]="pageProperty?.logo" width="200" height="60" style="align-items: center; display: flex;" alt="logo"/> 
    </ng-template>
  </a>
  <button class="navbar-toggler ml-auto custom-toggler"type="button"data-toggle="collapse"(click)="isCollapsed= !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- The menu items -->
  <!--<div class="collapse navbar-collapse" id="navbarSupportedContent">-->
 <div class="collapse navbar-collapse"[ngbCollapse]="isCollapsed"id="navbarSupportedContent">
  <!-- <ul class="navbar-nav float-right" style="border-right: 1px solid lightgray;padding-right: 15px;padding-left: 15px;" *ngIf="SelectedArea">
    <li class="nav-item dropdown" ngbDropdown>
      <a class="nav-link dropdown-toggle" id="id01"  ngbDropdownToggle style="color: #000;">
        <mdb-icon fas icon="map-marker-alt"></mdb-icon> </a>
      <div class="dropdown-menu" aria-labelledby="id01" ngbDropdownMenu style="margin: 0.8rem 0 0;">
        <a class="dropdown-item"  *ngFor="let city of areas; let i = index" (click)="cityHomeCliked(city)">
          <mdb-icon fas icon="map-marker-alt"></mdb-icon> {{city.name}}</a>
      </div>
    </li>
  </ul> -->
  <ul class="navbar-nav float-right" style="border-right: 1px solid lightgray;padding-right: 15px;padding-left: 15px;"
  *ngIf="isUserLogin">
    <li class="nav-item dropdown" ngbDropdown>
      <a class="nav-link dropdown-toggle" id="id01"  ngbDropdownToggle style="color: white;background-color: var(--background-color,red);border-radius: 20px;">
        <mdb-icon far icon="user"></mdb-icon> {{userDetails.firstName}} {{userDetails.lastName}}</a>
      <div class="dropdown-menu" aria-labelledby="id01"  ngbDropdownMenu style="margin: 0.8rem 0 0;">
        <a class="dropdown-item" [routerLink]="['/order-history']" routerLinkActive="router-link-active" *ngIf="locationShow">My Orders</a>
        <a class="dropdown-item" [routerLink]="['/track-order']" routerLinkActive="router-link-active" *ngIf="locationShow">Track Orders</a>
        <!-- <a class="dropdown-item" [routerLink]="['/reservation']" routerLinkActive="router-link-active" *ngIf="locationShow">Reservation</a> -->
        <a class="dropdown-item" [routerLink]="['/signup',1,pageName]" routerLinkActive="router-link-active">My Account</a>
        <a class="dropdown-item" (click)="logoutButtonClick()">Logout</a>
        <a class="dropdown-item" (click)="SupportPopup()">Support</a>

      </div>
    </li>
  </ul>
  <ul class="navbar-nav float-right" style="border-right: 1px solid lightgray;padding-right: 15px;padding-left: 15px;"
  *ngIf="!isUserLogin">
    <li class="nav-item dropdown" ngbDropdown>
      <button type="button" class="btn btn-rounded"
        style="font-size: 16px;font-style: normal; color: white;padding: 7px; text-transform: none;background-color: var(--background-color,red);border-radius: 20px;"
        [routerLink]="['/loginasguest', pageName ]" routerLinkActive="router-link-active">Login</button>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item" *ngIf="showSearch==1" style="border-left: 1px solid lightgray;padding-left: 10px;">
        <form class="form-inline waves-light" mdbWavesEffect>
            <div class="md-form my-0" style="font-size: 18px;">
                 <mdb-icon fas icon="search"></mdb-icon>
                <input class="form-control mr-sm-2" type="text" placeholder="Search" (keydown.enter)="$event.preventDefault()" (keyup)="keyup($event.target.value)">
            </div>
        </form>
    </li>
    <li *ngIf="countItem>=0" class="nav-item" style="font-size: 18px;"  style="border-left: 1px solid lightgray;padding-left: 10px;">
        <a class="nav-link variable" style="font-size: 18px;">
            <img src="assets/images/shopping-cart-solid.svg" style="width: 18px;filter: brightness(0) invert(1);">
            <span class="badge ml-1" style="font-size: 12px;">{{countItem}}</span>
        </a>
    </li>

</ul>

  </div>
  <!-- <a >
    </a> -->


  </nav>
</ng-template>
<!-- <div style="margin-top: 60px;" *ngIf="!isHomePage">&nbsp;</div> -->
