import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationComponent } from './reservation.component';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material.module';
import { MainHeaderModule } from '../shared/layout/main-header.module';
import { CommonFooterModule } from '../shared/layout/common-footer.module';
import { Time24To12Format } from '../shared/utility/timeconversion';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CoreModule } from '../core';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

const routes: Routes = [
  {
    path: '',
    component: ReservationComponent
  }

];

@NgModule({
  declarations: [ReservationComponent,Time24To12Format
  ],

  imports: [
    MDBBootstrapModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule,
    [RouterModule.forChild(routes)],
    MainHeaderModule,
    CommonFooterModule,
    NgbModule,
    CoreModule,
    NgSelectModule,
    Ng2SearchPipeModule


  ],

  exports: [RouterModule],



})


export class ReservationModule { }
