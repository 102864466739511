<app-main-header [isHomePage]="isHomePage"  [showSearch]="0" [pageName]="page"
*ngIf="page == 'home' || page == 'business'; else elseHead"

>
</app-main-header>


  <ng-template #elseHead>
    <app-main-header [isHomePage]="isHomePage" [showSearch]="0" [pageName]="page"
    [isshowlogoimage]="'true'" [logoUrl]="
 (locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'"

    ></app-main-header>

  </ng-template>
<div class="container">
  <br />
  <h5 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-size:36px;
  font-family: 'Poppins-Regular';
  font-style: normal;margin-bottom: 20px;">{{pageProperty?.subTitle}}</h5>
  <h5 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-size:36px;
  font-family: 'Poppins-SemiBold';
  font-style: normal;margin-bottom: 20px;">Login</h5>
  <p style="
  text-align: center;
  color: #767676;
  width: 100%;
  font-family: 'Poppins-Regular';
  font-size:18px;">If you have an account with us, sign in using your mobile number.</p>
  <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
    <mat-card>
      <mat-card-content>


        <mat-error *ngIf="loginInvalid && isSubmitted">
          The username and password were not recognised
        </mat-error>


        <div class="row">
          <div class="col-md-3 coldesign ">
            <mat-form-field appearance="outline"  class="example-full-width text-dark text-center">
              <mat-select style="height: 19px;"formControlName="countryCode"   panelClass="matpanelClassUniveral"
              disableOptionCentering>
                 <input #searchInput class="text-center searchbox"  placeholder="Search Here"  (keyup)="onKey($event.target.value)">
                <!-- <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
                  <mat-icon>close</mat-icon>
                </button> -->
                <mat-select-trigger>+{{loginForm.controls.countryCode.value.phoneCode}}</mat-select-trigger>

                <ng-container *ngFor="let country of countryData">
                <!-- <mat-option    *ngIf ="country.shortName && country.phoneCode!=0" [value]="country.phoneCode"> +{{country?.phoneCode}}({{country.shortName|lowercase}})</mat-option> -->
                <mat-option    *ngIf ="country.phoneCode!=0" [value]="country">{{country?.displayName }}-({{country?.phoneCode }})</mat-option>

              </ng-container>
              </mat-select>


          </mat-form-field>

          </div>
          <div class="col-md-9 coldesign ">
            <mat-form-field appearance="outline">
              <input style="height: 20px;" matInput color="primary" matInput type ="tel" placeholder="  Mobile Number" formControlName="uname" required>
              <mat-error>
                Please provide a valid mobile number
              </mat-error>
            </mat-form-field>
          </div>

        </div>



        <div class="row">
          <div class="col-md-12 coldesign ">
            <mat-form-field appearance="outline" class="full-width-input">
              <input style="height: 20px;" matInput type="password" placeholder="Password" formControlName="password" required>
              <mat-error>
                Please provide a valid password
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        




      </mat-card-content>
      <mat-card-footer>
        <button class="button" type="submit" style="width: 230px;">Login</button>
        <button class="button" type="button" [routerLink]="['/loginasguest',page]" style="width: 230px;">Continue As a Guest </button>
        <a style="color: red;font-size: 14px;display: grid; margin-top: 10px;" [routerLink]="['/forgot-password', page ]" routerLinkActive="router-link-active">Forgot Password?</a>
      </mat-card-footer>


    </mat-card>
  </form>


  <h6 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-family: 'Poppins-SemiBold';
  font-size:18px;
  font-style: normal;margin-bottom: 20px;">Don't have an account? <a
      style="color: #ff0e20;font-family: 'Poppins-SemiBold';" [routerLink]="['/signup',0, page]">Sign Up</a></h6>


</div>


<!-- <app-common-footer [pageProperty]="pageProperty" [cities]="cities"></app-common-footer> -->
