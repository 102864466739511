import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService, UserService,PageProperty } from '../core';
import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TrackOrderComponent implements OnInit {

  isHomePage: boolean = false;
  countItem = 0;
  cityId = JSON.parse(localStorage.getItem("selectedAreaId"));
  screenMode = "";
  localUserId: any;
  orderSourceGroup: any;
  locationDetails: any;
  currentOrders: any;
  driverName:any;
  driverPhone:any;
  trackingUrl:any
  orderSrc: any;
  sourceGrp: any;
  statusArray: any;
  searchText: string;
  currency: any;
  pageProperty: PageProperty;
  businessId:any;
  isMint:any;

  constructor(private route: ActivatedRoute, private orderService: OrderService, private userService: UserService,
    private titleService: Title) {
    this.currency = localStorage.getItem('currency');

    this.localUserId = JSON.parse(localStorage.getItem('localUserId'));
    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
 // this.titleService.setTitle(this.pageProperty.name);
    this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    this.screenMode = this.route.snapshot.params.mode;
    this.businessId = localStorage.getItem("business_id");
    if ([105, 1622, 3176].includes(Number(this.businessId))) {
      this.isMint = true;console.log("Changing to green....");
    }

    const backgroundColor = this.isMint ? '#95ca4c' : '#fc0d20';
    const borderRadius = this.isMint ? '2rem 0' : '0.35rem';
    const leaf = this.isMint ? 'url("/assets/images/Mint_Leaf_image.png") center/contain no-repeat' : '';

    document.documentElement.style.setProperty('--background-color', backgroundColor);
    document.documentElement.style.setProperty('--border-Radius', borderRadius);
    document.documentElement.style.setProperty('--leaf', leaf);
    console.log('local user', this.localUserId);
    let orderSrc = [];
    let sourceGrp = [];
    this.userService.getUserByGlobalUsersId(localStorage.getItem('globalUserId'), this.locationDetails.location.id).subscribe((response: any) => {
      if (response == null || response.displayMessage) {
      } else {
        localStorage.setItem('localUserId', response.id);
        this.orderService.getAllOrderSouceGroupDisplayByLocationId(this.locationDetails.location.id).subscribe(async (response: any) => {
          console.log(response);
          this.orderSourceGroup = response;
          for (let i in this.orderSourceGroup) {
            if (!this.orderSourceGroup[i].orderSouceDisplayPackets) continue;
            console.log('order source group', this.orderSourceGroup[i])
            orderSrc[this.orderSourceGroup[i].orderSouceDisplayPackets.id] = this.orderSourceGroup[i].id;
            await this.orderService.getAllOrderStatusByLocationIdAndOrderSourceGroupId(this.locationDetails.location.id, this.orderSourceGroup[i].id).subscribe((response: any) => {
              sourceGrp[this.orderSourceGroup[i].id] = response;
            });
          }
          this.orderSrc = orderSrc;
          this.sourceGrp = sourceGrp;
          console.log('order service', this.orderSrc, this.sourceGrp)
          await this.orderService.getOrdersByUserIdAndLocationIdAndPaidStatus(this.locationDetails.location.id, this.localUserId).subscribe((response: any) => {
            console.log(response);
            this.currentOrders = response;
            this.currentOrders.reverse();
            let statusArray = []
            for (var i in this.currentOrders) {
              statusArray[this.currentOrders[i].orderHeader.id] = cloneDeep(this.getStatus(this.currentOrders[i].orderHeader.orderSourceId));
            }
            this.statusArray = statusArray;
            console.log('order status array', this.statusArray)
          });
        });
      }
    });

  }

  getStatus(orderSourceId) {
    let orderSourceGroup = this.orderSrc[orderSourceId];
    let statusArray = [];
    for (let i in this.sourceGrp[orderSourceGroup]) {
      if (this.sourceGrp[orderSourceGroup][i].isOrderTracking != 0) {
        this.sourceGrp[orderSourceGroup][i].class = "disabled";
        this.sourceGrp[orderSourceGroup][i].date = "";
        statusArray.push(this.sourceGrp[orderSourceGroup][i])
      }

    }
    return statusArray.sort((a, b) => (parseInt(a['displaySequence']) > parseInt(b['displaySequence'])) ? 1 : -1);
  }

  getOrderStatus(orderId) {
    this.orderService.getOrderStatusHistoryByOrderId(orderId).subscribe((response: any) => {
      for (let i in response) {
        for (let j in this.statusArray[orderId]) {
          if (response[i].orderStatusId == this.statusArray[orderId][j].id) {
            this.statusArray[orderId][j].class = "complete";
            this.statusArray[orderId][j].date = moment(response[i].updated).utcOffset(localStorage.getItem('gmt')).format('DD MMM, YYYY-hh:mm a');
            console.log('status', this.statusArray[orderId][j])
          }
        }
      }
      this.driverName='';
      this.driverPhone='';
      this.trackingUrl='';
      this.getDriverDetails(orderId);
      console.log('status array', this.statusArray)
    })
  }

  getDriverDetails(orderId){
    this.orderService.getDeliveryPartnerByOrderId(orderId).subscribe((response :any)=>{
      this.driverName=response.runnerName;
      this.driverPhone=response.runnerPhone;
      this.trackingUrl = response.trackingUrl;

console.log("driver details",this.driverPhone,this.driverName,this.trackingUrl);
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    document.documentElement.style.removeProperty('--background-color');
    document.documentElement.style.removeProperty('--border-Radius');
    document.documentElement.style.removeProperty('--leaf');
  }

  searchMenu(searchText) {
    this.searchText = searchText;
  }
}
