import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../core/services/user.service';
import { PageProperty, LocationService, City, Country } from '../core';
import { Title, Meta } from '@angular/platform-browser';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @ViewChild(MatSelect, {static:false}) matSelect: MatSelect;
  pageProperty: PageProperty;

  redColorHex: string = "#ff0016";
  compareWith: any;
  locationDetails:any;
  loginForm: FormGroup;
  isSubmitted: boolean = false;
  loginInvalid: boolean = false;
  loading: any;
  cities: City[];
  isHomePage: boolean = false;
  toSelect: any;
  isMint: boolean = false;

  countryData: Country[];
  filteredOptions: Country[];
  page: string;
  businessId: any;
  countItem = 0;

  constructor(public formBuilder: FormBuilder, private router: Router, private userService: UserService
    , private route: ActivatedRoute, private locationService: LocationService, private titleService: Title) { 

      this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));

    }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      countryCode: ['', [Validators.required]],
      uname: ['', [Validators.required, Validators.minLength(10)]],
      password: ['', [Validators.required, Validators.minLength(2)]],
    })

    this.cities = JSON.parse(localStorage.getItem("cities"));
    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
    this.countryData = JSON.parse(localStorage.getItem("countries"));
    this.filteredOptions = this.countryData;
    this.page = this.route.snapshot.params.page;
    this.businessId = localStorage.getItem("business_id");
 // this.titleService.setTitle(this.pageProperty.name);

    if ([105, 1622, 3176].includes(Number(this.businessId))) {
      this.isMint = true;console.log("Changing to green....");
    }

    const backgroundColor = this.isMint ? '#95ca4c' : '#fc0d20';
    const borderRadius = this.isMint ? '2rem 0' : '0.35rem';
    const leaf = this.isMint ? 'url("/assets/images/Mint_Leaf_image.png") center/contain no-repeat' : '';

    document.documentElement.style.setProperty('--background-color', backgroundColor);
    document.documentElement.style.setProperty('--border-Radius', borderRadius);
    document.documentElement.style.setProperty('--leaf', leaf);

    if (localStorage.getItem("cartItems") && localStorage.getItem("cartItems") != undefined) {
      var cartItems = JSON.parse(localStorage.getItem("cartItems"));
      var countItem = 0;
      for (var i = 0; i < cartItems.items.length; i++) {
        countItem = countItem + cartItems.items[i].quantity;
      }
      this.countItem = countItem;
    }

    console.log(this.countryData);

    if (!this.countryData || this.countryData.length == 0){
      this.locationService.getAllCountries()
        .subscribe((response: any) => {
          this.countryData = response;
          this.filteredOptions = response;
          console.log(response);
          localStorage.setItem("countries", JSON.stringify(this.countryData));
          let city = JSON.parse(localStorage.getItem("SelectedArea"));
            if (Array.isArray(city)) {
              city = (city[0]);
            } else {
              city = (city);
            }
          let CountryID = this.cities.find(c => c.id == city.cityId).countryId;
          console.log("selected countru", city);
          if (city != null) {
            let obj = this.countryData.find(c => c.id == CountryID);
            this.toSelect = obj;
            console.log("selected countrddu", obj);
          }
          else {
            let obj = this.countryData.find(c => c.name == "UNITED STATES");
            this.toSelect = obj;
            console.log("selected cousssntrddu", obj);
          }
          //this.toSelect = this.countryData.find(c => c.phoneCode == 1);

          this.loginForm.get('countryCode').setValue(this.toSelect);
        });
      }else{
        localStorage.setItem("countries", JSON.stringify(this.countryData));
        let city = JSON.parse(localStorage.getItem("SelectedArea"));
            if (Array.isArray(city)) {
              city = (city[0]);
            } else {
              city = (city);
            }
        let CountryID = this.cities.find(c => c.id == city.cityId).countryId;
        console.log (CountryID)
        console.log("selected countru", city);
        if (city != null) {
          let obj = this.countryData.find(c => c.id == CountryID);
          this.toSelect = obj;
          console.log("selected countrddu", obj);
        }
        else {
          let obj = this.countryData.find(c => c.name == "UNITED STATES");
          this.toSelect = obj;
          console.log("selected cousssntrddu", obj);
        }
        //this.toSelect = this.countryData.find(c => c.phoneCode == 1);

        this.loginForm.get('countryCode').setValue(this.toSelect)
      }
  }

  ngAfterViewInit() {
    this.matSelect.openedChange.subscribe((opened) => {
      if (opened && this.searchInput) {
        setTimeout(() => {
          this.searchInput.nativeElement.focus();
        });
      }
    });
  }

  get errorControl() {
    return this.loginForm.controls;
  }

  onKey(value) {
    console.log(value);
    value = value.toLowerCase()
    if (!value) {

      console.log('fulldayta');

      this.countryData = this.filteredOptions;
    }
    else {
      console.log('dayta');
      this.countryData = this.filteredOptions;
      this.countryData = this.search(value);
    }
  }



  search(value: any): Country[] {
    let filter = value.toLowerCase();
    return this.countryData.filter(country => {
      return (
        country.displayName
          .toLowerCase()
          .includes(value) ||
        country.phoneCode.toString()
          .toLowerCase()
          .includes(value)
      );
    });
  }

  onChange(deviceValue) {
    console.log(deviceValue);
  }
isShouldShowLogoImage(){


}
  submitLoginForm() {
    console.log('here login clicked')
    this.isSubmitted = true;

    if (!this.loginForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {

      // this.presentLoading();

      console.log(this.loginForm.value)


      let deviceId = "nirvana-customer";
      let deviceName = "nirvana-customer";
      // this.loginForm.value.countryCode + "-"
      console.log('form data', this.loginForm.value.countryCode.phoneCode, this.loginForm.value)
      let user = {
        'username': this.loginForm.value.countryCode.phoneCode + "-" + this.loginForm.value.uname,
        'password': this.loginForm.value.password,
        'rolesName': "POS Customer",
        'deviceId': deviceId,
        'deviceTypeId': '4',
        'deviceName': deviceName,
        'ipAddress': deviceId,
        'versionInfo': "v1.0.0"
      }

      this.userService.loginUser(user)
        .subscribe((res: any) => {
          console.log(res);
          // this.loading.dismiss();
          if (res.displayMessage)
            alert(res.displayMessage);
          else {
            // this.presentAlert('User Logged In Successfully')
            localStorage.setItem('currentUser', JSON.stringify(res));
            localStorage.setItem('isUserLogin', '1');
            localStorage.setItem('globalUserId', JSON.stringify(res.id));
            if (this.page == "home") {
              this.router.navigate(['home']);
            } else if ((this.page == "business")) {
              this.router.navigate(['business', JSON.parse(localStorage.getItem("SelectedArea")).id]);
            } else if ((this.page == "reservation")) {
              this.router.navigate(['reservation']);
            } else {
              this.router.navigate(['menu', this.businessId, this.page]);
            }
          }
        }, err => {
          console.log(err);
          //this.loading.dismiss();
          alert(err)
          this.loginInvalid = false;

        });

    }
  }

  async presentAlert(message) {

  }

  async presentLoading() {

  }

  ngOnDestroy() {   
    document.documentElement.style.removeProperty('--background-color');
    document.documentElement.style.removeProperty('--border-Radius');
    document.documentElement.style.removeProperty('--leaf');
  }

}
