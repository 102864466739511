<app-main-header [isHomePage]="isHomePage" [pageName]="'reservation'"  [showSearch]="0"
(searchText)="searchMenu($event)"
[isshowlogoimage]="'true'" [logoUrl]="
    (locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'"

></app-main-header>
<!-- <div class="mt-5"> -->
<!-- <div class="container pt-4"   *ngIf="!isLoadReservationForm ; else elseBlock"> -->

<!-- <div class="row" style="width: 100%"> -->

<!-- <div class="col-md-12 p-0"> -->
<!-- <section class="menu_list mt-60 mb-60"> -->
<br /><br />
<div class="container pt-4" *ngIf="!isLoadReservationForm ; else elseBlock">

  <div class="row">

    <div *ngIf="!hideBackButton" class="col-md-12 py-4" style="position: absolute; left:2%;padding:0 !important">
      <a (click)="cancel()" [routerLink]="['/business', cityId]" style="color:var(--background-color,red)"><mdb-icon style="font-size: 22px;" fas icon="arrow-left"></mdb-icon></a>
    </div>
  </div>
  <div class="row">
    <!-- <h3>{{pageProperty?.subTitle}}</h3> -->
    <div class="col-md-12 text-center ">
      <h5 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-size:30px;
  font-family: 'Poppins-SemiBold';
  font-style: normal;margin-bottom: 0px;">Reservation</h5>
    </div>
  </div>
  <div class="col-md-12 p-2 text-center ">
    <div class="row justify-content-center align-items-center">
      <div class="col-xs-6" style="text-align: right; margin-left: 5px;">
        <button mdbBtn style="padding: 10px;font-weight: bold;border-radius: var(--border-Radius);width: 100%;background-color: white;text-transform: none;"
          [ngClass]="currentTab=='reservation'?'activeTab':''" (click)="changeTab('reservation')">Reserve & Preselect</button>
      </div>
      <div class="col-xs-6" style="margin-left: 5px; margin-right: 5px;">
        <button mdbBtn style="padding: 10px;font-weight: bold;border-radius: var(--border-Radius);width: 100%;background-color: white;text-transform: none;"
          [ngClass]="currentTab=='reservationList'?'activeTab':''" (click)="changeTab('reservationList')">Reservation
          History</button>
      </div>
    </div>
  </div>
  

  <div *ngIf="currentTab=='reservation'">
    <div class="row p-2">
      <div class="col-md-6 offset-md-1 lib-item">
        <div class="lib-panel">
  
          <div class="row box-shadow">
  
            <!-- <div class="col-md-3" style="font-size: 12px;">
              <img class="lib-img-show rounded" style="width: 100px;height: 100px;border: 1px solid #e8ebe9"
              [src]="(locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'">
              <span style="color:#ff1123;">Real Reviews <span class="badge"
                  style="background-color: #ff1123;color: #fff;">{{ratings}}</span></span>
              <span style="font-size: 0.9em;"> {{numberOfRatings}} ratings</span>
            </div> -->
            <div class="col-md-9 p-0">
              <!-- <div class="lib-row lib-header">
                {{locationDetails.location.name}}
              </div> -->
              <div class="lib-row lib-desc">
                <p class="row">
                  <!-- <span class="col-xl-3 p-0" style="color:#ff1123;">Address:</span><span
                    class="col-xl-9 p-0">{{locationDetails.location.address.address1}},
                    {{locationDetails.location.address.city}},
                    {{locationDetails.location.address.state}},
                    {{locationDetails.location.address.zip}}</span>
                  <span class="col-xl-3 p-0" style="color: #ff1123;">Call Now:</span><span
                    class="col-xl-9 p-0">{{locationDetails.location.address.phone}}</span>
                  <span class="col-xl-3 p-0" style="color: #ff1123;">Hours:</span><span
                  class="col-xl-9 p-0"><span *ngFor="let time of businessTime">{{time}}<br/></span> -->
                      <ng-template *ngIf="businessId == 3176;else other">
                      <span>Online Reservation currently not available. Please call the restaurant at (702) 454-4240 in the meantime. Thank you</span>
                      </ng-template>
                      <ng-template #other>
                      <span *ngIf="businessTime.length==0">Reservations are currently not available! Please check again later.</span>
                      </ng-template>
                     <!-- </span> -->
                </p>
              </div>
  
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-12 text-left pb-2">
            <ul class="list-group">
              <li class="list-group-item selected_card_item"
                *ngFor="let sourcegroup of displaySourceGroup; let i = index">
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="defaultUnchecked" [value]="sourcegroup"
                    name="defaultExampleRadios" checked mdbInput>
                  <label class="custom-control-label" for="defaultUnchecked">{{sourcegroup.displayName}}</label>
                </div>
              </li>
  
            </ul>
          </div>
          <div class="col-md-1">&nbsp;</div>

        </div>
      </div> -->
    </div>
    <form [formGroup]="reserveForm" novalidate>
      <div class="row p-3 justify-content-center" style="height: fit-content;">
        <div class="col-md-0">
          <label></label>
          <div style="width: 55px;height:55px; background-color: black; color: white; font-size: 28px;
                padding: 8px 15px;
                border-radius: 5px;
                cursor: pointer;margin-top: 8px;" (click)="backDateClick($event)" type="submit">
            <mdb-icon fas icon="angle-double-left"></mdb-icon>
          </div>
        </div>
        <div class="col-md-4">
          <label>Date Of Reservation</label>
          <div class="input-group">
            <input class="form-control" formControlName="reservationDate" name="dp" ngbDatepicker #d="ngbDatepicker"
              (click)="d.toggle()" (dateSelect)="onDateSelect($event)" [minDate]="minDate"
              [value]="currentDateStrForHtml " style="margin: 0px;width: 199px;height:55px">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2" (click)="d.toggle()"
                style="background-color: black; color: white">
                <mdb-icon far icon="calendar-alt"></mdb-icon>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-0">
          <label></label>
          <div style="width: 55px;height:55px; background-color: black; color: white; font-size: 28px;
                padding: 8px 15px;
                border-radius: 5px;
                cursor: pointer;margin-top: 8px;" (click)="forwardDateClick($event)" type="submit">
            <mdb-icon fas icon="angle-double-right"></mdb-icon>
          </div>
        </div>
        <div class="col-md-4">
          <label>Party Size</label>
          <div class="input-group">
            <ng-select [items]="partySizeArray" class="custom form-control" bindLabel="name" bindValue="id"
              placeholder="# Select Party Size" formControlName="selPartySize" [(ngModel)]="selectPartySize" #partyselect
              required>
            </ng-select>
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2" (click)="partyselect.open()"
                style="background-color: black; color: white">
                <mdb-icon fas icon="users"></mdb-icon>
              </span>
            </div>
            <span *ngIf="reserveForm.get('selPartySize').hasError('required') && errorForm"
              style="font-size: 15px;color: #ff1123;">Please Select Party Size</span>
          </div>
        </div>
      </div>
  
    </form>
    <div class="text-center mt-2">
      <h3>Available Reservation Slots</h3>
    </div>
    
    <div class="row mb-3" *ngIf="slots&&slots.length>0 && showReservation.length>0 ; else elseSlotBlock">

      <a *ngFor="let slot of slots; let i = index">

        <div class="col-md-1 p-2">

          <ul class="list-group list-group-horizontal" (click)="onSlotHold(slot)"
            style="width: 200px;height:49px;padding-left: 0px; border-width: 2px;font-size: 12px;">
            <li class="list-group-item p-2" style="width: 110px;">
              <div  style="padding: 4px;">
                <label>{{ slot.time |convertFrom24To12Format}}
                </label>


              </div>
            </li>
            <li class="list-group-item p-2" style="background-color: var(--background-color,red); width: 110px;color:#ffffff;white-space: nowrap;">
              <div>
                <label *ngIf="slot.slotRemaining==1;else elseBlck">
                  {{slot.slotRemaining}} slot left
                </label>
                <ng-template #elseBlck> <label>{{slot.slotRemaining}} slots left
                  </label></ng-template>


              </div>
            </li>
          </ul>

        </div>
      </a>
    </div>
    <ng-template #elseSlotBlock>
      <div class="row justify-content-center mb-3" style="width: 100%" *ngIf="showReservation.length>0">
        <h5> No slots available</h5>
      </div>
      <div class="row justify-content-center mb-3" style="width: 100%" *ngIf="showReservation.length==0">
          <h5 *ngIf="locationDetails.location.businessId == 3176;else other" style="color:#ff1123">Online Reservation currently not available. Please call the restaurant at (702) 454-4240 in the meantime. Thank you</h5>
        <ng-template #other>
        <h5 style="color:#ff1123">Reservations are currently not available! Please check again later.</h5>
        </ng-template>
      </div>
    </ng-template>

  </div>
  <div *ngIf="currentTab=='reservationList'">
    <div class="col-md-12 p-0 text-center " id="resultsTable">
      <table class="table table-striped table-responsive-md btn-table rounded">
        <thead class="black white-text">
          <tr>
            <th colspan="2">Name</th>
            <th>Date</th>
            <th>Time</th>
            <th>#</th>
            <th>Special Request</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngIf="reservationList.length == 0">
            <td colspan="8" style="color: #000000;font-size: 18px;font-weight: bold;">No reservations available</td>
          </tr>
          <tr *ngFor="let reservation of reservationList | filter:searchText">
            <td scope="row" [ngStyle]="{'background-color':reservation.reservationsStatus.hexCodeValues}">
            </td>
            <td scope="row">
              {{reservation.firstName+' '+reservation.lastName}}
            </td>
            <td scope="row">{{reservation.date+' '+reservation.time | date:'d-MMM-y' }}</td>
            <td scope="row">{{reservation.date+' '+reservation.time | date:'h:mm a' }}</td>
            <td scope="row">{{reservation.partySize}}</td>
            <td scope="row">{{reservation.requestType.displayName}}</td>
            <td scope="row">
              <button type="button" [disabled]="isValidReservation(reservation)" class="btn" (click)="orderNow(reservation)"
                style="color: white;" *ngIf="!reservation.orderId">Preselect Order</button>
              <button type="button" [disabled]="isValidReservation(reservation)" class="btn"
                (click)="editOrder(reservation)" style="color: white;" *ngIf="reservation.orderId">Edit
                Order</button>
                <button type="button" [disabled]="isValidReservation(reservation)" class="btn" (click)="cancel_reservation(reservation)"
                style="color: white;" >Cancel</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- </div> -->
<!-- </section> -->
<!-- </div> -->
<!-- </div> -->

<ng-template #elseBlock>
  <div class="container pt-4" style="text-align: center;">
    <div class="row">
      <div class="col-md-12" style="position: absolute; left:-48%;padding:0 !important">
        <a style="color:var(--background-color,red)" (click)="toggleView()"><mdb-icon style="font-size: 22px;" fas icon="arrow-left"></mdb-icon></a>
      </div>
    </div>

    <!-- <h5>{{ pageProperty?.subTitle }}</h5> -->
    <h5 style="
    text-align: center;
    color: #000000;
    width: 100%;
    font-size:30px;
    font-family: 'Poppins-SemiBold';
    font-style: normal;margin-bottom: 10px; margin-top: 5px;">
      Complete your reservation at <a style="color:#ff1123 ;
      text-align: center;
      width: 100%;
      font-size:30px;
      font-family: 'Poppins-SemiBold';
      font-style: normal;">{{locationDetails.location.name}}</a>
    </h5>
    <p style="
    text-align: center;
    color: #767676;
    width: 100%;
    font-family: 'Poppins-Regular';
    font-size:18px; margin-bottom: 5px;">{{reservationDateHtml|date:'fullDate'}} at {{slot?.hrTime}} for
      {{slot?.partySize}} Guest(s) <br>
      This reservation held for <span style="color:#ff1123"> {{timer}}</span></p>

    <mat-card style="
      width: 300px;
      margin: 2em auto;
      margin-top: 0px;
      text-align: center;
      box-shadow: none !important;
      max-height:fit-content;
      font-family: 'Poppins-Regular';
    ">
      <mat-card-content style="margin-top: 0px;margin-bottom: 0px;">
        <form [formGroup]="reservationForm" (ngSubmit)="onsubmit()" class="form-horizontal">
          <div class="form-group">
            <div class="row" style="width: 300px;">
              <div class="col-md-6 coldesign">
                <mat-form-field>
                  <input color="primary" matInput style="height: 20px;" placeholder="First Name"
                    formControlName="firstName" required />
                  <mat-error>
                    Please provide a valid data
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-6 coldesign">
                <mat-form-field>
                  <input color="primary" matInput style="height: 20px;" placeholder="Last Name"
                    formControlName="lastName" />
                  <!-- <mat-error>
                    Please provide a valid data
                  </mat-error> -->
                </mat-form-field>
              </div>
            </div>

            <div class="row" style="width: 300px;">
              <div class="col-md-3 coldesign">
                <mat-form-field class="example-full-width text-dark text-center">
                  <mat-select style="height: 19px;" formControlName="countryCode" panelClass="matpanelClassUniveral"
                    disableOptionCentering>
                    <input class="text-center searchbox" placeholder="Search Here" (keyup)="onKey($event.target.value)">
                    <!-- <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
                      <mat-icon>close</mat-icon>
                    </button> -->
                    <mat-select-trigger>+{{reservationForm.controls.countryCode.value.phoneCode}}</mat-select-trigger>

                    <ng-container *ngFor="let country of countryData">
                      <!-- <mat-option    *ngIf ="country.shortName && country.phoneCode!=0" [value]="country.phoneCode"> +{{country?.phoneCode}}({{country.shortName|lowercase}})</mat-option> -->
                      <mat-option *ngIf="country.phoneCode!=0" [value]="country">
                        {{country?.displayName }}-({{country?.phoneCode }})</mat-option>

                    </ng-container>
                  </mat-select>


                </mat-form-field>
              </div>

              <div class="col-md-9 coldesign">
                <mat-form-field>
                  <input color="primary" matInput style="height: 20px;" type="tel" placeholder="  Mobile Number"
                    formControlName="phoneNumber" required />
                  <mat-error>
                    Please provide a valid mobile number
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row" style="width: 300px;">
              <div class="col-md-12 coldesign">
                <mat-form-field>
                  <input color="primary" matInput style="height: 20px;" type="email" placeholder="Email address"
                    formControlName="email" required />
                  <mat-error>
                    Please enter valid email address
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row" style="width: 300px;">
              <div class="col-md-12 coldesign">
                <mat-form-field class="example-full-width text-dark">
                  <mat-label>Special Request</mat-label>
                  <mat-select style="height: 20px;" class="" aria-placeholder="Special Request"
                    formControlName="specialRequest" placeHolder="Special Request">
                    <mat-option *ngFor="let request of requestTypeArray" [value]="request.id">
                      {{ request?.displayName }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row" style="width: 300px;">
              <div class="col-md-12 coldesign">
                <mat-form-field class="example-full-width text-dark">
                  <mat-label>Contact Preference</mat-label>
                  <mat-select style="height: 20px;" class="" formControlName="contactPreference"
                    placeHolder="Contact Preference">
                    <mat-option *ngFor="let request of conatctPrefArray" [value]="request.id">
                      {{ request?.displayName }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row" style="width: 300px;">
              <div class="col-md-12 coldesign">
                <mat-form-field class="example-full-width text-dark">
                  <textarea matInput #postalCode maxlength="256" rows="3" placeholder="Comments"
                    formControlName="userComment"></textarea>
                  <mat-hint align="end">You have {{ 256 - postalCode.value.length }} characters
                    remaining</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row" style="width: 300px;margin-left: -27px;">
            <div class="col-md-5 coldesign">
              <button class="button" type="submit">Reserve</button>
            </div>
            <div class="col-md-2 coldesign"></div>
            <div class="col-md-5 coldesign">
              <button class="button" type="reset" (click)="cancel()">Cancel
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
<!-- </div> -->
<!-- <app-reservation-list></app-reservation-list> -->
<!-- <app-common-footer></app-common-footer> -->