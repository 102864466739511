export class Area {
image: string;
name: string;
created: Date;
createdBy: number;
id: number;
isOnline: 1
cityId: number;
status: string;
updated: Date;
updatedBy: number;
}
