export class PageProperty {



id: number;
name: string;
subTitle:string;
created: Date;
createdBy: number;
appLogo: string;
logo: string;
instagramUrl: string;
twitterUrl: string;
facebookUrl: string;
description: string;
updated: Date;
updatedBy: number;
status: string;
propertyToBusinesses: string;
convenienceName: string;
}
