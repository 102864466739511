import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../core/services/user.service';
import { PageProperty, LocationService, City, Country } from '../core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MustMatch } from './must-match';
import { Title, Meta } from '@angular/platform-browser';
import { appServerConstants } from '../app-url-constants';

import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-loginasguest',
  templateUrl: './loginasguest.component.html',
  styleUrls: ['./loginasguest.component.css']
})

export class LoginAsGuestComponent implements OnInit {

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @ViewChild(MatSelect, {static:false}) matSelect: MatSelect;
  userForEdit: any = {};
  selectedCountry: any = {};
  pageProperty: PageProperty;
  isHomePage: boolean = false;
  toSelect: any;
  account_title: string;
  isSubmitted: boolean;
  cities: City[];
  compareWith: any;
  defaultCountry: any;
  loading: any;
  searchValue: string;
  page: string;
  countryData: Country[];
  filteredOptions: Country[];

  matcher = new MustMatch();
  businessId: any;
  locationDetails:any;
  countItem = 0;

  signupForm: FormGroup;
  otpForm: FormGroup;
  userForm: FormGroup;
  globalUserId: any;
  showForgotScreen: boolean = true;
  showOtpScreen: boolean = false;
  showUserScreen: boolean = false;
  countdown: number = 60;
  countdownInterval:any;
  isMint: boolean = false;
  countdownStarted: boolean = false;


  constructor(private router: Router, private route: ActivatedRoute,
    public formBuilder: FormBuilder, private userService: UserService, private _snackBar: MatSnackBar, 
    private titleService: Title,private locationService: LocationService) { 

      this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));

    }

  ngOnInit() {

    this.signupForm = this.formBuilder.group({
      countryCode: ['', [Validators.required, Validators.minLength(2)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]+$')]],
      uname: ['', [Validators.required, Validators.minLength(2)]],

    });

    this.cities = JSON.parse(localStorage.getItem("cities"));
    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
 // this.titleService.setTitle(this.pageProperty.name);

    this.countryData = JSON.parse(localStorage.getItem("countries"));
    this.filteredOptions = this.countryData;
    this.page = this.route.snapshot.params.page;
    this.businessId = localStorage.getItem("business_id");

    if ([105, 1622, 3176].includes(Number(this.businessId))) {
      this.isMint = true;console.log("Changing to green....");
    }

    const backgroundColor = this.isMint ? '#95ca4c' : '#fc0d20';
    const borderRadius = this.isMint ? '2rem 0' : '0.35rem';
    const leaf = this.isMint ? 'url("/assets/images/Mint_Leaf_image.png") center/contain no-repeat' : '';

    document.documentElement.style.setProperty('--background-color', backgroundColor);
    document.documentElement.style.setProperty('--border-Radius', borderRadius);
    document.documentElement.style.setProperty('--leaf', leaf);

    console.log(localStorage.getItem("cartItems"));
    if (localStorage.getItem("cartItems") && localStorage.getItem("cartItems") != undefined) {
      var cartItems = JSON.parse(localStorage.getItem("cartItems"));
      var countItem = 0;
      for (var i = 0; i < cartItems.items.length; i++) {
        countItem = countItem + cartItems.items[i].quantity;
      }

      this.countItem = countItem;
    }

    if (!this.countryData || this.countryData.length == 0){
      this.locationService.getAllCountries()
        .subscribe((response: any) => {
          this.countryData = response;
          this.filteredOptions = response;
          console.log(response);
          localStorage.setItem("countries", JSON.stringify(this.countryData));
          let city = JSON.parse(localStorage.getItem("SelectedArea"));
            if (Array.isArray(city)) {
              city = (city[0]);
            } else {
              city = (city);
            }
          
          if (city != null) {
          let CountryID = this.cities.find(c => c.id == city.cityId).countryId;
          console.log("selected countru", city);

            let obj = this.countryData.find(c => c.id == CountryID);
            this.toSelect = obj;
            console.log("selected countrddu", obj);
          }
          else {
            let obj = this.countryData.find(c => c.name == "UNITED STATES");
            this.toSelect = obj;
            console.log("selected cousssntrddu", obj);
          }
          //this.toSelect = this.countryData.find(c => c.phoneCode == 1);

          this.signupForm.get('countryCode').setValue(this.toSelect);
        });
      }else{
        localStorage.setItem("countries", JSON.stringify(this.countryData));
        let city = JSON.parse(localStorage.getItem("SelectedArea"));
            if (Array.isArray(city)) {
              city = (city[0]);
            } else {
              city = (city);
            }
        if (city != null) {
        let CountryID = this.cities.find(c => c.id == city.cityId).countryId;
        console.log (CountryID)
        
        console.log("selected countru", city);
        
          let obj = this.countryData.find(c => c.id == CountryID);
          this.toSelect = obj;
          console.log("selected countrddu", obj);
        }
        else {
          let obj = this.countryData.find(c => c.name == "UNITED STATES");
          this.toSelect = obj;
          console.log("selected cousssntrddu", obj);
        }
        //this.toSelect = this.countryData.find(c => c.phoneCode == 1);

        this.signupForm.get('countryCode').setValue(this.toSelect)
      }

    // this.signupForm.get('countryCode').setValue(this.toSelect);

    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required]]
    });

    this.userForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    },
      {
        validator: this.checkPasswords
      })
    // ,Validators.pattern('(?=.*[!@#$%^&*])')
  }

  ngAfterViewInit() {
    this.matSelect.openedChange.subscribe((opened) => {
      if (opened && this.searchInput) {
        setTimeout(() => {
          this.searchInput.nativeElement.focus();
        });
      }
    });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }

  }
  onKey(value) {
    console.log(value);
    value = value.toLowerCase()
    if (!value) {

      console.log('fulldayta');

      this.countryData = this.filteredOptions;
    }
    else {
      console.log('dayta');
      this.countryData = this.filteredOptions;
      this.countryData = this.search(value);
    }
  }

  search(value: any): Country[] {
    let filter = value.toLowerCase();
    return this.countryData.filter(country => {
      return (
        country.displayName
          .toLowerCase()
          .includes(value) ||
        country.phoneCode.toString()
          .toLowerCase()
          .includes(value)
      );
    });

  }
  submitSignUpForm() {
    this.isSubmitted = true;

    if (!this.signupForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {
      console.log(this.signupForm.value);
      let locationId = 0;
      if (localStorage.getItem("locationDetails") && localStorage.getItem("locationDetails") != null)
        locationId = JSON.parse(localStorage.getItem("locationDetails")).location.id;
      else
        locationId = 1;

        let propertyId = appServerConstants.PROPERTY_ID;
      let phone = this.signupForm.value.countryCode.phoneCode + "-" + this.signupForm.value.phoneNumber;
      this.userService.sendSMSVerificationCode(phone,propertyId)
        .subscribe((res: any) => {

          console.log(res);
          if (res == null || res.displayMessage) {
            this._snackBar.open(res.displayMessage, '', {
              duration: 2000,
            });
            return;
          }
          this._snackBar.open("One Time Password Sent.", '', {
            duration: 2000,
          });
          this.countdown = 60;
          this.showOtpScreen = true;
          this.showForgotScreen = false;
          if (!this.countdownStarted) {
            this.startCountdown();
            this.countdownStarted = true; // Set flag to true after starting countdown
          }
        }, err => {
          console.log(err);
          this._snackBar.open("One Time Password not Sent.", '', {
            duration: 2000,
          });
        });

    }
  }

  startCountdown() {
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.showOtpScreen = false;
        this.showForgotScreen = true;
        clearInterval(this.countdownInterval);
        this.showOtpExpiredMessage();
        this.countdown = 60;
      }
    }, 1000);
  }

  showOtpExpiredMessage() {
    // Display the "One Time Password Expired" message here
    this._snackBar.open("One Time Password Expired.", '', {
      duration: 2000,
    });
  }

  formatCountdownTime(): string {
    const minutes: number = Math.floor(this.countdown / 60);
    const seconds: number = this.countdown % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  submitOtpForm() {
    if (!this.otpForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {
      console.log(this.otpForm.value);


      let otp = this.otpForm.value.otp;
      this.userService.validateUserAuthCode(otp)
        .subscribe((res: any) => {

          console.log(res);
          if (res == null || res.message) {
            this._snackBar.open(res.message, '', {
              duration: 2000,
            });
            return;
          }
          // this._snackBar.open("Validate OTP.", '', {
          //   duration: 2000,
          // });
          this.globalUserId = res.userId;
          console.log('Clearing countdown interval...');
         clearInterval(this.countdownInterval);
          this.submitUserForm();
        }, err => {
          console.log(err);
          this._snackBar.open("Invalid OTP.", '', {
            duration: 2000,
          });
          clearInterval(this.countdownInterval);
          this.countdown = 60;
        });

    }
  }

  submitUserForm() {

    if (!this.otpForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {
      console.log(this.otpForm.value);


      let otp = this.otpForm.value.otp;
      let GlobalUserAuthPacket = {
        "echoString": localStorage.getItem('deviceId') + " user-update-1",
        "merchantId": localStorage.getItem("account_id"),
        "locationId": 1,
        "authCode": otp,
        "password": this.userForm.value.password,
        "userId": this.globalUserId
      };

      var user = {
        'authPin': '',
        'createdBy': '1',
        'updatedBy': '1',
        'status': 'A',
        'globalUsersId': '0',
        'firstName': this.signupForm.value.uname,
        'lastName': " ",
        // this.selectedCountry.phoneCode + "-" +
        'username': this.signupForm.value.countryCode.phoneCode + "-" + this.signupForm.value.phoneNumber,
        // 'email': this.signupForm.value.email,
        'phone': this.signupForm.value.countryCode.phoneCode + "-" + this.signupForm.value.phoneNumber,
        // 'password': this.signupForm.value.password,
        'countryId': this.signupForm.value.countryCode.id
      };

      var address = [];
      var post_data = {
        "echoString": localStorage.getItem('deviceId') + " user-add-1",
        "merchantId": localStorage.getItem("account_id"),
        "locationId": localStorage.getItem("nirvanaxpLocationId"),
        "clientId": localStorage.getItem('deviceId'),
        "localReferenceNumber": localStorage.getItem('localReferenceNumber'),
        "status": "A",
        "user": user,
        'addressList': address
      };

      var user_data = {
        "UserPostPacket": post_data
      };
console.log(user_data);
      this.userService.addNirvanaXPUserForWebsiteByPhone(user_data)
        .subscribe((res: any) => {

          console.log(res);
          if (res == null || res.displayMessage) {
            this._snackBar.open(res.message, '', {
              duration: 2000,
            });
            return;
          }
         
          localStorage.setItem('currentUser', JSON.stringify(res.nirvanaXpUser));
          localStorage.setItem('isUserLogin', '1');
          localStorage.setItem('globalUserId', JSON.stringify(res.nirvanaXpUser.globalUsersId));
          if (this.page == "home") {
            this.router.navigate(['home']);
          } else if ((this.page == "business")) {
            this.router.navigate(['business', JSON.parse(localStorage.getItem("SelectedArea")).id]);
          } else if ((this.page == "reservation")) {
            this.router.navigate(['reservation']);
          } else {
            this.router.navigate(['menu', this.businessId, this.page]);
          }
        }, err => {
          console.log(err);
          this._snackBar.open("User create Failed.", '', {
            duration: 2000,
          });
        });

    }
  }

  ngOnDestroy() {
    clearInterval(this.countdownInterval);    
    document.documentElement.style.removeProperty('--background-color');
    document.documentElement.style.removeProperty('--border-Radius');
    document.documentElement.style.removeProperty('--leaf');
  }

}
