<app-main-header [isHomePage]="isHomePage" [pageName]="'business'" [showSearch]="1"
    (selectCity)="selectCurrentCity($event)" (searchText)="searchBiz($event)"></app-main-header>

<!--Main Layout-->
<div style="text-align:center;">
    <div class="container pt-4">

        <div class="row  mt-5">
            <div class="col">
                <h3 >
                    {{pageProperty?.subTitle}}
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button type="button" class="btn btn-rounded margin-10" *ngFor="let cus of cusines; let i = index"
                    [ngClass]="selectedCusineId==cus.id? 'mybtn-danger':'mybtn-dark'"
                    (click)="selectCusine(cus.id)">{{cus.displayName}}</button>
            </div>
        </div>

        <div class="row justify-content-md-center">
            <div class="col-lg-8">
                <!-- <angular-image-slider
            [images]="imagesUrl"
            [imageText]=""
            ></angular-image-slider> -->
                <div class="horizontal-scroller" style="top: 0px;">
                    <div class="scroller-left"> <i class="fas fa-angle-left" style="color:black;font-size: 25px;"></i>
                    </div>
                    <div class="scroller-right"><i class="fas fa-angle-right" style="color:black;font-size: 25px;"></i>
                    </div>
                    <div class="category-scrollbar">
                        <div class="icon-bar slider2" data-slick='{"slidesToShow": 4, "slidesToScroll": 1}'>
                            <a (click)="selectItemType(card.id)" *ngFor="let card of cards; let cardIndex = index">
                                <mdb-card class="my-1"
                                    [ngClass]="selectedItemTypeId==card.id? 'background_sel_col':'background_col'">
                                    <mdb-card-img src="./assets/images/burgers-white.png" alt="Card image cap"
                                        style="width: 60px;margin: 0px auto;"></mdb-card-img>
                                    <mdb-card-text class="my-card-text">{{card.displayName}}
                                    </mdb-card-text>
                                </mdb-card>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- <mdb-carousel class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'"
                [animation]="'slide'" [interval]="'0'" [isControls]="true"  >
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-2"  *ngFor="let card of item; let cardIndex = index"
                    style="cursor: pointer;"
                    (click)="selectItemType(card.id)">
                        <mdb-card class="my-1" [ngClass]="selectedItemTypeId==card.id? 'background_sel_col':'background_col'">
                            <mdb-card-img src="./assets/images/burgers-white.png" alt="Card image cap" style="width: 60px;margin: 0px auto;"></mdb-card-img>
                            <mdb-card-text class="my-card-text">{{card.displayName}}
                              </mdb-card-text>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </mdb-carousel> -->
            </div>
        </div>
    </div>

    <div>
        <div class="container-fluid mt-4 biz-div">
            <div class="row">
                <div class="col-lg-6 mb-4" *ngFor="let biz of business  |filter:searchText; let i = index">
                    <div class="card" style="min-height: 296px;">
                        <div class="card-horizontal">
                            <div class="card-label img-square-wrapper" style="padding: 1.25em;">
                                <img style="width: 140px; height: 140px;"
                                [src]="(biz.logo)?'https://images.nirvanaxp.com/images/location_images/250_250/'+biz.logo:'./assets/image/default-680x600.jpg'"
                                        alt="Card image cap">
                                <!-- <div class="row">
                                    <div class="col-md-12 p-0">
                                        <span style="color: #ff1123;">Real Reviews <br /><span class="badge"
                                                style="background-color: #ff1123;color: #fff;">{{biz.ratings| number:'1.2-2'}}</span></span>
                                        <span style="font-size: 0.9em;"> {{biz.numberOfRatings}} ratings</span></div>
                                </div> -->
                            </div>
                            <div class="card-body" style="padding-bottom: 5px;">
                                <div class="row">
                                    <div class="col-md-12 p-0 text-left">
                                        <h4 class="card-title float-left" >{{biz.name}}</h4>
                                    </div>

                                </div>
                                <div class="card-text"
                                    style="clear: both;text-align: left; color: #6e6e75; margin-bottom: 5px;">
                                    <div class="row">
                                        <div class="col-md-3 p-0"><span class="card-label">Cuisines: </span></div>
                                        <div class="col-md-9 p-0">{{biz.cuisinesName}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 p-0"><span class="card-label">Address: </span></div>
                                        <div class="col-md-9 p-0">{{biz.addressText}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 p-0"><span class="card-label">Call Now: </span></div>
                                        <div class="col-md-9 p-0">{{biz.phone}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 p-0"><span class="card-label">Hours: </span></div>
                                        <div class="col-md-9 p-0" ><span *ngFor="let time of biz.businessTime">{{time}}<br/></span>
                                            <span *ngIf="biz.businessTime.length==0">Online Ordering is currently not available! Please check again later.</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                             <button type="button" *ngIf="biz.menu == 1"  class="btn mybtn-danger btn-rounded"
                                style="width: 185px;font-size: 14px;padding: 5px 10px;"
                                (click)="getMenuPageForInstore(biz)" >
                                <img src="./assets/images/contactless-menu-icon.png" alt="Reserve & Pre-Order"
                                    height="38" width="38" class="float-left">
                                <div class="align-middle" style="
                                height: 42px;
                                display: table-cell;
                                text-align: center;
                                width: 150px;">{{biz.menuDisplayName}}</div></button> 
                            <button *ngIf="biz.pickupDelivery == 1"   type="button" class="btn mybtn-danger btn-rounded"
                                style="width: 185px;font-size: 14px;padding: 5px 10px;" (click)="getMenuPageForPickupDelivery(biz)"
                                >
                                <img src="./assets/images/contactless-pickup-delivery-icon.png"
                                    alt="Self Pickup" height="38" width="38" class="float-left">
                                    <div class="align-middle" style="
                                height: 42px;
                                display: table-cell;
                                text-align: center;
                                width: 150px;">{{biz.pickupDeliveryDisplayName}}</div></button>
                             <button *ngIf="biz.reservation == 1"  type="button" class="btn mybtn-danger btn-rounded"
                                style="width: 185px;font-size: 14px;padding: 5px 10px;" (click)="getReservation(biz)"
                                >
                                <img src="./assets/images/reserve-pre-order-icon.png" alt="Reserve & Pre-Order"
                                    height="38" width="38" class="float-left">
                                    <div class="align-middle" style="
                                height: 42px;
                                display: table-cell;
                                text-align: center;
                                width: 150px;">{{biz.reservationDisplayName}}</div></button> 

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-common-footer [pageProperty]="pageProperty" [areas]="areas"></app-common-footer> -->