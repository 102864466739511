<app-main-header [isHomePage]="isHomePage" [showSearch]="1" [pageName]="screenMode" (searchText)="searchMenu($event)"
[isshowlogoimage]="'true'" [logoUrl]="
(locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'"

>
</app-main-header>

<div class="container">
    <div class="row">
        <div class="col-md-12" style="left: -7%;margin-top: 80px;">
            <a href="javascript:history.back();" style="color:var(--background-color,red)"><mdb-icon style="font-size: 22px;" fas icon="arrow-left"></mdb-icon></a>
        </div>
        <div class="col-12 justify-content-center align-items-center text-center p-4">
            <h3>Track Orders</h3>
        </div>
        <div class="col-12">
            <ngb-accordion #acc="ngbAccordion" activeIds="" closeOthers="true">
                <ngb-panel *ngFor="let order of currentOrders | filter:searchText">
                    <ng-template ngbPanelTitle>
                        <span (click)="getOrderStatus(order.orderHeader.id)"><b>Order
                                #{{order.orderHeader.orderNumber}}</b>
                        </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div *ngIf= "trackingUrl" style="font-weight: bold;color: black;font-size: 18px;"><br>
                            <span> Track your order by <a style="text-decoration: underline;" href="{{trackingUrl}}" target="_blank">clicking here</a></span>
                        </div>
                        <div class="row bs-wizard" style="border-bottom:0;">
                            <div *ngFor="let statusArray of statusArray[order.orderHeader.id]; let i = index"
                                [ngClass]="'col bs-wizard-step '+statusArray.class">
                                <div class="text-center bs-wizard-stepnum">&nbsp;</div>
                                <div class="progress">
                                    <div class="progress-bar"></div>
                                </div>
                                <a href="#" class="bs-wizard-dot" (click)="$event.preventDefault()">{{i+1}}</a>
                                <div class="bs-wizard-info text-center">{{statusArray.displayName}}
                                    <br/><span *ngIf="statusArray.date">{{statusArray.date}}</span></div>
                            </div>
                            
                        </div>
                        <div  style="font-weight: bold;color: black;font-size: 18px;"><br>
                            <span *ngIf="driverName" >Driver Name: {{driverName}}</span><br>
                            <a *ngIf="driverPhone" style="color:#000000">Phone No:{{driverPhone}}</a>
                          </div>

                       
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
</div>