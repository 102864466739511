import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotfoundComponent } from './shared/notfound/notfound.component';
import {PathResolveService } from './path-resolve.service';
import { BusinessSignupModule } from './signup/business-signup/business-signup.module';

const routes: Routes = [
  {
    path: '',
    resolve: {
      path: PathResolveService
    },
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'home',
    resolve: {
      path: PathResolveService
    },
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'business/:id',
    loadChildren: () => import('./business/business.module').then(m => m.BusinessModule)
  },

  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule)
  },

  {
    path: 'menu/:id/:mode',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule)

  },
  {
    path: 'signup/:id/:page',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule)
  },

  {
    path: 'login/:page',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  }, {
    path: 'loginasguest/:page',
    loadChildren: () => import('./loginasguest/loginasguest.module').then(m => m.LoginAsGuestModule)
  },
  {
    path: 'reservation',
    loadChildren: () => import('./reservation/reservation.module').then(m => m.ReservationModule)
  },
  {
    path: 'track-order',
    loadChildren: () => import('./track-order/track-order.module').then(m => m.TrackOrderModule)
  },
  {
    path: 'forgot-password/:page',
    loadChildren: () => import('./forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule)
  }, 
  {
    path: 'order-history',
    loadChildren: () => import('./order-history/order-history.module').then(m => m.OrderHistoryModule)
  }, 
  {
    path: 'reservation-list',
    loadChildren: () => import('./reservation-list/reservation-list.module').then(m => m.ReservationListModule)
  },
  {
    path: 'bizsignup',
    loadChildren: () => import('./signup/business-signup/business-signup.module').then(m => m.BusinessSignupModule)

  }
  ,{
    path: '**',
    resolve: {
      path: PathResolveService
    },
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preload all modules; optionally we could
    // implement a custom preloading strategy for just some
    // of the modules (PRs welcome 😉)
    preloadingStrategy: PreloadAllModules,
    useHash: true
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
