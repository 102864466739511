export class Country {

  id: number;
  name: string;

  displayName: string;

  shortName: string;

  phoneCode: number;
}
