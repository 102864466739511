import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vegan';
  path: string;
  constructor(
    @Inject(PLATFORM_ID) private platformId:object,
    private router: Router, private route: ActivatedRoute) {
      console.log(this.router.url);
    }

  onActivate() {
    console.log(window.location.href);
    {
      if (isPlatformBrowser(this.platformId)) {
        this.router.events.subscribe((event: NavigationEnd) => {
          window.scroll(0, 0);
        });
      }
    }
  }
}
