import { HttpServiceProvider } from './http.service';
import { Injectable } from '@angular/core';

import { City } from '../models/city.model';
import { ItemsType } from '../models/itemsType.model';
import { CusineType } from '../models/cusineType.model';
import { Business } from '../models/business.model';
import { PageProperty } from '../models/pageProperty.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { Country } from '../models/country.model';

@Injectable()
export class LocationService {
  constructor(private httpServiceProvider: HttpServiceProvider) { }

  getCityByPropertyId(propertyId) {
    return this.httpServiceProvider.get<City[]>('/GlobalBusinessService', '/CustomerService/getCityByPropertyId/'+propertyId,1);
  }
  getAreaByCityId(cityId) {
    return this.httpServiceProvider.get<City[]>('/GlobalBusinessService', '/CustomerService/getAreaByCityId/'+cityId,1);
  }
  getUrlParam(name) {
    return this.httpServiceProvider.get<City[]>('/GlobalBusinessService', '/CustomerService/getUrlParam/'+name,1);
  }
  getBusiness(businessId) {
    return this.httpServiceProvider.get<City[]>('/GlobalBusinessService', '/CustomerService/getBusiness/'+businessId,1);
  }
  getAllGlobalItemsType(propertyId) {
    return this.httpServiceProvider.get<ItemsType[]>('/GlobalBusinessService', '/CustomerService/getAllGlobalItemsTypeByPropertyId/'+propertyId,1);
  }

  getAllGlobalCusineType(propertyId) {
    return this.httpServiceProvider.get<CusineType[]>('/GlobalBusinessService', '/CustomerService/getAllGlobalCusineTypeByPropertyId/'+propertyId,1);
  }

  getAllBusinessByCityIdItemsTypeIdCuisineTypeId(cityId: number, itemTypeId: number, cusineTypeId: number, propertyId: number) {
    return this.httpServiceProvider.get<Business[]>('/GlobalBusinessService', '/CustomerService/getAllBusinessByCityIdItemsTypeIdCuisineTypeId/' + cityId + "/" + itemTypeId + "/" + cusineTypeId + "/" + propertyId,1);
  }
  getAllBusinessByAreaIdItemsTypeIdCuisineTypeId(areaId: number, itemTypeId: number, cusineTypeId: number, propertyId: number) {
    return this.httpServiceProvider.get<Business[]>('/GlobalBusinessService', '/CustomerService/getAllBusinessByAreaIdItemsTypeIdCuisineTypeId/' + areaId + "/" + itemTypeId + "/" + cusineTypeId + "/" + propertyId,1);
  }

  getReferenceNumberByAccountIdAndBusinessIdForWebsite(acountId: number, businessId: number) {

    return this.httpServiceProvider.get<Business[]>('/PartnerManagementService', '/CustomerService/getReferenceNumberByAccountIdAndBusinessIdForWebsite/' + acountId + "/" + businessId,1);

  }

  getAllOnPageProperty(propertyId) {
    return this.httpServiceProvider.get<PageProperty>('/GlobalBusinessService', '/CustomerService/getPropertyById/'+propertyId,1);
  }

  getAllCountries() {
    return this.httpServiceProvider.get<Country[]>('/LookupService', '/CustomerService/getAllCountries',1);
  }

  getLocationsByBusinessId(businessId) {
    return this.httpServiceProvider.get('/LocationsService', '/CustomerService/getLocationsByBusinessId/' + businessId,0);
  }

  getTimezoneById(timezoneId) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/getTimezoneById/' + timezoneId,0);
  }

  getBusinessHoursByLocationId(locationId) {
    return this.httpServiceProvider.get('/LocationsService', '/CustomerService/getBusinessHoursByLocationId/' + locationId,0);
  }
  getShiftSlotsByOrderSourceId(orderSourceId, date, fromTime) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/getShiftSlotsByOrderSourceId/' + orderSourceId + "/" + date + "/" + fromTime,0);
  }

  holdShiftSlotForSlotId(slotPost) {
    return this.httpServiceProvider.post('/LookupService', '/CustomerService/holdShiftSlotForSlotId', slotPost,0);
  }

  unHoldShiftSlotForClientId(holdSlotId) {
    return this.httpServiceProvider.get('/LookupService', '/CustomerService/unHoldShiftSlotForClientId/' + holdSlotId,0);
  }
}
