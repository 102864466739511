<app-main-header [isHomePage]="isHomePage" [countItem]="countItem" [pageName]="screenMode"
    (searchText)="searchMenu($event)"  [isshowlogoimage]="'true'" [ratings]=ratings [numberOfRatings]=numberOfRatings 
    [currentTimeDisplay]=currentTimeDisplay [time]=time [logoUrl]="
    (locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'">
</app-main-header>


<div Class="" style="width: 98%;max-width: 100%;">
    <div class="row p-0 mt-5">
        <div class="col-lg-9 col-md-8 pt-4" [style.max-width]="cartItems.items.length > 0 && isEveryItemInCartValid()? ' ': '100%'">
            <section class="menu_list mt-60 mb-60">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div *ngIf="!hideBackButton" class="col-md-12 py-4" style="position: absolute; left:2%;padding:0 !important">
                            <a [routerLink]="['/business', cityId]" style="color:var(--background-color,red)"><mdb-icon style="font-size: 22px;" fas icon="arrow-left"></mdb-icon></a>
                        </div>
                        <div class="col-lg-6 lib-item">
                            <div class="lib-panel">

                                <div class="row box-shadow">

                                    <!--<div class="col-md-3" style="font-size: 12px;">
                                        <img class="lib-img-show rounded"
                                            style="width: 100px;height: 100px;border: 1px solid #e8ebe9"
                                            [src]="(locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'">
                                        <span style="color:#ff1123;">Real Reviews <span class="badge"
                                                style="background-color: #ff1123;color: #fff;">{{ratings}}</span></span>
                                        <span style="font-size: 0.9em;"> {{numberOfRatings}} ratings</span>
                                    </div>-->
                                    <div class="col-md-9 p-0">
                                        <!-- <div class="lib-row lib-header">
                                            {{locationDetails.location.name}}
                                        </div> 
                                        <div class="lib-row lib-desc">
                                            <p class="row">
                                                <span class="col-xl-3 p-0" style="color:#ff1123;">Address:</span><span
                                                    class="col-xl-9 p-0">{{locationDetails.location.address.address1}},
                                                    {{locationDetails.location.address.city}},
                                                    {{locationDetails.location.address.state}},
                                                    {{locationDetails.location.address.zip}}</span>
                                                <span class="col-xl-3 p-0" style="color: #ff1123;">Call Now:</span><span
                                                    class="col-xl-9 p-0">{{locationDetails.location.address.phone}}</span>
                                                <span class="col-xl-3 p-0" style="color: #ff1123;">Hours:</span><span
                                                    class="col-xl-9 p-0"><span *ngFor="let time of businessTime">{{time}}<br/></span>
                                                        <span *ngIf="businessTime.length==0">{{currentTimeDisplay}}</span></span>
                                            </p>
                                        </div>-->
                                        <ul class="list-group list-group-horizontal" style="height: 6rem;"
                                            *ngIf="screenMode=='PickupDelivery' && displaySourceGroup.length>0">
                                            <li class="list-group-item p-2" style="width: 50%;"
                                                [class.selected_card_item]="scheduleWay=='Now'" (click)="changeWay()">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" class="custom-control-input" id="scheduleWayNow"
                                                        name="scheduleWay" mdbInput [(ngModel)]="scheduleWay"
                                                        value="Now">
                                                    <label class="custom-control-label" for="scheduleWayNow">Now
                                                        <p *ngIf="scheduleWay=='Now' && !isBusinessClosed" class="mb-1"
                                                            style="font-size: 11px;font-weight: 400;">
                                                            {{currentDate}} {{currentTime}}
                                                        </p>
                                                        <p *ngIf="scheduleWay=='Now' && isBusinessClosed" class="mb-1"
                                                            style="font-size: 11px;font-weight: 400;">
                                                            {{currentTimeDisplay}}
                                                        </p>
                                                    </label>

                                                </div>
                                            </li>
                                            <li class="list-group-item p-2" style="width: 50%;"
                                                [class.selected_card_item]="scheduleWay=='Later'">
                                                <div class="custom-control custom-radio">
                                                    <input (click)="openScheduleModal('schedule-modal')" type="radio" class="custom-control-input"
                                                        id="scheduleWayLater" name="scheduleWay" mdbInput
                                                        [(ngModel)]="scheduleWay" value="Later">
                                                    <label class="custom-control-label" for="scheduleWayLater">
                                                        Later
                                                        <p *ngIf="scheduleWay=='Later'" class="mb-1"
                                                            style="font-size: 12px;font-weight: 400;">
                                                            {{currentDate}} {{currentTime}}<br />
                                                            <mdb-icon far icon="clock" *ngIf="scheduleWay=='Later'">
                                                            </mdb-icon>
                                                            <span style="padding-left: 5px;"
                                                                *ngIf="scheduleWay=='Later'"
                                                                [textContent]="timer"></span>
                                                        </p>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-md-9 text-left p-0 pb-2">
                                    <ul class="list-group-horizontal list-group" >
                                        <li class="list-group-item p-2"
                                            *ngFor="let sourcegroup of displaySourceGroup; let i = index"
                                            [class.selected_card_item]="sourcegroup.id==selectedOrderGroup.id"
                                            (click)="selectSourceGroup(sourcegroup)">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" class="custom-control-input"
                                                    [attr.id]="'defaultUnchecked'+i" [value]="sourcegroup"
                                                    name="defaultExampleRadios" checked mdbInput
                                                    [(ngModel)]="selectedOrderGroup">
                                                <label class="custom-control-label"
                                                    [attr.for]="'defaultUnchecked'+i">{{sourcegroup.displayName}}</label>
                                            </div>

                                        </li>

                                    </ul>
                                </div>
                                <span *ngIf="selectedOrderGroup">
                                <div class="col-md-12  text-left" *ngIf="selectedOrderGroup.name == 'Delivery' && locationDetails && locationDetails.locationSetting && locationDetails.locationSetting.distance">
                                    {{locationDetails.locationSetting.distance}}
                                </div></span>
                                <div class="col-md-12  text-left" *ngIf="userSelectedReservation && userSelectedReservation!='' && screenMode == 'Reservation'">
                                   {{userSelectedReservation}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        style="background: #FAFAFA;position: sticky;top: 60px;z-index: 999; width: 102.5%; margin: 0px;">
                        <div class="col-md-12 row">
                            <div class="col-md-6" style="margin-left: 5%;display: inline-flex;align-items: center;">
                            <span style="color:#ff1123;" *ngIf="displaySourceGroup.length==0 || isBusinessClosed">Online Ordering is currently not available! Please check again later.</span>
                            <ng-container *ngIf="displaySourceGroup.length>0 && !isBusinessClosed">
                            <div style="" class="p-0 "><span class="card-label" style="color:#ff1123">Hours: </span></div>
                            <div class="p-0" ><span>&nbsp;{{businessTime}}</span>
                            <span style="color:#ff1123;" *ngIf="businessTime.length==0">Online Ordering is currently not available! Please check again later.</span></div>
                            </ng-container>
                        </div>
                        <div style="width: 25%; height: 50px;margin-left: 20%;">
                            <li class="row" style="width: 100%; padding-left:20px; margin: 0px; border: white 5px;height: 50px;">
                                <form class="form-inline waves-white" style="width: 100%;height: 50px;" >
                                    <div class="md-form my-0" style="font-size: 18px; width: 100%;height: 40px;background: #DEDEDE; border-radius: 40px;">
                                         <mdb-icon style="position: relative;top: -5%;left: 5%;"  fas icon="search"></mdb-icon>
                                        <input class="form-control mr-sm-0" style="width: 90%; padding: 0%;height: 40px;border-bottom: none;box-shadow: none;" type="text" placeholder="Search menu item" (input)="searchMenu($event.target.value)">
                                    </div>
                                </form>
                            </li>
                        </div>
                        </div>
                        <div class="col-md-12 offset-md-0 p-2" style="width: 100%" *ngIf="itemInfo">
                            <div class="horizontal-scroller" style="top: 0px;">
                                <div class="scroller-left"> <i class="fas fa-angle-double-left" style="color:black"></i>
                                </div>
                                <div class="scroller-right"><i class="fas fa-angle-double-right"
                                        style="color:black"></i>
                                </div>
                                <div class="category-scrollbar">
                                    <div class="icon-bar slider2" id="mainNav" #sliderContainer data-slick='{"slidesToShow": 6, "slidesToScroll": 1}'>
                                        <ng-container *ngFor="let category of itemInfo; let i = index">
                                        <a [ngClass]="(i==0)?'active':''" [attr.id]="'cat'+i"
                                            (click)="scrollToCategory(i, $event)" [attr.href]="'#itemSrc'+category.id" 
                                            *ngIf="category.itemDisplayList.length > 0 || category.subCategoryDisplayWebsite.length>0">
                                            <div style="padding: 5px 10px;">{{category.displayName}}</div>
                                        </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row p-4">
                        <div class="col-md-lg" style="margin-left: 5%;" *ngIf="itemInfo">
                            <div id="fh5co-menus" data-section="menu" class="animated">
                                <div class="row row-padded" style="width: 102%;"
                                    *ngFor="let category of itemInfo | filter:searchText; let i = index"  [attr.id]="'itemSrc'+category.id">
                                    <div class="fh5co-food-menu to-animate-2 fadeIn animated col-md-12 p-2"
                                        [attr.id]="'itemDiv'+i"  *ngIf="category.itemDisplayList.length > 0 || category.subCategoryDisplayWebsite.length>0">
                                        <h2 class="fh5co" style="font-size: 24px;font-weight: bold;">{{category.displayName}}</h2>
                                    </div>
                                    <div class="col-md-12 menu_item">
                                        <div class="fh5co-food-menu to-animate-2 fadeIn animated">
                                            <ul style="margin:0 !important;" id="firstUl">
                                                <li *ngFor="let item of category.itemDisplayList | filter:searchText; let j = index"
                                                 (click)="item.outOfStock ==1 || openDialog(item,category.displayName)">
                                                    <div class="fh5co-food-desc">
                                                        <div>
                                                            <h3>
                                                                {{item.displayName}}
                                                                <span *ngFor="let charImage of item.itemCharImages"
                                                                    style="padding:2px;">
                                                                    <img [src]="'https://images.nirvanaxp.com/images/menu_images/ItemChar/100_100/'+charImage"
                                                                        title="" alt="" height="16" width="16"
                                                                        style="cursor:pointer">
                                                                </span>
                                                            </h3>
                                                            <div class="fh5co-food-pricing" *ngIf="(item.priceSelling>0 && item.outOfStock <=0)">
                                                                {{currency}}{{item.priceSelling | number:'1.2-2'}}
                                                            </div>
                                                            <div class="fh5co-food-pricing" *ngIf="item.priceSelling==0 && item.outOfStock <=0">
                                                                {{currency}}{{minAttrPrice(item.itemAttributeTypeDisplayPacketList) | number:'1.2-2'}}
                                                            </div>
                                                            <div class="fh5co-food-outOfStock"  *ngIf="item.outOfStock==1">
                                                                 Sold Out
                                                                 
                                                            </div>


                                                        <p style="font-size: 12px;">{{item.description?.slice(0, 150)}}<span *ngIf="item.description?.length>150">...</span></p>
                                                        </div>
                                                        <figure class="rounded big-pic">
                                                            <a>
                                                                <img [src]="(item.imageName)?'https://images.nirvanaxp.com/images/menu_images/Item/650_650/'+item.imageName:'./assets/images/foodcover400.png'"
                                                                    alt="">
                                                            </a>
                                                            <button class="btn-add">Add</button>
                                                        </figure>
                                                        
                                                    </div>
                                                    
                    
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div class="row row-padded" style="width: 102%;"
                                        *ngFor="let subcategory of category.subCategoryDisplayWebsite | filter:searchText; let k = index">
                                        <div class="fh5co-food-menu to-animate-2 fadeIn animated col-md-12 p-2"
                                            [attr.id]="'itemDiv1'+k+i">
                                            <h2 class="fh5co" style="font-size: 20px;text-align: center;" *ngIf="subcategory.itemDisplayList.length > 0">
                                                {{subcategory.displayName}}</h2>
                                        </div>
                                        <div class="col-md-12 menu_item">
                                            <div class="fh5co-food-menu to-animate-2 fadeIn animated">
                                                <ul style="margin:0 !important;" id="firstUl">
                                                    <li *ngFor="let subitem of subcategory.itemDisplayList | filter:searchText; let l = index"
                                                        (click)="subitem.outOfStock ==1 || openDialog(subitem,subcategory.displayName)">
                                                        <div class="fh5co-food-desc">
                                                            
                                                            <div>
                                                                <h3>
                                                                    {{subitem.displayName}}
                                                                    <span
                                                                        *ngFor="let charImage of subitem.itemCharImages"
                                                                        style="padding:2px;">
                                                                        <img [src]="'https://images.nirvanaxp.com/images/menu_images/ItemChar/100_100/'+charImage"
                                                                            title="" alt="" height="16" width="16"
                                                                            style="cursor:pointer">
                                                                    </span>
                                                                </h3><div class="fh5co-food-pricing" *ngIf="subitem.priceSelling>0  && subitem.outOfStock <=0">
                                                                    {{currency}}{{subitem.priceSelling | number:'1.2-2'}}
                                                                </div>
                                                                <div class="fh5co-food-pricing" *ngIf="subitem.priceSelling==0  && subitem.outOfStock <=0">
                                                                    {{currency}}{{minAttrPrice(subitem.itemAttributeTypeDisplayPacketList) | number:'1.2-2'}}
                                                                </div>
                                                                <div class="fh5co-food-outOfStock"  *ngIf="subitem.outOfStock==1">
                                                                    Sold Out
                                                                    
                                                               </div>
                                                                <p style="font-size: 12px;">{{subitem.description?.slice(0, 150)}}<span *ngIf="subitem.description?.length>150">...</span></p>
                                                            </div>
                                                            <figure class="rounded big-pic">
                                                                <a>
                                                                    <img [src]="(subitem.imageName)?'https://images.nirvanaxp.com/images/menu_images/Item/650_650/'+subitem.imageName:'./assets/images/foodcover400.png'"
                                                                        alt="">
                                                                </a>
                                                                <button class="btn-add">Add</button>
                                                            </figure>
                                                            
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div *ngIf="cartItems.items.length > 0 && isEveryItemInCartValid()" class="col-lg-3 col-md-4 pt-4 sidecart open-sidecart shadow-sm">
            <div class="row justify-content-center align-items-center m-1">
                <h6 style="font-weight: 500;">Your Cart</h6>
            </div>
            <hr>
            <div>
                <!-- <div class="row" style="margin-bottom: 3px; display: flex; align-items: center">
                    <div class="col-sm-12">
                        <h3 style="font-size: 14px;font-weight: 600;">
                            South Indian
                        </h3>
                    </div>
                    <div class="col-sm-6">
                        <h3>
                            Idali Sambhar
                        </h3>
                    </div>
                    <div class="col-sm-6 text-right Subtotal" style="font-size:16px;font-weight: 400;">
                        {{currency}}7.99
                    </div>
                </div>
                <div class="row" style="margin: 0; display: flex; align-items: center">
                    <div class="col-sm-12 text-left p-0">
                        <div class="input-group">
                            <input type="text" class="form-control bg-light" value="1" aria-describedby="button-addon4">
                            <div class="input-group-append" id="button-addon4">
                                <button mdbBtn color="dark" size="sm" class="m-0 px-3 py-2 rounded-left" type="button"
                                    mdbWavesEffect>
                                    <mdb-icon fas icon="plus"></mdb-icon>
                                </button>
                                <button mdbBtn color="dark" size="sm" class="m-0 px-3 py-2" type="button" mdbWavesEffect
                                    style="border: 1px solid gray;">
                                    <mdb-icon fas icon="minus"></mdb-icon>
                                </button>
                                <button mdbBtn color="danger" size="sm" class="m-0 px-3 py-2 rounded-right"
                                    type="button" mdbWavesEffect>
                                    <mdb-icon fas icon="times"></mdb-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <hr style="margin: 8px 0"> -->
                <ng-container *ngFor="let item of cartItems.items; let j = index">
                    <ng-container *ngIf="item.isItemRemoved==0 else removedItemContent">
                    <div class="row"
                        style="margin-bottom: 3px; display: flex; align-items: center">
                        <div class="col-sm-12">
                            <h3 style="font-size: 12px;font-weight: 600;">
                                {{item.categoryName}}
                            </h3>
                        </div>
                        <div class="col-sm-6">
                            <h3>
                                <div class="col-sm-12" style="font-size:16px;font-weight: 500; padding: 0px; margin: 0%;">
                                    {{item.displayName}}
                                </div>
                            </h3>
                            <div *ngIf="item.isItemRemoved==0" class="row"
                                style="margin: 0; display: flex; align-items: center;color: grey;">
                                <div class="col-sm-12 text-left p-0 attrName"
                                    style="font-size: 12px; font-weight: 300; line-height: 1.5;"
                                    *ngFor="let attrType of item.addedAttribute">
                                    <!-- {{attrType.name}}:<span *ngFor="let attr of attrType.attr">{{attr.displayName}},</span> -->
                                    <span *ngFor="let attr of attrType.attr"> {{attr.displayName}},</span>
                                </div>
                                <div class="col-sm-12 text-left p-0 attrName"
                                    style="font-size: 11px; font-weight: 300; line-height: 1.5; font-style: italic;"
                                    *ngIf="item.comment">Special Request:<span style="word-wrap: break-word;">{{item.comment}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="input-group px-1" style="width:100px; height: 30px; border: 1px grey;margin-left: 10px;">
                                <button mdbBtn color="white" class="m-0 p-0 remove-shadow" style="width:20px; padding: 0px; margin: 0%;" type="button"
                                    mdbWavesEffect (click)="modifyCart(j,'minus')">
                                    <mdb-icon fas icon="minus"></mdb-icon>
                                </button>
                                <input type="text" readonly class="form-control bg-white p-0" style="width:30px; font-size:14px; font-weight: 400; padding: 0px; margin: 0%; border: 0px;" [value]="item.quantity"
                                aria-describedby="button-addon4">
                                <button mdbBtn color="white" class="m-0 p-0 remove-shadow" style="width:20px ;padding: 0px; margin: 0%;" type="button"
                                    mdbWavesEffect (click)="modifyCart(j,'plus')">
                                    <mdb-icon fas icon="plus"></mdb-icon>
                                </button>
                                <!-- <button mdbBtn color="white" class="p-0 remove-shadow" style="width:20px ;padding: 0px; margin-left: 15%;" type="button"
                                    mdbWavesEffect (click)="modifyCart(j,'remove')">
                                    <mdb-icon fas icon="trash"></mdb-icon>
                                </button> -->
                            <div class="input-group-append" id="button-addon4"></div>
                        </div>
                        <div class="col-sm-3 Subtotal" style="font-size:14px;font-weight: 800;">
                            {{currency}}{{item.subTotal*item.quantity| number:'1.2-2'}}
                        </div>
                        
                    </div>
                    <hr style="margin: 10px 0">
                    </ng-container>
                    <ng-template #removedItemContent></ng-template>
                </ng-container>
            </div>
            
            <div class="sidecart-header row shadow-sm" style="position: sticky; bottom: 10px;">
                <div class="col-sm-8 p-0">
                    <!-- <button white-loader="" type="button" class="radius-4 btn" style="width: 100%;height: 50px;">
                        
                    </button> -->
                    <button mdbBtn type="button" class="my-button" mdbWavesEffect
                        (click)="openCheckkoutModal('checkout-modal')" style="font-size: 16px;padding: 20px;"
                        *ngIf="screenMode=='PickupDelivery'">Checkout</button>
                    <button mdbBtn type="button" class="my-button" mdbWavesEffect
                        (click)="openCheckkoutModal('checkout-modal')" style="font-size: 16px;padding: 20px;"
                        *ngIf="screenMode=='Menu'">Select and
                        Save</button>
                    <button mdbBtn type="button" class="my-button" mdbWavesEffect
                        (click)="openCheckkoutModal('checkout-modal')" style="font-size: 16px;padding: 5px;"
                        *ngIf="screenMode=='Reservation'">Reserve &
                        Pre-Select
                    </button>
                </div>
                <div class="col-sm-4 p-1 text-center">
                    <h3 class="grand_total" style="font-size: 16px;font-weight: 1000;">
                        {{currency}}{{cartItems.subTotal | number:'1.2-2'}}
                    </h3>
                    <span style="font-size: 12px" class=""><i>(without taxes)</i></span>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>

    <app-modal-checkout id="checkout-modal" [selectedOrderGroup]="selectedOrderGroup" [scheduleWay]="scheduleWay"
        (onCloseCheckout)="closeCheckoutModal($event)" (stopTimer)="stopTimer($event)"
        [currentDateTime]="currentDateTime"></app-modal-checkout>
    <app-modal-schedule id="schedule-modal" [selectedOrderGroup]="selectedOrderGroup"
        (onClose)="closeScheduleModal($event)"></app-modal-schedule>
    <app-modal-user id="user-modal"></app-modal-user>
    <app-thank-you-modal id="thank-you-modal" [selectedOrderGroup]="selectedOrderGroup" [scheduleWay]="scheduleWay"
        (onClose)="clearCart($event)"></app-thank-you-modal>
        <!-- <app-common-footer></app-common-footer> -->