import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule, Routes } from '@angular/router';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { CommonFooterModule } from '../../shared/layout/common-footer.module';
import { AngularMaterialModule } from '../../angular-material.module';
import { BusinessSignupComponent } from './business-signup.component';

const routes: Routes = [
  {
    path: '',
    component: BusinessSignupComponent
  }
];

@NgModule({
  declarations: [ BusinessSignupComponent],
  imports: [
    MDBBootstrapModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule,
    [RouterModule.forChild(routes)],
    CommonFooterModule
    ,


  ],

  exports: [RouterModule]

})
export class BusinessSignupModule { }
