import { Component, OnInit } from '@angular/core';
import { LocationService } from '../core/services/location.service';
import { ItemsType, CusineType, City, PageProperty, Business,Area } from '../core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {
  cards: ItemsType;
  cusines: CusineType;
  cities: City;
  areas:Area[];
  pageProperty: PageProperty;
  business: Business;
  selectedCityId = 0;
  selectedAreaId=0;
  selectedArea:any;
  selectedItemTypeId = 0;
  selectedCusineId = 0;
  selectedPropertyId = 2;
  selectCityName = "";
  isHomePage: boolean = false;
  searchText: string;
  isUserLogin: any;
  metaStr: string;
  slides: any = [[]];
  businessSlides: any = [[]];


  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  constructor(private locationService: LocationService, private route: ActivatedRoute, private router: Router, private titleService: Title,
    private metaTagService: Meta) {
    this.selectedCityId = this.route.snapshot.params.id;
    this.selectedAreaId=this.route.snapshot.params.id;
    localStorage.setItem("selectedAreaId",this.route.snapshot.params.id);
    this.isUserLogin = localStorage.getItem('isUserLogin');
    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
    this.selectedArea=JSON.parse(localStorage.getItem("SelectedArea"));
 // this.titleService.setTitle(this.pageProperty.name);

    this.metaStr = this.pageProperty.name + ", "+this.pageProperty.subTitle+",  restaurants, order food, order online, order food online, food, " +
      "delivery, food delivery, home delivery, fast, hungry, quickly, offer, discount, contactless menu, "+
      "contactless pickup, contactless delievry, Reserve and pre-select option, Real Reviews, Order history, Order status,Reservation History";

    this.selectedPropertyId = this.pageProperty.id;
    this.locationService.getAllGlobalItemsType(this.pageProperty.id)
      .subscribe((response: any) => {
        this.cards = response;
        this.slides = this.chunk(this.cards, 6);
        for (var key in this.cards) {
          this.metaStr = this.metaStr + ", " + this.cards[key].displayName;
        }
        setTimeout(function () {


          console.log('scollbar resp', response)
          $('.slider2').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            initialSlide: 0,
            variableWidth: true,
            prevArrow: $('.scroller-left'),
            nextArrow: $('.scroller-right'),
          });
        }, 500);
        console.log(response);
      });

    this.locationService.getAllGlobalCusineType(this.pageProperty.id)
      .subscribe((response: any) => {
        this.cusines = response;
        this.selectedCusineId = response[0].id;
        console.log(response);
        for (var key in response) {
          this.metaStr = this.metaStr + ", " + response[key].displayName;
        }

      });
      this.selectedArea=JSON.parse(localStorage.getItem("SelectedArea"));
      this.selectCityName = this.selectedArea.name;
      this.metaStr = this.metaStr + ", " + this.selectCityName;
    // this.locationService.getCityByPropertyId(this.pageProperty.id).subscribe((response: any) => {
    //   // this.cusines = response;
    //   this.cities = response;
    //   for (var i = 0; i < response.length; i++) {
    //     if (response[i].id == this.selectedCityId) {
    //       this.selectCityName = response[i].name;
    //       this.metaStr = this.metaStr + ", " + this.selectCityName;
    //     }
    //   }
    // });

    this.locationService.getAllBusinessByAreaIdItemsTypeIdCuisineTypeId(this.selectedAreaId, this.selectedItemTypeId, this.selectedCusineId, this.selectedPropertyId).subscribe((response: any) => {
      this.business = response;
      console.log("response biz  ststst----------------------------------------", response);

      var day = parseInt(moment().format('e')) + 1;
      for (var bizkey in response) {
        this.metaStr = this.metaStr + ", " + response[bizkey].name;
        if (response[bizkey].menu == 1)
          this.metaStr = this.metaStr + " - " + response[bizkey].menuDisplayName;
        if (response[bizkey].pickupDelivery == 1)
          this.metaStr = this.metaStr + "," + response[bizkey].pickupDeliveryDisplayName;
        if (response[bizkey].reservation == 1)
          this.metaStr = this.metaStr + "," + response[bizkey].reservationDisplayName;


        this.business[bizkey].businessStartTime = [];
        this.business[bizkey].businessEndTime = [];
        this.business[bizkey].businessTime = [];


        for (var key in response[bizkey].businessHours) {
          if (response[bizkey].businessHours[key].day.id == day && response[bizkey].businessHours[key].isClosed == 0) {
            console.log("response biz hrs----------------------------------------", response[bizkey].businessHours[key]);

            var newTime = response[bizkey].businessHours[key].timeFrom.split(':');
            var dateFromTime = new Date();
            dateFromTime.setHours(parseInt(newTime[0]));
            dateFromTime.setMinutes(parseInt(newTime[1]));
            dateFromTime.setSeconds(parseInt(newTime[2]));
            this.business[bizkey].businessStartTime.push(response[bizkey].businessHours[key].timeFrom);

            var newTime = response[bizkey].businessHours[key].timeTo.split(':');
            var dateTime = new Date();
            dateTime.setHours(parseInt(newTime[0]));
            dateTime.setMinutes(parseInt(newTime[1]));
            dateTime.setSeconds(parseInt(newTime[2]));
            this.business[bizkey].businessEndTime.push(response[bizkey].businessHours[key].timeTo);
            this.business[bizkey].businessTime.push(moment(dateFromTime).format('hh:mm a') + " - " + moment(dateTime).format('hh:mm a'));
          }
        }
      }
      console.log("business setr -------------------------------------", response[bizkey]);
      this.metaTagService.updateTag({ name: 'keywords', content: this.metaStr });
    });
  }

  ngOnInit() {

    localStorage.removeItem('cartItems');
    localStorage.removeItem('orderHeader');
    localStorage.removeItem('idOfHoldingClientObj');
    localStorage.removeItem('required_pickuptime');
    localStorage.removeItem('slotId');
    localStorage.removeItem('countDown');
    localStorage.removeItem('startDate');
    localStorage.removeItem('currentReservation');
    localStorage.removeItem("locationDetails");
    localStorage.removeItem("business_id");


  }

  selectCurrentCity(city) {
    console.log(city);
    this.selectedAreaId = JSON.parse(city).id;
    this.getBusiness();
  }

  searchBiz(searchText) {
    this.searchText = searchText;
  }


  selectCusine(cusineId) {
    this.selectedCusineId = cusineId;
    this.getBusiness();
  }

  selectItemType(itemTypeId) {
    this.selectedItemTypeId = itemTypeId;
    this.getBusiness();
  }

  getBusiness() {
    this.locationService.getAllBusinessByAreaIdItemsTypeIdCuisineTypeId(this.selectedAreaId, this.selectedItemTypeId, this.selectedCusineId, this.selectedPropertyId).subscribe((response: any) => {
      this.business = response;
      var day = parseInt(moment().format('e')) + 1;
      for (var bizkey in response) {
        this.metaStr = this.metaStr + ", " + response[bizkey].name;
        this.business[bizkey].businessStartTime = [];
        this.business[bizkey].businessEndTime = [];
        this.business[bizkey].businessTime = [];
        if (response[bizkey].menu == 1)
          this.metaStr = this.metaStr + " - " + response[bizkey].menuDisplayName;
        if (response[bizkey].pickupDelivery == 1)
          this.metaStr = this.metaStr + "," + response[bizkey].pickupDeliveryDisplayName;
        if (response[bizkey].reservation == 1)
          this.metaStr = this.metaStr + "," + response[bizkey].reservationDisplayName;

        for (var key in response[bizkey].businessHours) {
          if (response[bizkey].businessHours[key].day.id == day && response[bizkey].businessHours[key].isClosed == 0) {
            console.log("response biz hrs----------------------------------------", response[bizkey].businessHours[key]);

            var newTime = response[bizkey].businessHours[key].timeFrom.split(':');
            var dateFromTime = new Date();
            dateFromTime.setHours(parseInt(newTime[0]));
            dateFromTime.setMinutes(parseInt(newTime[1]));
            dateFromTime.setSeconds(parseInt(newTime[2]));
            this.business[bizkey].businessStartTime.push(response[bizkey].businessHours[key].timeFrom);

            var newTime = response[bizkey].businessHours[key].timeTo.split(':');
            var dateTime = new Date();
            dateTime.setHours(parseInt(newTime[0]));
            dateTime.setMinutes(parseInt(newTime[1]));
            dateTime.setSeconds(parseInt(newTime[2]));
            this.business[bizkey].businessEndTime.push(response[bizkey].businessHours[key].timeTo);
            this.business[bizkey].businessTime.push(moment(dateFromTime).format('hh:mm a') + " - " + moment(dateTime).format('hh:mm a'));
          }
        }
      }
      this.metaTagService.updateTag({ name: 'keywords', content: this.metaStr });

    });
  }

  getMenuPageForPickupDelivery(business) {
    console.log("we got the business details",business);
    localStorage.setItem("auth_token", business.authToken);
    localStorage.setItem("business_id", business.id);
    localStorage.setItem("ratings", business.ratings);
    localStorage.setItem("numberOfRatings", business.numberOfRatings);
    localStorage.setItem("account_id", business.accountId);
    localStorage.setItem("businessStartTime", JSON.stringify(business.businessStartTime));
    localStorage.setItem("businessEndTime", JSON.stringify(business.businessStartTime));
    localStorage.setItem("businessTime", JSON.stringify(business.businessTime));
    localStorage.setItem("businessHours", JSON.stringify(business.businessHours));
    this.locationService.getLocationsByBusinessId(business.id).subscribe((response: any) => {
      localStorage.setItem("locationDetails", JSON.stringify(response));
      localStorage.setItem('currency', response.location.transactionalCurrency.symbol);
      var timeZone = response.timezone.timezoneName;
      var gmt = timeZone.split('GMT');
      if (gmt['1'].length == 5) {
        var text = "";
        for (var key in gmt['1']) {
          text += gmt['1'][key];
          if (key == '0')
            text += "0"
        }
      } else
        text = gmt['1'];
      localStorage.setItem('gmt', text);
      this.router.navigate(['menu', business.id, "PickupDelivery"]);
    });
  }

  getMenuPageForInstore(business) {
    localStorage.setItem("auth_token", business.authToken);
    localStorage.setItem("business_id", business.id);
    localStorage.setItem("ratings", business.ratings);
    localStorage.setItem("numberOfRatings", business.numberOfRatings);
    localStorage.setItem("bizLat",business.lattitude);
    localStorage.setItem("bizLong",business.longitude);
    localStorage.setItem("account_id", business.accountId);
    localStorage.setItem("businessStartTime", JSON.stringify(business.businessStartTime));
    localStorage.setItem("businessEndTime", JSON.stringify(business.businessStartTime));
    localStorage.setItem("businessTime", JSON.stringify(business.businessTime));
    localStorage.setItem("businessHours", JSON.stringify(business.businessHours));
    this.locationService.getLocationsByBusinessId(business.id).subscribe((response: any) => {
      console.log(response);
      localStorage.setItem("locationDetails", JSON.stringify(response));
      localStorage.setItem('currency', response.location.transactionalCurrency.symbol);

      var timeZone = response.timezone.timezoneName;
      var gmt = timeZone.split('GMT');
      if (gmt['1'].length == 5) {
        var text = "";
        for (var key in gmt['1']) {
          text += gmt['1'][key];
          if (key == '0')
            text += "0"
        }
      } else
        text = gmt['1'];
      localStorage.setItem('gmt', text);

      this.router.navigate(['menu', business.id, "Menu"]);
    });
  }

  getReservation(business) {
    localStorage.setItem("auth_token", business.authToken);
    localStorage.setItem("business_id", business.id);
    localStorage.setItem("ratings", business.ratings);
    localStorage.setItem("numberOfRatings", business.numberOfRatings);
    localStorage.setItem("bizLat",business.lattitude);
    localStorage.setItem("bizLong",business.longitude);
    localStorage.setItem("account_id", business.accountId);
    localStorage.setItem("businessStartTime", JSON.stringify(business.businessStartTime));
    localStorage.setItem("businessEndTime", JSON.stringify(business.businessStartTime));
    localStorage.setItem("businessTime", JSON.stringify(business.businessTime));
    localStorage.setItem("businessHours", JSON.stringify(business.businessHours));
    this.locationService.getLocationsByBusinessId(business.id).subscribe((response: any) => {
      console.log(response);
      localStorage.setItem("locationDetails", JSON.stringify(response));
      localStorage.setItem('currency', response.location.transactionalCurrency.symbol);

      var timeZone = response.timezone.timezoneName;
      var gmt = timeZone.split('GMT');
      if (gmt['1'].length == 5) {
        var text = "";
        for (var key in gmt['1']) {
          text += gmt['1'][key];
          if (key == '0')
            text += "0"
        }
      } else
        text = gmt['1'];
      localStorage.setItem('gmt', text);
      if (this.isUserLogin == '1')
        this.router.navigate(['reservation']);
      else
        this.router.navigate(['loginasguest', 'reservation']);

    });


  }
}
