import { Injectable } from '@angular/core';
import { paths } from './app.path';
import { LocationService, PageProperty } from './core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PathResolveService implements Resolve<string | null> {
  pageProperty: PageProperty;

  constructor(private locationService: LocationService, private router: Router) { }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): string | null {
    const typoPath = state.url.replace('/', '');
    const threshold = this.getThreshold(typoPath);
    const dictionary = Object.values(paths)
      .filter(path => Math.abs(path.length - typoPath.length) < threshold);
    
    // if (!dictionary.length) return null;

    //this.sortByDistances(typoPath, dictionary);
    if(typoPath!=""){
    this.locationService.getUrlParam(typoPath).subscribe((response: any) => {
      // this.cusines = response;
      console.log("response length---------------------------------------",);
      if (response.id > 0) {
        this.locationService.getAllOnPageProperty(response.propertyId)
          .subscribe(tags => {
            this.pageProperty = <PageProperty>tags;
            console.log(tags);
            localStorage.setItem("pageProperty", JSON.stringify(this.pageProperty));
            localStorage.setItem("convenienceName", this.pageProperty.convenienceName);
            localStorage.removeItem("sourceGroup");

            this.locationService.getCityByPropertyId(this.pageProperty.id)
              .subscribe((res: any) => {
                localStorage.setItem("cities", JSON.stringify(res));
                localStorage.setItem("SelectedCity", JSON.stringify(res.find(o => o.id == parseInt(response.cityId))));
                if (response.businessId != "" && response.businessId != 0) {
                  var day = parseInt(moment().format('e')) + 1;
        
                  this.locationService.getBusiness(response.businessId)
                    .subscribe((business: any) => {
                      business[0].businessStartTime = [];
                      business[0].businessEndTime = [];
                      business[0].businessTime = [];
        
                      for (var key in business[0].businessHours) {
                        if (business[0].businessHours[key].day.id == day && business[0].businessHours[key].isClosed == 0) {
                          console.log("response biz hrs----------------------------------------", business[0].businessHours[key]);
        
                          var newTime = business[0].businessHours[key].timeFrom.split(':');
                          var dateFromTime = new Date();
                          dateFromTime.setHours(parseInt(newTime[0]));
                          dateFromTime.setMinutes(parseInt(newTime[1]));
                          dateFromTime.setSeconds(parseInt(newTime[2]));
                          business[0].businessStartTime.push(business[0].businessHours[key].timeFrom);
        
                          var newTime = business[0].businessHours[key].timeTo.split(':');
                          var dateTime = new Date();
                          dateTime.setHours(parseInt(newTime[0]));
                          dateTime.setMinutes(parseInt(newTime[1]));
                          dateTime.setSeconds(parseInt(newTime[2]));
                          business[0].businessEndTime.push(business[0].businessHours[key].timeTo);
                          business[0].businessTime.push(moment(dateFromTime).format('hh:mm a') + " - " + moment(dateTime).format('hh:mm a'));
                        }
                      }
                      localStorage.setItem("auth_token", business[0].authToken);
                      localStorage.setItem("business_id", business[0].id);
                      localStorage.setItem("bizLat",business[0].lattitude);
                      localStorage.setItem("bizLong",business[0].longitude);
                      localStorage.setItem("ratings", business[0].ratings);
                      localStorage.setItem("numberOfRatings", business[0].numberOfRatings);
                      localStorage.setItem("account_id", business[0].accountId);
                      localStorage.setItem("businessStartTime", JSON.stringify(business[0].businessStartTime));
                      localStorage.setItem("businessEndTime", JSON.stringify(business[0].businessStartTime));
                      localStorage.setItem("businessTime", JSON.stringify(business[0].businessTime));
                      localStorage.setItem("businessHours", JSON.stringify(business[0].businessHours));
                      this.locationService.getLocationsByBusinessId(business[0].id).subscribe((locationRes: any) => {
                        localStorage.setItem("locationDetails", JSON.stringify(locationRes));
                        localStorage.setItem('currency', locationRes.location.transactionalCurrency.symbol);
                        var timeZone = locationRes.timezone.timezoneName;
                        var gmt = timeZone.split('GMT');
                        if (gmt['1'].length == 5) {
                          var text = "";
                          for (var key in gmt['1']) {
                            text += gmt['1'][key];
                            if (key == '0')
                              text += "0"
                          }
                        } else
                          text = gmt['1'];
                        localStorage.setItem('gmt', text);
                        if (response.pickupDelivery == 1) {
                          this.router.navigate(['menu', business[0].id, "PickupDelivery"]);
                        } else if (response.menu == 1) {
                          this.router.navigate(['menu', business[0].id, "Menu"]);
                        } else {
                          if (localStorage.getItem('isUserLogin')){
                            this.router.navigate(['reservation']);
                          }else{
                            this.router.navigate(['loginasguest', 'reservation']);
                          }
                        }
                      });
                    });
                } else {
                  this.router.navigate(['/business', response.cityId]);
                }
              });
          });
       
      }
    });
  }
    console.log("----------------------------------", typoPath);
    console.log("----------------------------------", dictionary);
    localStorage.setItem('HideBackButton',JSON.stringify(true));
    return `/${dictionary[0]}`;
  }


  getThreshold(path: string): number {
    if (path.length < 5) return 3;

    return 5;
  }
  sortByDistances(typoPath: string, dictionary: string[]) {
    const pathsDistance = {} as { [name: string]: number };

    dictionary.sort((a, b) => {
      if (!(a in pathsDistance)) {
        pathsDistance[a] = this.levenshtein(a, typoPath);
      }
      if (!(b in pathsDistance)) {
        pathsDistance[b] = this.levenshtein(b, typoPath);
      }

      return pathsDistance[a] - pathsDistance[b];
    });
  }

  levenshtein(a: string, b: string): number {
    if (a.length == 0) {
      return b.length;
    }
    if (b.length == 0) {
      return a.length;
    }

    const matrix = [];

    // increment along the first column of each row
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) == a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1, // deletion
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }
}