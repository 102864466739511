import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';


interface DialogData {
  isToday:boolean;
  dateAsObject :Date;
  time: string;


}


@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {

  isToday:Boolean = true;
  minuteStep = 5;
  hourStep = 1 ;
  dateAsObject :Date = new Date();
  time :NgbTimeStruct  = {hour: this.dateAsObject.getHours(), minute: this.dateAsObject.getMinutes(), second: this.dateAsObject.getMinutes()};;;


  constructor(
    public dialogRef: MatDialogRef<TimePickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      this.time   = {hour: data.dateAsObject.getHours(), minute: data.dateAsObject.getMinutes(), second: data.dateAsObject.getMinutes()};
      this.isToday = data.isToday;
      console.log(this.time );

    }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {


  }



  ctrl = new FormControl('', (control: FormControl) => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if(!this.isToday)
    {
      return null;
    }

    if (value.hour < this.dateAsObject.getHours() && this.isToday) {
      return {tooEarly: true};
    }
    if (value.hour > this.dateAsObject.getHours() && this.isToday) {
      return {tooLate: true};
    }

    return null;
  });

}
