<app-main-header [isHomePage]="isHomePage" [showSearch]="0" [pageName]="page"
*ngIf="page == 'home'  || page == 'business'; else elseHeadea"



></app-main-header>
<ng-template #elseHeadea>
  <app-main-header [isHomePage]="isHomePage" [showSearch]="0"
  [isshowlogoimage]="'true'" [logoUrl]="
(locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'"

  ></app-main-header>

</ng-template>

  


<div class="container">
  <br />
  <h5 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-size:36px;
  font-family: 'Poppins-Regular';
  margin-bottom: 20px;">{{pageProperty?.subTitle}}</h5>
  <h5 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-family: 'Poppins-SemiBold';
  font-size:36px;
  margin-bottom: 20px;">Continue as a Guest</h5>
  <p style="
  text-align: center;
  color: #767676;
  width: 100%;
  font-family: 'Poppins-Regular';
  font-size:18px;" *ngIf="showForgotScreen">Enter your mobile number to receive a one time password.</p>
  <form [formGroup]="signupForm" (ngSubmit)="submitSignUpForm()" class="form-horizontal" *ngIf="showForgotScreen">

    <mat-card>
      <mat-card-content>


        <div class="form-group">
         
          <div class="row">
            <div class="col-md-3 coldesign ">
              <mat-form-field appearance="outline" class="example-full-width text-dark text-center">
                <mat-select style="height: 19.5px;" formControlName="countryCode" panelClass="matpanelClassUniveral"
                  disableOptionCentering>
                  <input #searchInput class="text-center searchbox" placeholder="Search Here" (keyup)="onKey($event.target.value)">
                  <!-- <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
                      <mat-icon>close</mat-icon>
                    </button> -->
                  <mat-select-trigger>+{{signupForm.controls.countryCode.value.phoneCode}}</mat-select-trigger>

                  <ng-container *ngFor="let country of countryData">
                    <!-- <mat-option    *ngIf ="country.shortName && country.phoneCode!=0" [value]="country.phoneCode"> +{{country?.phoneCode}}({{country.shortName|lowercase}})</mat-option> -->
                    <mat-option *ngIf="country.phoneCode!=0" [value]="country">
                      {{country?.displayName }}-({{country?.phoneCode }})</mat-option>

                  </ng-container>
                </mat-select>


              </mat-form-field>
            </div>

            <div class="col-md-9 coldesign">
              <mat-form-field appearance="outline">
                <input color="primary" matInput style="height: 20px;" type="tel" placeholder="Mobile Number"
                  formControlName="phoneNumber" required>
                <mat-error>
                  Please provide a valid mobile number
                </mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field appearance="outline" class="full-width-input">
                <input matInput type="test" style="height: 20px;" placeholder="Name" formControlName="uname" required>
                <mat-error>
                  Please provide a valid name
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          
        </div>


      </mat-card-content>
      <mat-card-footer>
        <div class="row">
          <div class="form-group col-md-12" style="padding: 0px !important;">
            <button class="button" type="submit">Submit</button>

          </div>
        </div>
      </mat-card-footer>

    </mat-card>
  </form>
  <form [formGroup]="otpForm" (ngSubmit)="submitOtpForm()" class="form-horizontal" *ngIf="showOtpScreen">

    <mat-card>
      <mat-card-content>
        <div class="form-group">
          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field appearance="outline">
                <input color="primary" matInput style="height: 20px;" type="email" placeholder="One Time Password(OTP)"
                  formControlName="otp" required>
                <mat-error>
                  OTP is required.
                </mat-error>

              </mat-form-field>

            </div>

          </div>
        </div>
      </mat-card-content>
      <mat-card-footer style="margin-left: 5px;">
        <div class="row">
          <div class="form-group col-md-12" style="padding: 0px !important;">
            <button class="button" type="submit" style="width: 140px;">
              Submit </button>
            <button class="button" type="button" (click)="submitSignUpForm()">
              Resend
            </button>
          </div>
        </div>
        <div class="row" style="color: #ff0e20;font-weight: bold;">
          Timeout for one time password {{formatCountdownTime()}}
        </div>
      </mat-card-footer>

    </mat-card>
  </form>
  <h6 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-family: 'Poppins-SemiBold';
  font-size:18px;
  font-style: normal;margin-bottom: 20px;">Already have an account with us ? <a
      style="color: #ff0e20;font-family: 'Poppins-SemiBold';" [routerLink]="['/login', page]">Login</a></h6>
</div>

<!-- <app-common-footer></app-common-footer> -->