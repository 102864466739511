import { Component, OnInit } from '@angular/core';
import { LocationService } from '../core/services/location.service';
import { Title, Meta } from '@angular/platform-browser';

import { City, PageProperty,Area } from '../core/models';

import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { appServerConstants } from '../app-url-constants';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  pageProperty: PageProperty;
  deviceInfo;
  cities: City[];
  areas:Area[];
  isUserLogin: any;
  propertyId: any;

  constructor(private locationService: LocationService, private deviceService: DeviceDetectorService,
    private router: Router, private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit(): void {

    this.deviceInfo = this.deviceService.getDeviceInfo();
    localStorage.setItem('auth_token', '937e45c7-37ae-4249-b131-1c726693104c');
    console.log(this.deviceInfo);
    var deviceId = this.deviceService.userAgent;

    localStorage.setItem('deviceId', deviceId);
    // localStorage.setItem('localReferenceNumber', '0');
    //localStorage.setItem('account_id', '1312');

    this.isUserLogin = localStorage.getItem("isUserLogin");
    console.log(this.isUserLogin);

    this.propertyId = appServerConstants.PROPERTY_ID;
    this.locationService.getAllOnPageProperty(this.propertyId)
      .subscribe(tags => {
        this.pageProperty = <PageProperty>tags;
        console.log(tags);
     // this.titleService.setTitle(this.pageProperty.name);


        localStorage.setItem("pageProperty", JSON.stringify(this.pageProperty));
        localStorage.setItem("convenienceName", this.pageProperty.convenienceName);
        this.locationService.getCityByPropertyId(this.pageProperty.id)
          .subscribe((response: any) => {
            this.cities = response;
            console.log(response);
            var metaStr = this.pageProperty.name + ", " + this.pageProperty.subTitle + ", restaurants, order food, order online, order food online, food, " +
              "delivery, food delivery, home delivery, fast, hungry, quickly, offer, discount, contactless menu, " +
              "contactless pickup, contactless delievry, Reserve and pre-select option, Real Reviews, Order history, Order status,Reservation History";
            var cityName = "";
            var areaName="";
            if(this.pageProperty.id){
              this.locationService.getAreaByCityId(this.cities[0].id)
              .subscribe((response: any) => {
                this.areas = response;
                localStorage.setItem("areas", JSON.stringify(this.areas));

                console.log(response);
              for (var key in this.areas) {
                metaStr = metaStr + ", " + this.areas[key].name;
               areaName = cityName + this.areas[key].name + ", ";
              }
            });
            }else{
              for (var key in this.cities) {
                metaStr = metaStr + ", " + this.cities[key].cityName;
                cityName = cityName + this.cities[key].cityName + ", ";
              }
            }
           
            this.metaTagService.addTags([
              { name: 'keywords', content: metaStr },
              { name: 'robots', content: 'index, follow' },
              { name: 'author', content: 'Uzma Shaikh' },
              { name: 'description', content: 'With ' + this.pageProperty.name + ', your favorite local restaurants are just a few taps away. Order safely and securely from ' + this.pageProperty.name + ' restaurants to satisfy your cravings. We offer a wide selection of ' + this.pageProperty.name + ' restaurants and world cuisines - from Italian to Thai and Indian to Continental. serving in ' + areaName },
              { charset: 'UTF-8' }
            ]);
            localStorage.setItem("cities", JSON.stringify(this.cities));


          });

      });





    // this.cities =JSON.parse(localStorage.getItem("cities"));

  }



  cityHomeCliked(area: Area) {

    // localStorage.setItem("SelectedCity", JSON.stringify(city));
    localStorage.setItem("SelectedArea", JSON.stringify(area));

    //  this.router.navigate(['/business',city.id]);

    this.router.navigate(['/business', area.id]).then((e) => {
      if (e) {
        console.log("Navigation is successful!");
        localStorage.setItem('HideBackButton', JSON.stringify(false));
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

}
