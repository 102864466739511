import { Component, OnInit, Inject, ElementRef,ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

interface DialogData {
  item: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  item: any;
  cartItem: any;
  itemValid: boolean = false;
  currency: any;
  @ViewChild('main', { static: false }) content: ElementRef;
  @ViewChild('listGroup', {static:false}) listGroup: ElementRef;
  constructor(
    
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private el: ElementRef) {
    console.log('data is===', data)
    this.item = data.item;
    this.currency = localStorage.getItem('currency');

    this.cartItem = {
      "id": this.item.id,
      "displayName": this.item.displayName,
      "priceSelling": this.item.priceSelling,
      "subTotal": this.item.priceSelling,
      "itemAttributeTypeDisplayPacketList": this.item.itemAttributeTypeDisplayPacketList,
      "comment": "",
      "quantity": this.item.quantity,
      "orderDetailId": 0,
      "addedAttribute": [],
      "isItemRemoved": 0
    }

    for (let i in this.item.itemAttributeTypeDisplayPacketList) {
      if (this.item.itemAttributeTypeDisplayPacketList[i].isRequired)
        this.itemValid = true;
    }
  }

  scrollToListGroup() {
    if (this.listGroup) {
      this.listGroup.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  showAlert(): void {
    const targetElement = this.el.nativeElement.querySelector('#targetDiv');console.log(targetElement);
    alert('You Need to check All Required Boxes');
    targetElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' } as ScrollIntoViewOptions);
  }
  

  modifyCart(action) {
    switch (action) {
      case "plus":
        this.cartItem.quantity += 1;
        break;
      case "minus":
        (this.cartItem.quantity > 1) ? this.cartItem.quantity -= 1 : 1;
        break;
    }
  }

  addAttribute(attribute, attrType, e) {
    if(attribute.multiSelect)
    e.preventDefault();  
    var attrTypeKey: any = -1;
    attribute.orderDetailAttributeId = 0;
    for (var i in this.cartItem.addedAttribute) {
      if (this.cartItem.addedAttribute[i].id == attrType.id) {
        attrTypeKey = i;
        break
      }
    }
    if (attrTypeKey > -1) {
        for (let i in this.cartItem.itemAttributeTypeDisplayPacketList) {
          if (this.cartItem.itemAttributeTypeDisplayPacketList[i].id == attrType.id && this.cartItem.itemAttributeTypeDisplayPacketList[i].isRequired) {
            if (this.cartItem.addedAttribute[attrTypeKey].attr.length == this.cartItem.itemAttributeTypeDisplayPacketList[i].maxAllowed) {
              var len = this.cartItem.addedAttribute[attrTypeKey].attr.length;
              this.cartItem.subTotal -= this.cartItem.addedAttribute[attrTypeKey].attr[len - 1].sellingPrice;
              this.cartItem.addedAttribute[attrTypeKey].attr.pop();
              break;
            }
  
          }
        }
      
      var attrKey: any = -1;
      for (var i in this.cartItem.addedAttribute[attrTypeKey].attr) {
        if (this.cartItem.addedAttribute[attrTypeKey].attr[i].id == attribute.id) {
          attrKey = i;
          break
        }
      }
      if (attrKey > -1) {
        this.cartItem.addedAttribute[attrTypeKey].attr.splice(attrKey, 1);
        this.cartItem.subTotal -= attribute.sellingPrice;
      } else {
        this.cartItem.addedAttribute[attrTypeKey].attr.push(attribute)
        this.cartItem.subTotal += attribute.sellingPrice;
      }
    } else {
      this.cartItem.addedAttribute.push({ id: attrType.id, name: attrType.displayName, maxAllowed: attrType.maxAllowed, isRequired: attrType.isRequired, attr: [attribute] });
      this.cartItem.subTotal += attribute.sellingPrice;
      console.log('attribute added here',this.cartItem,attribute)
    }

    let j = 0;
    let r = 0;
    for (let i in this.cartItem.itemAttributeTypeDisplayPacketList) {

      if (this.cartItem.itemAttributeTypeDisplayPacketList[i].isRequired) {
        r++;
        for (let k in this.cartItem.addedAttribute) {
          if (this.cartItem.itemAttributeTypeDisplayPacketList[i].id == this.cartItem.addedAttribute[k].id && this.cartItem.addedAttribute[k].attr.length == this.cartItem.addedAttribute[k].maxAllowed) {
            j++;
          }

        }

      }
    }
    console.log('attribute added',this.cartItem)
    if (r == j)
      this.itemValid = false;
      
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkUncheck(j, i,e,multiSelect) {
    if(multiSelect){
      e.preventDefault();  
      console.log('imhere')
      document.getElementById('attribute'+j+i).click();
      e.stopPropagation();
    }
    
  }

  isEnabled(attribute, attrType) {
    let isEnabled = false;
    var attrTypeKey: any = -1;
    attribute.orderDetailAttributeId = 0;
    for (var i in this.cartItem.addedAttribute) {
      if (this.cartItem.addedAttribute[i].id == attrType.id) {
        attrTypeKey = i;
        for (var i in this.cartItem.addedAttribute[attrTypeKey].attr) {
          if (this.cartItem.addedAttribute[attrTypeKey].attr[i].id == attribute.id) {
            isEnabled = true
            break
          }
        }
        break
      }
    }
    return isEnabled;
  }

  ngOnInit() {
    setTimeout(() => {
      this.content.nativeElement.scrollIntoView();
      this.scrollToListGroup();
    }, 400);
    
  }
}
