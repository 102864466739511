import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { DeviceDetectorModule } from 'ngx-device-detector';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import {

  SharedModule
} from './shared';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { BusinessModule } from './business/business.module';
import { MenuModule } from './menu/menu.module';
import { MainHeaderModule } from './shared/layout/main-header.module';
import { CommonFooterModule } from './shared/layout/common-footer.module';
import { LoginModule } from './login/login.module';
import { LoginAsGuestModule } from './loginasguest/loginasguest.module';
import { SignupModule } from './signup/signup.module';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { ReservationModule } from './reservation/reservation.module';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ModalComponent } from './modal/modal.component';
import { NotfoundComponent } from './shared/notfound/notfound.component';
import { TimePickerComponent } from './shared/time-picker/time-picker.component';
import { AngularMaterialModule } from './angular-material.module';
import { ConfirmationDialogService } from './shared/confirmation-dialog/confirmation-dialog.service';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './shared/alert-msg-dialog/alert-dialog.component';
import { AlertDialogService } from './shared/alert-msg-dialog/alert-dialog.service';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './shared/loader/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { TrackOrderModule } from './track-order/track-order.module';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { BusinessSignupModule } from './signup/business-signup/business-signup.module';
import { TermsConditionsModalComponent } from './shared/layout/terms-conditions-modal/terms-conditions-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SupportComponent } from './support/support.component';

@NgModule({
  declarations: [
    AppComponent,

    ModalComponent,
    NotfoundComponent,
    TimePickerComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    LoaderComponent,
    TermsConditionsModalComponent,
    SupportComponent

  ],
  imports: [
    NoopAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HomeModule,
    AngularMaterialModule,
    MainHeaderModule, CommonFooterModule,
    BusinessModule,
    MenuModule,
    SignupModule,BusinessSignupModule,
    NgbModule,
    LoginModule,LoginAsGuestModule,
    TrackOrderModule,
    ReservationModule,
    Ng4LoadingSpinnerModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    BrowserModule, BrowserAnimationsModule,
    NgSelectModule,
    NgxStripeModule.forRoot("pk_test_F1vExIXe2dhGd461dVt2jahZ00DnurJ3q2"),
    NgxStarRatingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDcBfC068_4vUB6g792W-utxWpZExAKa6A',
      libraries: ['places']
    }),
    MatDialogModule,
    FormsModule,
  ],
  providers: [ConfirmationDialogService, AlertDialogService,
    LoaderService,DatePipe,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  entryComponents: [ModalComponent, TimePickerComponent, ConfirmationDialogComponent, AlertDialogComponent, LoaderComponent,TermsConditionsModalComponent, SupportComponent]
})
export class AppModule { }
