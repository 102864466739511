import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from '../shared/loader/loader.service';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
    constructor(public loaderService: LoaderService) {
      console.log("loader loader constructer");
     }

    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     console.log("loader loader call");
    //     this.loaderService.show();
    //     return next.handle(req).pipe(
    //         finalize(() => this.loaderService.hide())
    //     );
    // }


    removeRequest(req: HttpRequest<any>) {
      const i = this.requests.indexOf(req);
      if (i >= 0) {
        this.requests.splice(i, 1);

      }
      console.log(i, this.requests.length);
     // this.loaderService.isLoading.next(this.requests.length > 0);
     if(this.requests.length==0)
    this.loaderService.isLoading.next(false);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.requests.push(req);
      this.loaderService.isLoading.next(true);
      return Observable.create(observer => {
        const subscription = next.handle(req)
          .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => { this.removeRequest(req); observer.error(err); },
          () => { this.removeRequest(req); observer.complete(); });
        // teardown logic in case of cancelled requests
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      });
    }

}
