<div mdbModal #userModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">
          <mdb-icon far icon="user-circle"></mdb-icon>
          {{title}}
        </h4>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center" style="margin-top: 20px;text-align: center;">
          <div class="col-12">
            <br />
            <h5>{{pageProperty?.subTitle}}</h5>
            <h5 class="font-weight-bold">Login</h5>
            <p class="grey-text">If you have account with us , sign in with email addresss</p>
            <mat-card>
              <mat-card-content>
                <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">

                  <mat-error *ngIf="loginInvalid && isSubmitted">
                    The username and password were not recognised
                  </mat-error>


                  <div class="row">
                    <div class="col-md-3 coldesign ">
                      <mat-form-field class="example-full-width text-dark text-center">
                        <mat-select formControlName="countryCode" [(ngModel)]="toSelect" [value]="toSelect"
                          (change)="onChange($event.target.value)">
                          <input class="text-center searchbox" placeholder="search here"
                            (keyup)="onKey($event.target.value)">
                          <ng-container *ngFor="let country of countryData">
                            <!-- <mat-option    *ngIf ="country.shortName && country.phoneCode!=0" [value]="country.phoneCode"> +{{country?.phoneCode}}({{country.shortName|lowercase}})</mat-option> -->
                            <mat-option *ngIf="country.phoneCode!=0" [value]="country.phoneCode">
                              ({{country?.phoneCode }}) -
                              {{country?.displayName }}</mat-option>
                          </ng-container>
                        </mat-select>


                      </mat-form-field>

                    </div>
                    <div class="col-md-9 coldesign ">
                      <mat-form-field>
                        <input color="primary" matInput placeholder="  mobile number" formControlName="uname" required>
                        <mat-error>
                          Please provide a valid mobile number
                        </mat-error>
                      </mat-form-field>
                    </div>

                  </div>



                  <div class="row">
                    <div class="col-md-12 coldesign ">
                      <mat-form-field class="full-width-input">
                        <input matInput type="password" placeholder="Password" formControlName="password" required>
                        <mat-error>
                          Please provide a valid password
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <a>Forgot password?</a><br />
                  <button class="button">Login</button>


                </form>
              </mat-card-content>

              <h6 class="font-weight-bold">Are you new? <a class="text-danger"
                  [routerLink]="['/signup']">Signup</a></h6>

            </mat-card>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn class="relative waves-light " mdbWavesEffect style="background-color:#fc0d20;
          color:#fff;">Place Delivery Order Now</button>
      </div>
    </div>
  </div>
</div>