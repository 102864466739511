<h1 mat-dialog-title style="margin-bottom: 0px;">{{item.displayName}}
    <span *ngFor="let charImage of item.itemCharImages"
    style="padding:2px;">
    <img [src]="'https://images.nirvanaxp.com/images/menu_images/ItemChar/100_100/'+charImage"
        title="" alt="" height="16" width="16"
        style="cursor:pointer">
    </span>
</h1>

<!-- <input type="text" style="opacity: 0; z-index: -1; height: 0px;"> -->
<div mat-dialog-content>
    <div class="row p-0 m-0" #main>
        <img class="full-img mx-auto d-block" [src]="(item.imageName)?'https://images.nirvanaxp.com/images/menu_images/Item/650_650/'+item.imageName:'./assets/images/foodcover400.png'" alt="">
        <div class="col-md-12 p-0" style="text-align: justify;" *ngIf="item.description">
            {{item.description}}
        </div>
        <div class="col-md-12 pl-0 pr-0" *ngIf="item.itemAttributeTypeDisplayPacketList.length" #targetDiv>
            <!-- <input type="text" style="opacity: 0; z-index: -1; height: 0px; margin-top: -20px;"> -->

            <ul #listGroup class="list-group  pt-2"
                *ngFor="let itemAttributeType of item.itemAttributeTypeDisplayPacketList; let j = index">
                <div *ngIf="itemAttributeType.displayName!='Custom Text'">
                    <h6>{{itemAttributeType.displayName}}<span *ngIf="itemAttributeType.isRequired"
                           style="color: red;">(Required - <!-- <span style="font-size: 12px;">  -->Select
                                {{itemAttributeType.maxAllowed}})
                            <!-- </span> -->
                            </span></h6>
                                
                    <li class="list-group-item" style="padding: .4rem 0.7rem;min-height: 50px;cursor: pointer;"
                        *ngFor="let itemAttribute of itemAttributeType.itemAttributeDisplayPackets; let i = index" (click)="checkUncheck(j,i,$event,itemAttribute.multiSelect);">
                        
                        <div class="custom-control custom-radio" *ngIf="!itemAttribute.multiSelect">
                            <input [type]="itemAttribute.multiSelect?'checkbox':'radio'" class="custom-control-input"
                                [attr.id]="'attr'+j+i" [attr.name]="'itemAttributeRadio'+j" mdbInput  [checked] = "isEnabled(itemAttribute,itemAttributeType)"
                                (click)="addAttribute(itemAttribute,itemAttributeType,$event)">
                            <label class="custom-control-label" [attr.for]="'attr'+j+i" style="width: 100%;">
                                <span class="col-md-9 p-0" style="display: inline-block;">{{itemAttribute.displayName}}
                                    <span *ngFor="let charImage of itemAttribute.itemCharImages"
                                    style="padding:2px;">
                                    <img [src]="'https://images.nirvanaxp.com/images/menu_images/ItemChar/100_100/'+charImage"
                                        title="" alt="" height="16" width="16"
                                        style="cursor:pointer">                                        
                                </span>
                            </span>
                                <span class="col-md-3 p-0"
                                    style="float: right;float: right;text-align: right;">{{currency}}{{itemAttribute.sellingPrice| number:'1.2-2'}}</span></label>
                        </div>
                        <div *ngIf="itemAttribute.multiSelect">
                            <label class="checkbox" [attr.id]="'attr'+j+i">
                                <input type="checkbox" [attr.id]="'attribute'+j+i" mdbInput [checked] = "isEnabled(itemAttribute,itemAttributeType)" (click)="addAttribute(itemAttribute,itemAttributeType,$event);" />
                                <span class="default"></span>

                            </label>
                            <span class="col-md-7 p-0" style="float: left;">{{itemAttribute.displayName}}</span>
                            <span class="col-md-3 p-0"
                                style="float: right;text-align: right;">{{currency}}{{itemAttribute.sellingPrice| number:'1.2-2'}}</span>
                        </div>
                    </li>
                </div>
            </ul>
        </div>
        <div class="col-md-12 p-0  pt-2">
            <h6 style="font-weight: 500;">Special Instructions</h6>
            <textarea maxlength="256" rows="3" cols="40" class="rounded" 
                [(ngModel)]="cartItem.comment" style="width: 100%;"></textarea>
        </div>
        <div class="col-md-12 p-0">
            <div class="input-group">
                <input type="text" class="form-control bg-light" readonly [(ngModel)]="cartItem.quantity"
                    aria-describedby="button-addon4" style="height:50px;font-weight: bold;color: black;">
                <div class="input-group-append" id="button-addon4">
                    <button class="btn btn-dark rounded-left" type="button" style="margin: 0;padding: 10px 20px;"
                        (click)="modifyCart('minus')">
                        <i class="fa fa-minus" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-dark rounded-right" type="button" style="margin: 0;padding: 10px 20px;"
                        (click)="modifyCart('plus')">
                        <i class="fa fa-plus" aria-hidden="true"></i> </button>
                </div>
            </div>

        </div>
    </div>
</div>
<div mat-dialog-actions (click)="itemValid && showAlert()">
    <button class="btn ml-0 mr-0 mb-2 mt-2 pl-3 pr-3"
        style="width: 100%;height: 50px; font-size: 1.3em;text-transform: none;" [mat-dialog-close]="cartItem"
        [disabled]="itemValid">
        <span style="float: left;">{{currency}}{{cartItem.subTotal*cartItem.quantity| number:'1.2-2'}}</span>
        <span style="float: right;"><img src="assets/images/shopping-cart-solid.svg" style="width: 18px;filter: brightness(0) invert(1); margin-top:-4px;"> Add to cart</span>
    </button>
</div>