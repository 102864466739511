import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportComponent } from './support.component';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private dialog:MatDialog) { }

  openDialog():void {
    console.log("In Service for Support");
    this.dialog.open(SupportComponent, {
      // width: '400px',
    });
  }
}
