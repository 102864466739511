import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ReservationService, ReservationSlot, OrderService, LocationService, Country, RequestType, ContactPreference, UserService } from '../core';
import { MatDialog, MatDatepickerInputEvent, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageProperty, City } from '../core';
import { appServerConstants } from '../app-url-constants';
import { AlertDialogService } from '../shared/alert-msg-dialog/alert-dialog.service';
import { interval } from 'rxjs';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';
import { AnonymousSubject } from 'rxjs/internal/Subject';

declare let $: any;

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit, OnDestroy {
  pageProperty: PageProperty;
  gmt: string;
  businessId = 0;
  locationDetails: any;
  isHomePage: boolean = false;
  selectPartySize: any;
  partySizeArray: any[];
  slots: any;
  currentDateStr: string;
  currentDateStrForHtml: string;
  minDate: any;
  reserveForm: FormGroup;
  displaySourceGroup: any = [];
  selectedOrderGroup: any;
  events: any;
  selectedSlotId: any;
  cityId: any;
  isLoadReservationForm: boolean = false;
  btnDisabled = false;
  cancelStatusId=2;
  reservationStatusObject:any;
  usersId :any;
  isMint: boolean = false;

  reservationForm: FormGroup;
  isSubmitted: boolean;
  toSelect: any;
  countryData: Country[];
  filteredOptions: Country[];
  requestTypeArray: RequestType[];
  conatctPrefArray: ContactPreference[];
  reserveobj: any;
  timer: any = "00:00";
  subscribe: any;
  //reservationDate: any;
  slot: any;
  currentUser: any;
  localUserDetail: any;
  searchText: any;
  reservationId :any;


  date: Date;
  numberOfRatings: string;
  ratings: string;
  businessHours: any;
  businessStartTime: any;
  businessEndTime: any;
  reservationList:any = [];
  showReservation:any = [];
  businessTime: any;
  allSourceGroup:any;
  currentTab;
  hideBackButton:boolean;

  constructor(private reservationService: ReservationService,
    public dialog: MatDialog, private route: ActivatedRoute, private reservation: ReservationService,
    private orderService: OrderService, public formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private locationService: LocationService,
    private confirmationDialogService: AlertDialogService,
    private calendar: NgbCalendar,private titleService: Title
  ) {
    this.gmt = localStorage.getItem('gmt');
    this.businessId = this.route.snapshot.params.id;
    this.businessTime = JSON.parse(localStorage.getItem('businessTime'));
    this.currentDateStr = moment().utcOffset(this.gmt).format('YYYY-MM-DD');
    this.currentDateStrForHtml = moment().utcOffset(this.gmt).format('dddd Do MMMM, YYYY');
    this.isLoadReservationForm = false;
    this.loadCommanDetail();
    this.loadServices();
    this.ngOnInit();
    this.currentTab = "reservation";
    this.businessTime = JSON.parse(localStorage.getItem('businessTime'));
    this.businessStartTime = JSON.parse(localStorage.getItem('businessStartTime'));
    this.businessEndTime = JSON.parse(localStorage.getItem('businessEndTime'));
    this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    if ([105, 1622, 3176].includes(Number(this.locationDetails.location.businessId))) {
      this.isMint = true;console.log("Changing to green....");
    }
    this.hideBackButton = JSON.parse(localStorage.getItem('HideBackButton'));
    this.reservation.getReservationStatus(this.locationDetails.location.id).subscribe((res: any) => {
      this.reservationStatusObject = res.filter(source => {
        return source.name == 'Cancelled' ;     
      })

      this.cancelStatusId = this.reservationStatusObject[0].id;
  
      });

    const backgroundColor = this.isMint ? '#95ca4c' : '#fc0d20';
    const borderRadius = this.isMint ? '2rem 0' : '0.35rem';
    const leaf = this.isMint ? 'url("/assets/images/Mint_Leaf_image.png") center/contain no-repeat' : '';

    document.documentElement.style.setProperty('--background-color', backgroundColor);
    document.documentElement.style.setProperty('--border-Radius', borderRadius);
    document.documentElement.style.setProperty('--leaf', leaf);
  }

  changeTab(tab) {
    let locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    this.reservation.getReservationWithOrderIdByUserIdAndLocationId(locationDetails.location.id, this.usersId).subscribe((res: any) => {
      res = res.reverse(res.date);     // this.reservationList = res.reverse();
      this.reservationList=res.filter(source => {
        return source.reservationsStatusId !=this.cancelStatusId ;     
      })
      this.reservationList=this.reservationList;
      console.log(res);
    });
    this.currentTab = tab;
  }

  ngOnInit() {
    localStorage.removeItem('cartItems');
    localStorage.removeItem('orderHeader');
    localStorage.removeItem('idOfHoldingClientObj');
    localStorage.removeItem('required_pickuptime');
    localStorage.removeItem('slotId');
    localStorage.removeItem('countDown');
    localStorage.removeItem('startDate');
    localStorage.removeItem('currentReservation');

    this.loadReservationScreen();
    this.loadReservationForm();
    console.log('in ng init');
  }

  ngAfterViewInit() {
    if (localStorage.getItem("ReservationList")){
      this.currentTab = "reservationList";
      localStorage.removeItem("ReservationList");
    }
  }

  loadCommanDetail() {


    this.minDate = {
      year: parseInt(moment().utcOffset(this.gmt).format('YYYY')),
      month: parseInt(moment().utcOffset(this.gmt).format('MM')),
      day: parseInt(moment().utcOffset(this.gmt).format('DD'))
    }
    this.date = new Date(this.currentDateStr);

    //let city = JSON.parse(localStorage.getItem("SelectedCity"));
 this.cityId = JSON.parse(localStorage.getItem("selectedAreaId"));

    this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
    console.log(this.pageProperty);
 // this.titleService.setTitle(this.pageProperty.name);
    this.ratings = localStorage.getItem("ratings");
    this.numberOfRatings = localStorage.getItem("numberOfRatings");

    this.partySizeArray = [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: '5' },
      { id: 6, name: '6' },
      { id: 7, name: '7' },
      { id: 8, name: '8' },
      { id: 9, name: '9' },
      { id: 10, name: '10' },
    ];

    

  }

  loadServices() {

    let locationId = this.locationDetails.location.id;
    let now = moment().utcOffset(this.gmt).format('YYYY-MM-DD');
    let time = moment().utcOffset(localStorage.getItem('gmt')).format('HH:mm:ss');


    this.reservationService.getReservationSlotForDateAndLocationId(locationId, now, time)
      .subscribe((response: any) => {
        this.slots = (response);
        console.log(response);

      });


    this.orderService.getAllOrderSouceGroupDisplayByLocationId(locationId).subscribe((response: any) => {
      console.log(response);
      for (var orderSourceGroup of response) {
        console.log(orderSourceGroup);

        if (orderSourceGroup.name === "In Store") {
          orderSourceGroup.displayName = "Reserve and Preselect";
          this.displaySourceGroup.push(orderSourceGroup);
        }
      }
      console.log(this.displaySourceGroup);
    });


    this.countryData = JSON.parse(localStorage.getItem("countries"));
    this.filteredOptions = this.countryData;


    // this.conatctPrefArray = JSON.parse(localStorage.getItem("conatctPrefArray"));
    // this.requestTypeArray = JSON.parse(localStorage.getItem("requestTypeArray"));

    this.reservationService.getAllContactPreferenceByLocationId(this.locationDetails.location.id)
      .subscribe((response: any) => {
        this.conatctPrefArray = response;
        console.log(response);
        localStorage.setItem("conatctPrefArray", JSON.stringify(this.conatctPrefArray));
      });

    this.reservationService.getAllRequestTypeByLocationId(this.locationDetails.location.id)
      .subscribe((response: any) => {
        this.requestTypeArray = response;
        console.log('request array', response);
        localStorage.setItem("requestTypeArray", JSON.stringify(this.requestTypeArray));
      });

  }

  loadReservationScreen() {
    this.orderService.getAllLocationToFunctionForCustomerByLocationId(this.locationDetails.location.id)
    .subscribe((functions: any) => {
      console.log('functions are------------------------------------------', functions);
      this.showReservation = functions.filter(source => {
        return source.name == 'Online Reservation' && source.status == "A"
      })
    });
    this.reactiveForm();
  }


  reactiveForm() {
    this.reserveForm = this.formBuilder.group({

      reservationDate: [''],
      selPartySize: ['', Validators.required],

    })

    //

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.onDateSelect(event);
  }


  onDateSelect(event) {
    console.log("event ===================================================", event);
    console.log("event ===================================================", event.values);

    if (event.month < 10) {
      event.month = '0' + event.month;
    }
    if (event.day < 10) {
      event.day = '0' + event.day;
    }
    let now = event.year + '-' + event.month + '-' + event.day;
    let time = "";
    if (now == moment().utcOffset(this.gmt).format('YYYY-MM-DD'))
      time = moment().utcOffset(localStorage.getItem('gmt')).format('HH:mm:ss');
    else
      time = "00:00:00";

      
      // time = moment().utcOffset(localStorage.getItem('gmt')).format('HH:mm:ss');

    let locationId = this.locationDetails.location.id;
    this.currentDateStr = now;
    console.log(this.locationDetails);
    console.log(locationId);
    console.log(now);
    console.log(moment().utcOffset(this.gmt).format('YYYY-MM-DD'));
    console.log(time);
    this.reservationService.getReservationSlotForDateAndLocationId(locationId, now, time)
      .subscribe((response: any) => {
        if (response.displayMessage) {
          // alert("unhold"+ data.displayMessage);
          console.log(response);
        }
        else
          this.slots = (response);
        console.log(response);
      });

    let local = new Date(this.currentDateStr);
    this.date = local;
    var momentVariable = moment(this.currentDateStr, 'YYYY-MM-DD');
    this.currentDateStrForHtml = momentVariable.format('dddd Do MMMM, YYYY');





  }

  holdReservationSlot(slot) {
    this.selectedSlotId = slot.id;
    console.log('slot', slot)
    var slotObj = {
      'id': slot.id,
      'updatedBy': 1,

    };

    var reserveSlots = {
      "reservationSlot": slotObj
    };
    var holdslot = {
      "HoldReservationSlotPacket": reserveSlots
    };

    if (localStorage.getItem("idOfHoldingReserveSlotId")) {
      var previousslot = localStorage.getItem("idOfHoldingReserveSlotId");
      console.log(previousslot, "inside of unhold");
      // let slotId:any = previousslot.id;
      this.reservationService.unHoldReservationSlot(previousslot).subscribe((data: any) => {
        if (data.displayMessage) {
          // alert("unhold"+ data.displayMessage);
          console.log("unhold", data);
        }

      });
    }

    this.reservationService.holdReservationSlot(holdslot).subscribe((data: any) => {
      if (data.displayMessage) {
        alert("hold" + data.displayMessage);
      } else if (data > 0) {

        localStorage.setItem("idOfHoldingReserveSlotId", data);

        localStorage.setItem("required_reserve_time", this.currentDateStr);


        let hrTime = this.transform(slot.time);
        let slotObj = { id: slot.id, slotRemaining: slot.slotRemaining, time: slot.time, hrTime: hrTime, date: slot.date, partySize: this.selectPartySize };

        localStorage.setItem("idOfHoldingReserveSlotObj", JSON.stringify(slotObj));



        //  this.router.navigate(['reservation/reservationform', slot.id]);
        this.isLoadReservationForm = true;
        this.refreshReservationForm();

      }
    });
  }


  errorForm: any;
  onSlotHold(slot: any) {
    //let partySize = this.reserveForm.get['selPartySize'].value;
    //console.log(partySize);
    if (this.selectPartySize) {
      console.log("valid");
      console.log(this.selectPartySize);
      this.holdReservationSlot(slot);
      this.errorForm = false;
    }
    else {
      console.log("invalid");
      this.errorForm = true;
    }
    console.log(this.selectPartySize);
    console.log(this.reserveForm.errors);
    // let reserveobj  = {slotId:slot.id,reservationTime: slot.startTime, reservationDate:this.currentDate,partySize:this.partySize};
    // this.router.navigate(['reservation/reservationform', slot.id, reserveobj]);
  }


  transform(time: any): any {
    {

      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1);

        time[3] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12;
        let part = time[0];
        if (part.toString().length == 1) {
          part = "0" + part;
        }
        time[0] = part;             // Adjust hours

      }
      return time.join('');
    }
  }


  backDateClick(event) {
    var momentVariable = moment(this.currentDateStr, 'YYYY-MM-DD');
    let nextWeek = momentVariable.subtract(1, 'days');
    console.log(nextWeek);
    let comapare = (nextWeek.format('YYYY-MM-DD'));
    console.log(comapare);
    console.log(moment().utcOffset(this.gmt).format('YYYY-MM-DD'));
    if (!moment(comapare).isBefore(moment().utcOffset(this.gmt).format('YYYY-MM-DD'))) {
      this.btnDisabled = false;
      this.currentDateStrForHtml = (nextWeek.format('dddd Do MMMM, YYYY'));
      this.currentDateStr = (nextWeek.format('YYYY-MM-DD'));

      console.log(this.currentDateStr);


      this.reserveForm.get('reservationDate').setValue(this.currentDateStrForHtml);
      let locationId = this.locationDetails.location.id;

      let time;

      if (moment(comapare).isSame(moment().utcOffset(this.gmt).format('YYYY-MM-DD'))) {
        time = moment().utcOffset(localStorage.getItem('gmt')).format('HH:mm:ss');
      }
      else
        time = "00:00:00";

      this.reservationService.getReservationSlotForDateAndLocationId(locationId, this.currentDateStr, time)
        .subscribe((response: any) => {
          if (response.displayMessage) {
            // alert("unhold"+ data.displayMessage);
            console.log(response);
          } else {
            this.slots = (response);
          }
          console.log(response);
        });

    } else {
      this.btnDisabled = true;
      console.log("can not select previous date");
    }
  }


  forwardDateClick(event) {
    var momentVariable = moment(this.currentDateStr, 'YYYY-MM-DD');
    let nextWeek = momentVariable.add(1, 'days');
    let comapare = (nextWeek.format('YYYY-MM-DD'))
    this.currentDateStrForHtml = (nextWeek.format('dddd Do MMMM, YYYY'));
    this.currentDateStr = (nextWeek.format('YYYY-MM-DD'));
    console.log(this.currentDateStr);

    let locationId = this.locationDetails.location.id;
    this.reserveForm.get('reservationDate').setValue(this.currentDateStrForHtml);
    this.reservationService.getReservationSlotForDateAndLocationId(locationId, this.currentDateStr, "00:00:00")
      .subscribe((response: any) => {
        if (response.displayMessage) {
          // alert("unhold"+ data.displayMessage);
          console.log(response);
        }
        else
          this.slots = (response);
        console.log(response);
      });
    if (!moment(comapare).isBefore(moment().utcOffset(this.gmt).format('YYYY-MM-DD'))) {
      this.btnDisabled = false;
    }
    else {
      this.btnDisabled = true;
    }
    //  this.reserveForm.get('reservationDate').setValue(this.currentDateStr);
    console.log(this.reserveForm.value.reservationDate);
    event.stopPropagation();

  }

  /* from down reservation form code */

  reservationDateHtml: any;

  refreshReservationForm() {

    this.reservationDateHtml = new Date(this.currentDateStr);
    this.slot = JSON.parse(localStorage.getItem("idOfHoldingReserveSlotObj"));
    console.log(this.slot);

    var countDownDate = new Date();
    countDownDate.setMinutes(countDownDate.getMinutes() + 10);
    localStorage.countDown = countDownDate;
    localStorage.startDate = countDownDate;
    this.start_timer();

  }

  loadReservationForm() {
    this.reservationForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: [''],
        countryCode: ['', Validators.required],
        phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],

        email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],

        specialRequest: [''],
        contactPreference: [''],
        userComment: [''],

      } ,
    );

    let locationId = this.locationDetails.location.id;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));console.log(this.currentUser);
    const UserID = this.currentUser.globalUsersId ? this.currentUser.globalUsersId : this.currentUser.id;
    if (this.currentUser && this.currentUser.id != 0)
      this.userService.getUserByGlobalUsersId(UserID, locationId).subscribe((response: any) => {
        if (response == null || response.displayMessage) {
          // service to add user on local
          this.setDefaultCountryCode();
        } else {
          console.log(response);
          this.localUserDetail = {
            "id": response.id,
            "firstName": response.firstName,
            "lastName": response.lastName,
            "phone": response.phone,
            "email": response.email
          };

          this.usersId = response.id;

          this.reservationForm.get('firstName').setValue(response.firstName);
          this.reservationForm.get('lastName').setValue(response.lastName);
          this.reservationForm.get('email').setValue(response.email);

          var array = response.phone.split("-");
          let country = this.countryData.find(c => c.phoneCode == array[0]
          );
          this.toSelect = country;
          var phone = array[1];
          console.log(this.toSelect);
          this.reservationForm.get('phoneNumber').setValue(phone);

          this.toSelect = country;
          this.reservationForm.get('countryCode').setValue(this.toSelect);
          let locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
          this.reservation.getReservationWithOrderIdByUserIdAndLocationId(locationDetails.location.id, response.id).subscribe((res: any) => {
            res = res.reverse(res.date);
            this.reservationList=res.filter(source => {
              return source.reservationsStatusId !=this.cancelStatusId ;     
            })
           // this.reservationList=this.reservationList;
            console.log(res);
          });
          this.orderService.getAllOrderSouceGroupDisplayByLocationId(locationDetails.location.id).subscribe(async (response: any) => {
            console.log(response);
            this.allSourceGroup = response;
          });
        }
      });
    else {
      this.setDefaultCountryCode();
    }
  }



  onKey(value) {
    console.log(value);
    value = value.toLowerCase()
    if (!value) {

      console.log('fulldayta');

      this.countryData = this.filteredOptions;
    }
    else {
      console.log('dayta');
      this.countryData = this.filteredOptions;
      this.countryData = this.search(value);
    }
  }
  search(value: any): Country[] {
    let filter = value.toLowerCase();
    return this.countryData.filter(country => {
      return (
        country.displayName
          .toLowerCase()
          .includes(value) ||
        country.phoneCode.toString()
          .toLowerCase()
          .includes(value)
      );
    });
  }

  onsubmit() {


    if (this.reservationForm.invalid)
      return;
    else {
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        this.addReservationWithUserDetails();
      }
    }
  }

  cancel() {
    this.isLoadReservationForm = false;
    this.toggleView();
    // this.reservationForm.reset;
    // this.setDefaultCountryCode();
  }

  setDefaultCountryCode() {
    let city = JSON.parse(localStorage.getItem("SelectedCity"));
    console.log("selected countru", city);
    if (city != null) {
      let obj = this.countryData.find(c => c.id == city.countryId);
      this.toSelect = obj;
      console.log("selected countrddu", obj);
    }
    else {
      let obj = this.countryData.find(c => c.name == "UNITED STATES");
      this.toSelect = obj;
      console.log("selected cousssntrddu", obj);
    }
    this.reservationForm.get('countryCode').setValue(this.toSelect);
  }





  addReservationWithUserDetails() {


    var contactPreference1;
    if (this.reservationForm.value.contactPreference) {
      contactPreference1 = { "id": this.reservationForm.value.contactPreference };
    } else {
      let contactPref = this.conatctPrefArray.find(c => c.name == "None");
      console.log(contactPref);
      contactPreference1 = { "id": contactPref.id };
    }
    var requestType;
    if (this.reservationForm.value.specialRequest) {

      requestType = { "id": this.reservationForm.value.specialRequest };
    } else {
      let request = this.requestTypeArray.find(c => c.displayName == "None");
      console.log(request);
      requestType = { "id": request.id };
    }
    console.log(requestType);

    var comment = this.reservationForm.value.userComment;

    console.log(comment);
    var date = localStorage.getItem("required_reserve_time");
    var time = this.slot.time;
    console.log(date);

    let userId = 0;
    if (this.localUserDetail != null) {
      userId = this.localUserDetail.id;
    }
    let location = this.locationDetails.location;
    var reservation = {
      'lastName': this.reservationForm.value.lastName,
      'partySize': this.slot.partySize,
      'locationId': location.id,
      'usersId': userId,
      'requestType': requestType,
      'visitNumber': 1,
      'date': date,
      'contactPreference1': contactPreference1,
      'comment': comment,
      'time': time,
      'reservationSource': "Web",
      'phoneNumber': this.reservationForm.value.countryCode.phoneCode + "-" + this.reservationForm.value.phoneNumber,
      'email': this.reservationForm.value.email,
      // 'reservationsStatus': reservationsStatus,
      'reservationPlatform': "web",
      //'reservationsType': reservationsType,
      'createdBy': 1,
      'updatedBy': 1,
      'firstName': this.reservationForm.value.firstName,
      'referenceNumber': localStorage.getItem(appServerConstants.AUTH_TOKEN),
    };
    console.log(reservation);


    var reservationPostPacket = {
      "echoString": Number(localStorage.getItem("account_id")) + " ReservationService-add-1",
      "merchantId": localStorage.getItem("account_id"),
      "locationId": location.id,
      "clientId": localStorage.getItem('deviceId'),
      "idOfReservationHoldingClientObj": localStorage.getItem("idOfHoldingReserveSlotId"),
      "reservation": reservation
    };



    var reservationPostObject = {
      "ReservationPacket": reservationPostPacket
    };

    console.log(reservationPostObject);

    this.reservationService.addForWebsite(reservationPostObject).subscribe((res: any) => {
      this.isSubmitted = false;
      if (res == null || res.displayMessage) {
        alert(res.displayMessage);
        return;
      }
      localStorage.setItem('currentReservation', JSON.stringify(res));
      this.openConfirmationDialog();

    });


  }

  openConfirmationDialog() {
    this.confirmationDialogService.confirm('', 'Would you like to Pre-Select Menu for your reservation?', 2, "Yes", "No")
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed)
          this.getMenuPageForInstore();
        else {
          this.openReserveSuccessDialog();
          window.location.reload();
          localStorage.setItem("ReservationList","1");
          // this.isLoadReservationForm = false;
        }

      })
      .catch(() => {
        this.isLoadReservationForm = false;
        this.openReserveSuccessDialog();
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }


  getMenuPageForInstore() {
    this.router.navigate(['menu', localStorage.getItem("business_id"), "Reservation"]);
  }

  openReserveSuccessDialog() {
    let city = JSON.parse(localStorage.getItem("SelectedCity"));
    this.confirmationDialogService.confirm('', 'Your reservation has been confirmed', 1, "Ok")
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)

        // this.router.navigate(['/reservation-list']);

        this.router.navigate(['/reservation']).then((e) => {
          if (e) {
            console.log("Navigation is successful!");
          } else {
            console.log("Navigation has failed!");
          }
        });

      })
      .catch(() => {
        this.router.navigate(['/business', this.cityId]).then((e) => {
          if (e) {
            console.log("Navigation is successful!");
          } else {
            console.log("Navigation has failed!");
          }
        });

        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
  }



  start_timer() {
    var countDownDate: any = new Date(localStorage.startDate);
    countDownDate.setMinutes(countDownDate.getMinutes() + 10);
    var killSession: any = countDownDate;
    var countDown: any = new Date(localStorage.countDown);

    const source = interval(1000);
    this.subscribe = source.subscribe(() => {
      // Get todays date and time
      var now = new Date().getTime();
      // Find the distance between now an the count down date
      var distance = countDown - now;
      //Check if user is inactive for 30 Minutes
      var inactive_distance = killSession - now;
      if (inactive_distance < 0) {
        //            alert('Your Session has expired....')
        delete localStorage.countDown;
        this.subscribe.unsubscribe();
      }
      // Time calculations for days, hours, minutes and seconds
      let minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
      minutes = (minutes < 10) ? ("0" + minutes) : minutes;
      seconds = (seconds < 10) ? ("0" + seconds) : seconds;
      // Output the result in an element with id="demo"
      this.timer = minutes + ":" + seconds;

      // If the count down is over, write some text
      if (distance < 0) {
        this.subscribe.unsubscribe();
        this.timer = "00:00";
        // this.confirmationDialogService.confirm("", "Your Session is about to expire, Do you want to continue?", 2, "Yes")
        //   .then((confirmed) => {
        //     if (confirmed) {
        //       var countDownDate = new Date();
        //       countDownDate.setMinutes(countDownDate.getMinutes() + 10);
        //       localStorage.countDown = countDownDate;
        //       this.start_timer();
        //       this.holdSlot();
        //     } else {
        //       delete localStorage.countDown;
        //     }
        //   });

        this.confirmationDialogService.confirm("", "Your reservation slot has expired. Please pick a new time slot.", 1, "Ok")
          .then((confirmed) => {
            delete localStorage.countDown;
            this.isLoadReservationForm = false;
          });
      }
    });
  }


  holdSlot() {
    console.log('slot', this.slot)
    var slotObj = {
      'id': this.slot.id,
      'updatedBy': 1,

    };

    var reserveSlots = {
      "reservationSlot": slotObj
    };
    var holdslot = {
      "HoldReservationSlotPacket": reserveSlots
    };


    if (localStorage.getItem("idOfHoldingReserveSlotId")) {
      console.log(localStorage.getItem("idOfHoldingReserveSlotId"), "inside of unhold");
      let slotId: any = localStorage.getItem("idOfHoldingReserveSlotId");
      this.reservationService.unHoldReservationSlot(slotId).subscribe((data: any) => {
        if (data.displayMessage) {

          console.log("unhold", data);
        }

      });
    }
    this.reservationService.holdReservationSlot(holdslot).subscribe((data: any) => {
      if (data.displayMessage) {
        alert(data.displayMessage);
      }
      else if (data > 0) {

        localStorage.setItem("idOfHoldingReserveSlotId", data);

      }
    });
  }

  ngOnDestroy() {
    localStorage.removeItem("ReservationList");
    if (this.subscribe) this.subscribe.unsubscribe();
    document.documentElement.style.removeProperty('--background-color');
    document.documentElement.style.removeProperty('--border-Radius');
    document.documentElement.style.removeProperty('--leaf');
  }

  toggleView() {

    this.isLoadReservationForm = false;
  }

  orderNow(reservation) {
    console.log('current reservation', reservation)
    localStorage.setItem('currentReservation', JSON.stringify({ reservation: reservation }));
    this.router.navigate(['menu', localStorage.getItem("business_id"), "Reservation"]);
  }

  editOrder(reservation) {
    localStorage.setItem('currentReservation', JSON.stringify({ reservation: reservation }));
    this.orderService.getOnlineOrderById(reservation.orderId).subscribe(async (response: any) => {
      let orderHeader = response;
      console.log('order header', orderHeader)
      let cartItems = [];
      let subTotal = 0;
      if (orderHeader.onlineOrderDetailItems) {
        let orderDetailItems = orderHeader.onlineOrderDetailItems;
        for (let index in orderDetailItems) {
          let cartItem = {
            "id": orderDetailItems[index].itemsId,
            "displayName": orderDetailItems[index].name,
            "priceSelling": orderDetailItems[index].price,
            "subTotal": orderDetailItems[index].price,
            "quantity": orderDetailItems[index].itemsQty,
            "comment": orderDetailItems[index].specialRequest,
            "orderDetailId": orderDetailItems[index].id,
            "addedAttribute": [],
            "isItemRemoved": orderDetailItems[index].isItemRemoved
          };

          let attributes = [];
          let attr = orderDetailItems[index].onlineOrderDetailAttributes
          for (let i in attr) {
            let j
            attributes.forEach(function (x, index1) {
              if (x.itemAttributeTypeName === attr[i].name) {
                j = index1;
              }
            });
            let attribute = {
              "id": attr[i].attributeId,
              "displayName": attr[i].name,
              "orderDetailAttributeId": attr[i].id,
              "sellingPrice": attr[i].sellingPrice
            }
            cartItem.subTotal += attribute.sellingPrice
            if (j) {
              attributes[j].attr.push(attribute)
            } else {
              attributes.push({ name: attr[i].itemAttributeTypeName, attr: [attribute] })
            }
          }
          cartItem.addedAttribute = attributes;
          console.log('attributes are===', attributes)
          if (orderDetailItems[index].isItemRemoved == 0) {
            subTotal += cartItem.subTotal * orderDetailItems[index].itemsQty;
            cartItems.push(cartItem)
          }


        }
      }

      localStorage.setItem("cartItems", JSON.stringify({ subTotal: subTotal, items: cartItems }));
      // this.page = this.route.snapshot.params.page;
      let sourceGroup;
      for (let i in this.allSourceGroup) {
        if (this.allSourceGroup[i].orderSouceDisplayPackets && this.allSourceGroup[i].orderSouceDisplayPackets.id == orderHeader.orderSourceId) {
          sourceGroup = this.allSourceGroup[i];
          break;
        }
      }
      localStorage.setItem("sourceGroup", JSON.stringify(sourceGroup));
      localStorage.setItem("orderHeader", JSON.stringify({ onlineOrderHeader: orderHeader }))
      if (sourceGroup.name == "Pick Up" || sourceGroup.name == "Delivery") {
        this.router.navigate(['menu', localStorage.getItem("business_id"), "PickupDelivery"]);
      } else
        this.router.navigate(['menu', localStorage.getItem("business_id"), "Reservation"]);
      console.log("yayy cart items", cartItems, sourceGroup);
    });
  }

  searchMenu(searchText) {
    this.searchText = searchText;
  }

  isValidReservation(reservation) {
    let currentDateTime = moment().utcOffset(localStorage.getItem('gmt')).format('YYYY-MM-DD HH:mm:ss');
    if(moment(reservation.date+" "+reservation.time).isSameOrBefore(currentDateTime))
      return true;
    else if (reservation.orderStatusName == 'Order Paid')
      return true;
    else
      return false;  
  }

  isReservationSelected() {
   
      return false;  
  }

  cancel_reservation(reservationObj) {
    console.log('current reservation', reservation)
    localStorage.setItem('currentReservation', JSON.stringify({ reservation: reservationObj }));

    
   this.reservation.getReservationStatus(reservationObj.locationId).subscribe((res: any) => {
    this.reservationStatusObject = res.filter(source => {
      return source.name == 'Cancelled' ;     
    })
    this.cancelStatusId = this.reservationStatusObject[0].id;

    });

    var reservation = {
      'id': reservationObj.id,
      'lastName': reservationObj.lastName,
      'partySize': reservationObj.partySize,
      'locationId': reservationObj.locationId,
      'usersId': reservationObj.usersId,
      'requestType': reservationObj.requestType,
      'visitNumber': reservationObj.visitNumber,
      'date': reservationObj.date,
      'contactPreference1': reservationObj.contactPreference1,
      'comment': reservationObj.comment,
      'time': reservationObj.time,
      'reservationSource': reservationObj.reservationSource,
      'phoneNumber': reservationObj.phoneNumber,
      'email': reservationObj.email,
     'reservationsStatusId': this.cancelStatusId,
      'reservationPlatform': reservationObj.reservationPlatform,
      'reservationsType': reservationObj.reservationsType,
      'createdBy':reservationObj.createdBy,
      'updatedBy': reservationObj.updatedBy,
      'firstName':reservationObj.firstName,
      'referenceNumber': localStorage.getItem(appServerConstants.AUTH_TOKEN),
    };
    console.log(reservation);


    var reservationPostPacket = {
      "echoString": Number(localStorage.getItem("account_id")) + " ReservationService-add-1",
      "merchantId": localStorage.getItem("account_id"),
      "locationId": reservationObj.locationId,
      "clientId": localStorage.getItem('deviceId'),
      "idOfReservationHoldingClientObj": localStorage.getItem("idOfHoldingReserveSlotId"),
      "reservation": reservation
    };



    var reservationPostObject = {
      "ReservationPacket": reservationPostPacket
    };

    console.log(reservationPostObject);


    this.confirmationDialogService.confirm('', 'Do you want to cancel reservation?', 2, "Yes", "No")
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)
        if (confirmed){
          this.reservationService.updateReservationStatus(reservationPostObject).subscribe((res: any) => {
            this.isSubmitted = false;
            if (res == null || res.displayMessage) {
              alert(res.displayMessage);


              
              return;
            }

            let locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    this.reservation.getReservationWithOrderIdByUserIdAndLocationId(locationDetails.location.id, this.usersId).subscribe((res: any) => {
      res = res.reverse(res.date);     // this.reservationList = res.reverse();
      this.reservationList=res.filter(source => {
        return source.reservationsStatusId !=this.cancelStatusId ;     
      })
      this.reservationList=this.reservationList;
      console.log(res);
    });
            // localStorage.setItem('currentReservation', JSON.stringify(res));
            // this.openConfirmationDialog();
      
          });
        }
          
        else {
        //  this.openReserveSuccessDialog();
          this.isLoadReservationForm = false;
          
        }

      })
      .catch(() => {
        this.isLoadReservationForm = false;
       // this.openReserveSuccessDialog();
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });



   
    


  }


}
