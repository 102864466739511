<app-main-header [isHomePage]="isHomePage" [showSearch]="0" [countItem]="countItem" [pageName]="page"

 *ngIf="page == 'home'  || page == 'business'; else elseHeade"


></app-main-header>

<ng-template #elseHeade>
  <app-main-header [isHomePage]="isHomePage" [showSearch]="0"
  [isshowlogoimage]="'true'" [logoUrl]="
(locationDetails.location.imageUrl)?'https://images.nirvanaxp.com/images/location_images/250_250/'+locationDetails.location.imageUrl:'./assets/image/default-680x600.jpg'"

  ></app-main-header>

</ng-template>


<div class="container">
  <div class="col-md-12 py-4" style="position: absolute;padding:0 !important">
    <a href="javascript:history.back();" style="color:var(--background-color,red);position: absolute;left: -4%;padding: 0 !important;margin-top: 80px;"><mdb-icon style="font-size: 22px;" fas icon="arrow-left"></mdb-icon></a>
  </div>
  <br />
  <h5 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-size:36px;
  font-family: 'Poppins-Regular';
  margin-bottom: 20px;margin-top: 60px;">{{pageProperty?.subTitle}}</h5>
  <h5 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-family: 'Poppins-SemiBold';
  font-size:36px;
  margin-bottom: 20px;">{{updateAccount==1?'My Account':'Sign Up'}}</h5>
  <p style="
  text-align: center;
  color: #767676;
  width: 100%;
  font-family: 'Poppins-Regular';
  font-size:18px;" *ngIf="updateAccount==0">Creating an account will save your time at checkout and allow you <br>to access your order status
    and history.</p>
  <form [formGroup]="registrationForm" (ngSubmit)="onsubmitSignup()" class="form-horizontal">

    <mat-card>
      <mat-card-content>


        <div class="form-group">
          <div class="row">
            <div class="col-md-6 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" placeholder="First Name"
                  formControlName="firstName" required>
                <mat-error>
                  Please provide a valid data
                </mat-error>
              </mat-form-field>


            </div>

            <div class="col-md-6 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" placeholder="Last Name"
                  formControlName="lastName">
                <!-- <mat-error>
                  Please provide a valid data
                </mat-error> -->
              </mat-form-field>


            </div>
          </div>






          <div class="row">
            <div class="col-md-3 coldesign ">
              <mat-form-field class="example-full-width text-dark text-center">
                <mat-select style="height: 19px;" formControlName="countryCode" panelClass="matpanelClassUniveral"
                  disableOptionCentering [disabled]="updateAccount==1">
                  <input #searchInput class="text-center searchbox" placeholder="Search Here" (keyup)="onKey($event.target.value)">
                  <!-- <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
                    <mat-icon>close</mat-icon>
                  </button> -->
                  <mat-select-trigger>+{{registrationForm.controls.countryCode.value.phoneCode}}</mat-select-trigger>

                  <ng-container *ngFor="let country of countryData">
                    <!-- <mat-option    *ngIf ="country.shortName && country.phoneCode!=0" [value]="country.phoneCode"> +{{country?.phoneCode}}({{country.shortName|lowercase}})</mat-option> -->
                    <mat-option *ngIf="country.phoneCode!=0" [value]="country">
                      {{country?.displayName }}-({{country?.phoneCode }})</mat-option>

                  </ng-container>
                </mat-select>


              </mat-form-field>
            </div>

            <div class="col-md-9 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" type="tel" placeholder="Mobile Number"
                  formControlName="phoneNumber" required (change)="checkPhoneExist($event)" [readonly]="updateAccount==1">
                <mat-error>
                  Please provide a valid mobile number
                </mat-error>
                <div *ngIf="phoneExist" class="mat-form-field-subscript-wrapper" style="margin-top: 20px;">
                  <span class="mat-error">
                    Mobile Number already exist. <a [routerLink]="['/forgot-password', page ]" style="color:#ff0016;">Forgot
                      Password</a>
                  </span>
                </div>
              </mat-form-field>
            </div>
          </div>





          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" type="email" placeholder="Email address"
                  formControlName="email" required (change)="checkEmailExist($event)"[readonly]="updateAccount==1">
                <mat-error>
                  Please enter valid email address
                </mat-error>
                <div *ngIf="emailExist" class="mat-form-field-subscript-wrapper" style="margin-top: 20px;">
                  <span class="mat-error">
                    Email id already exist.
                  </span>
                </div>
              </mat-form-field>

            </div>

          </div>


          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field class="full-width-input">
                <input matInput type="password" style="height: 20px;" placeholder="Password" formControlName="password"
                  required>
                <mat-error>
                  Please provide a valid password
                </mat-error>
              </mat-form-field>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field class="full-width-input">
                <input matInput style="height: 20px;" type="password" placeholder="Confirm Password"
                  formControlName="confirmPassword" [errorStateMatcher]="matcher">
                <mat-error *ngIf="registrationForm.hasError('notSame')">
                  Password and Confirm Password should match
                </mat-error>


              </mat-form-field>
            </div>
          </div>




        </div>


      </mat-card-content>
      <mat-card-footer>
        <div class="row">
          <div class="form-group col-md-12" style="padding: 0px !important;">
            <button class="button"> {{updateAccount==1?'Update':'Sign Up'}} </button>

          </div>
        </div>
      </mat-card-footer>

    </mat-card>
  </form>
  <h6 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-family: 'Poppins-SemiBold';
  font-size:18px;
  font-style: normal;margin-bottom: 20px;"*ngIf="updateAccount==0">Already have an account with us ? <a
      style="color: #ff0e20;font-family: 'Poppins-SemiBold';" [routerLink]="['/login', page]" *ngIf="updateAccount==0">Login</a></h6>


</div>

<!-- <app-common-footer></app-common-footer> -->