import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef  } from '@angular/material/dialog';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  phoneNo:any;
  locationDetails:any;
  constructor(public dialogRef: MatDialogRef<SupportComponent>) { }

  ngOnInit() {
    console.log("Support Opened");
    this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));console.log(this.locationDetails.location);
    this.phoneNo = this.locationDetails.location.address.phone;
  }

  close() {
    this.dialogRef.close();
  }

}
