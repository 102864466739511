
export class ContactPreference  {
created:Date;
createdBy:Number;
updated: Date ;
updatedBy:Number;
status:String;
description:String;
name:String;
locationsId:Number;
displaySequence:Number;
displayName:String;
id:String;


}
