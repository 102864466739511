          // export const baseUrl = '/api'; //pune grofers ,vegas chow ,pune eat
          export const baseUrl = 'https://test.nirvanaxp.com:443/'; 
export const webSocketUrl = 'https://test.nirvanaxp.com:443/';

export const propertyId = 2; //1 las vegas 2-pune eats // 3- test pune grocer

export const AUTH_TOKEN = 'auth_token';
export const VERSION = 'V6';


export const appServerConstants = {
  SERVER_PATH: baseUrl,
  AUTH_TOKEN : 'auth_token',
  VERSION : 'V6',
  PROPERTY_ID : propertyId
};


export function setBaseUrl(accessTokenName: string) {
  appServerConstants.AUTH_TOKEN = accessTokenName;
}