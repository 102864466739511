import { Component, OnInit, ViewChild, OnDestroy, EventEmitter, Directive, ElementRef } from '@angular/core';
import { OrderService, LocationService, PageProperty } from '../core';
import { interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from '../modal/modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ScheduleModalService } from '../shared/layout/schedule-modal/schedule-modal.service';
import { CheckoutModalService } from '../shared/layout/checkout-modal/checkout-modal.service';
import { UserModalService } from '../shared/layout/user-modal/user-modal.service';
import * as moment from 'moment';
import { ConfirmationDialogService } from '../shared/confirmation-dialog/confirmation-dialog.service';
import { ThankYouModalService } from '../shared/layout/thank-you-modal/thank-you-modal.service';
import { CheckoutModalComponent } from '../shared/layout/checkout-modal/checkout-modal.component';
import { WebsocketService } from '../core/services/websocket.service';
import { AlertDialogService } from '../shared/alert-msg-dialog/alert-dialog.service';
import { Title, Meta } from '@angular/platform-browser';


interface DialogData {
  item: object;
}

declare let $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnDestroy {
  itemInfo;
  businessId = 0;
  cityId = 0;
  isHomePage: boolean = false;
  isMint: boolean = false;
  countItem = 0;
  ratings: any;
  pageProperty: PageProperty;
  numberOfRatings: any;
  @ViewChild(CheckoutModalComponent, { static: true }) childComponent: CheckoutModalComponent;

  scheduleWay = (Number(localStorage.getItem("idOfHoldingClientObj")) > 0) ? "Later" : "Now";
  cartItems: any = { subTotal: 0, items: [] };
  locationDetails: any;
  screenMode = "";
  displaySourceGroup: any = [];
  selectedOrderGroup: any;
  isUserLogin: any;
  currentDateTime: any;
  currentDate: any;
  currentTime: any;
  businessHours: any = [];
  businessStartTime: any;
  businessEndTime: any;
  searchText: string;
  reservation: any;
  currency: any;
  userSelectedReservation = "";
  businessTime: any;
  metaStr: string;
  gmt: any;
  currentTimeDisplay: string;
  time: any;
  hideBackButton:boolean;

  constructor(private locationService: LocationService, private orderService: OrderService, public dialog: MatDialog,
    private route: ActivatedRoute, private thankYouModalService: ThankYouModalService, public ws: WebsocketService,
    private scheduleModalService: ScheduleModalService, private checkoutModalService: CheckoutModalService, private alertDialogService: AlertDialogService,
    private userModalService: UserModalService, private router: Router,
    private confirmationDialogService: ConfirmationDialogService, private titleService: Title,
    private metaTagService: Meta) {

    this.gmt = localStorage.getItem('gmt');
    if (this.scheduleWay == "Later")
      this.start_timer()

    if (localStorage.getItem("cartItems") && localStorage.getItem("cartItems") != undefined) {
      this.cartItems = JSON.parse(localStorage.getItem("cartItems"));
      var countItem = 0;
      for (var i = 0; i < this.cartItems.items.length; i++) {
        countItem = countItem + this.cartItems.items[i].quantity;
      }
      this.countItem = countItem;
    }
    this.businessId = this.route.snapshot.params.id;
    if ([105, 1622, 3176].includes(Number(this.businessId))) {
      this.isMint = true;console.log("Changing to green....");
    }
    this.hideBackButton = JSON.parse(localStorage.getItem('HideBackButton'));
    this.cityId = JSON.parse(localStorage.getItem("selectedAreaId"));
    this.currency = localStorage.getItem('currency');

    this.screenMode = this.route.snapshot.params.mode;
    this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));
    //localStorage.setItem('businessHours',JSON.stringify(this.locationDetails.businessHours));
    console.log(this.locationDetails);
    this.initiateSocket();

    this.isUserLogin = localStorage.getItem('isUserLogin');
    this.ratings = localStorage.getItem("ratings");
    this.numberOfRatings = localStorage.getItem("numberOfRatings");

    this.businessTime = JSON.parse(localStorage.getItem('businessTime'));
    this.businessStartTime = JSON.parse(localStorage.getItem('businessStartTime'));
    this.businessEndTime = JSON.parse(localStorage.getItem('businessEndTime'));
    this.getCurrentTime();
    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
 // this.titleService.setTitle(this.pageProperty.name);

    const backgroundColor = this.isMint ? '#95ca4c' : '#fc0d20';
    const borderRadius = this.isMint ? '2rem 0' : '0.35rem';
    const leaf = this.isMint ? 'url("/assets/images/Mint_Leaf_image.png") center/contain no-repeat' : '';

    document.documentElement.style.setProperty('--background-color', backgroundColor);
    document.documentElement.style.setProperty('--border-Radius', borderRadius);
    document.documentElement.style.setProperty('--leaf', leaf);

    this.metaStr = this.pageProperty.name;
    if (this.screenMode === "PickupDelivery") {
      console.log("biz time---------------------------------------------------------------------", this.businessTime);
      this.orderService.getAllCategoriesForWebsite(this.businessId).subscribe((response: any) => {
        console.log(response);
        this.itemInfo = response;
        var catzero = this.itemInfo[0].id;
        for (var key in this.itemInfo) {
          this.metaStr = this.metaStr + ", " + this.itemInfo[key].displayName;
          for (var itemkey in this.itemInfo[key].itemDisplayList) {
            this.metaStr = this.metaStr + ", " + this.itemInfo[key].itemDisplayList[itemkey].displayName;
          }
          for (var subcatkey in this.itemInfo[key].subCategoryDisplayWebsite) {
            this.metaStr = this.metaStr + ", " + this.itemInfo[key].subCategoryDisplayWebsite[subcatkey].displayName;
            for (var subitemkey in this.itemInfo[key].subCategoryDisplayWebsite[subcatkey].itemDisplayList) {
              this.metaStr = this.metaStr + ", " + this.itemInfo[key].subCategoryDisplayWebsite[subcatkey].itemDisplayList[subitemkey].displayName;
            }
          }
        }

        this.metaTagService.addTags([
          { name: 'keywords', content: this.metaStr }
        ]);
        setTimeout(function () {
          $('.slider2').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            initialSlide: 0,
            variableWidth: false,
            prevArrow: $('.scroller-left'),
            nextArrow: $('.scroller-right'),
          });
          var lastId,
            topMenu = $("#mainNav"),
            topMenuHeight = topMenu.outerHeight() + 1,
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
              var item = $($(this).attr("href"));
              if (item.length) { return item; }
            });
          // Bind to scroll
          $(window).scroll(function () {
            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight + 155;
            // Get id of current scroll item
            var cur = scrollItems.map(function () {
              if ($(this).offset().top < fromTop)
                return this;
            });
            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";
            if (lastId !== id) {
              lastId = id;
              // Set/remove active class
              menuItems.removeClass("active")
                .filter("[href='#" + id + "']").addClass("active");
            }
            if (!id) {
              menuItems.removeClass("active")
                .filter("[href='#itemSrc" + catzero + "']").addClass("active");
            }
            var activemenuItems = topMenu.find("a.active"); console.log("A",activemenuItems);
            var slickIndex = activemenuItems.closest(".slick-slide").attr("data-slick-index"); console.log("B",slickIndex);
            $('.slider2').slick('slickGoTo', slickIndex)
          });


        }, 500);

      });
    } else {
      this.orderService.getAllCategoriesForWebsiteContactlessMenu(this.businessId).subscribe((response: any) => {
        console.log(response);
        this.itemInfo = response;
        var catzero = this.itemInfo[0].id;

        for (var key in this.itemInfo) {
          this.metaStr = this.metaStr + ", " + this.itemInfo[key].displayName;
          for (var itemkey in this.itemInfo[key].itemDisplayList) {
            this.metaStr = this.metaStr + ", " + this.itemInfo[key].itemDisplayList[itemkey].displayName;
          }
          for (var subcatkey in this.itemInfo[key].subCategoryDisplayWebsite) {
            this.metaStr = this.metaStr + ", " + this.itemInfo[key].subCategoryDisplayWebsite[subcatkey].displayName;
            for (var subitemkey in this.itemInfo[key].subCategoryDisplayWebsite[subcatkey].itemDisplayList) {
              this.metaStr = this.metaStr + ", " + this.itemInfo[key].subCategoryDisplayWebsite[subcatkey].itemDisplayList[subitemkey].displayName;
            }
          }
        }
        setTimeout(function () {
          $('.slider2').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            initialSlide: 0,
            variableWidth: false,
            prevArrow: $('.scroller-left'),
            nextArrow: $('.scroller-right'),
          });
          var lastId,
            topMenu = $("#mainNav"),
            topMenuHeight = topMenu.outerHeight() + 1,
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
              var item = $($(this).attr("href"));
              if (item.length) { return item; }
            });
          // Bind to scroll
          $(window).scroll(function () {
            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight + 155;
            // Get id of current scroll item
            var cur = scrollItems.map(function () {
              if ($(this).offset().top < fromTop)
                return this;
            });
            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";
            if (lastId !== id) {
              lastId = id;
              // Set/remove active class
              menuItems.removeClass("active")
                .filter("[href='#" + id + "']").addClass("active");
            }
            if (!id) {
              menuItems.removeClass("active")
                .filter("[href='#itemSrc" + catzero + "']").addClass("active");
            }
            var activemenuItems = topMenu.find("a.active"); console.log("A",activemenuItems);
            var slickIndex = activemenuItems.closest(".slick-slide").attr("data-slick-index"); console.log("B",slickIndex);
            $('.slider2').slick('slickGoTo', slickIndex)
          });


        }, 500);
        this.metaTagService.addTags([
          { name: 'keywords', content: this.metaStr }
        ]);
      });
    }

    this.orderService.getAllOrderSouceGroupDisplayByLocationId(this.locationDetails.location.id).subscribe((response: any) => {
      console.log("order source-----------------------------------------", response);
      this.orderService.getAllLocationToFunctionForCustomerByLocationId(this.locationDetails.location.id)
        .subscribe((functions: any) => {
          console.log('functions are------------------------------------------', functions);
          if (localStorage.getItem("sourceGroup")){
          this.selectedOrderGroup = JSON.parse(localStorage.getItem("sourceGroup"));
          localStorage.removeItem("sourceGroup")
          }
          for (var orderSourceGroup of response) {

            if (this.screenMode === "PickupDelivery") {
              if (orderSourceGroup.name === "Pick Up") {
                let sourceArray = functions.filter(source => {
                  return source.name == 'Online Takeout' && source.status == "A"
                })
                if (sourceArray.length > 0) {
                  if (!this.selectedOrderGroup)
                    this.selectedOrderGroup = orderSourceGroup;
                  this.displaySourceGroup.push(orderSourceGroup);

                }
              }
              if (orderSourceGroup.name === "Delivery") {
                let sourceArray = functions.filter(source => {
                  return source.name == 'Online Delivery' && source.status == "A"
                })
                if (sourceArray.length > 0) {
                  if (!this.selectedOrderGroup)
                    this.selectedOrderGroup = orderSourceGroup;
                  this.displaySourceGroup.push(orderSourceGroup);
                }
              }
            }
            if (this.screenMode === "Menu") {
              let sourceArray = functions.filter(source => {
                return source.name == 'Online Walk In' && source.status == "A"
              });
              if (sourceArray.length > 0) {
                if (orderSourceGroup.name === "In Store") {
                  orderSourceGroup.displayName = "Contactless Menu";
                  this.displaySourceGroup.push(orderSourceGroup);
                  this.selectedOrderGroup = orderSourceGroup;
                }
              }
            }
            if (this.screenMode === "Reservation") {
              let sourceArray = functions.filter(source => {
                return source.name == 'Online Reservation' && source.status == "A"
              });
              if (sourceArray.length > 0) {
                if (orderSourceGroup.name === "In Store") {
                  orderSourceGroup.displayName = "Reserve & Pre-Select";
                  this.displaySourceGroup.push(orderSourceGroup);
                  this.selectedOrderGroup = orderSourceGroup;
                }
              }
            }
          }
        });
      console.log(this.displaySourceGroup);
    });



  }

  isBusinessClosed: boolean = true;
  getCurrentTime() {
    if (this.screenMode == "Reservation") {
      this.reservation = JSON.parse(localStorage.getItem('currentReservation'));
      var dt = this.reservation.reservation.date.split('-');
      var tt = this.reservation.reservation.time.split(':');
      var date = new Date(dt[0], dt[1], dt[2], tt[0], tt[1], tt[2]); // 1 Jan 2011, 00:00:00

      this.currentDateTime = moment(date).format('YYYY-MM-DD HH:mm:ss');
      this.currentTime = moment(date).format('hh:mm a');
      var resDate = moment(date).format('DD MMM, YYYY');
      var resTime = moment(date).format('hh:mma');
      this.userSelectedReservation = "Your reservation on " + resDate + " at " +
        resTime + " for party #" + this.reservation.reservation.partySize;

    } else if( this.scheduleWay == "Later" && localStorage.getItem("required_pickuptime") != null){
      this.currentDateTime = moment.utc(localStorage.getItem("required_pickuptime"));
      let str = moment.utc(this.currentDateTime);
      this.currentDate = moment(str).format('DD MMM, YYYY');
      this.currentTime = moment(str).format('hh:mm a');
    }else {
      this.currentDateTime = moment().utcOffset(localStorage.getItem('gmt')).format('YYYY-MM-DD HH:mm:ss');
      let str = moment.utc(this.currentDateTime);
      this.currentDate = moment(str).format('DD MMM, YYYY');
      this.currentTime = moment(str).format('hh:mm a');
    }



    for (let i in this.businessTime) {
      var format = 'hh:mm a'
      let str = this.businessTime[i].split('-');
      // var time = moment() gives you current time. no format required.
      var time = moment(this.currentTime, format),
        beforeTime = moment(str[0], format),
        afterTime = moment(str[1], format);
        if (time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime)) {
        this.isBusinessClosed = false;
      }
    }
    console.log('business time', this.businessTime, this.isBusinessClosed)
    let businessHours = JSON.parse(localStorage.getItem("businessHours"));
    var dayofWeek = parseInt(moment().utcOffset(this.gmt).format('e')) + 1;
    console.log('business business hr and week', businessHours, dayofWeek);

    if (this.isBusinessClosed) {

      let timeString = "";

      var currentTime = moment().utcOffset(this.gmt).format('HH:mm:ss');

      for (let index = 0; index < businessHours.length; index++) {
        const element = businessHours[index];
        if (dayofWeek == element.day.id) {
          if (element.isClosed != 1) {

            let diff = moment(element.timeFrom, 'HH:mm:ss').diff(moment(currentTime, 'HH:mm:ss'))
            var d = moment.duration(diff);
            console.log("time dif ---- " + d);
            let difftemp = Math.floor(d.asHours()) + moment.utc(diff).format(":mm");
            console.log("time mint dif ---- " + element.timeFrom);

            if (parseInt(d + '') > 0) {
              let time = moment.utc(moment().utcOffset(this.gmt).format('YYYY-MM-DD') + " " + element.timeFrom).format('HH:mm');
              timeString = "Opens at " + moment(time, ["HH.mm"]).format("hh:mm a");;
              break;

            }
          }

        }
      }

      if (!timeString || timeString == "") {
        console.log("in if cond");

        //if current day don't have hours then check next day
        if (dayofWeek == 8) {
          dayofWeek = 1;
        } else {
          dayofWeek = dayofWeek + 1
        }

        let isShowDate = false;
        for (let index = 0; index < businessHours.length; index++) {

          const element = businessHours[index];
          console.log("in if cond2 ------------------------", element, dayofWeek);

          if (dayofWeek == element.day.id) {
            if (element.isClosed != 1) {
              let time = moment.utc(moment().utcOffset(this.gmt).format('YYYY-MM-DD') + " " + element.timeFrom).format('HH:mm');
              if (isShowDate) {
                timeString = "Opens at " + "(" + element.day.name + ") " + moment(time, ["HH.mm"]).format("hh:mm a");;
              } else {
                timeString = "Opens at " + moment(time, ["HH.mm"]).format("hh:mm a");;
              }

              break;

            } else {
              isShowDate = true;
              if (dayofWeek == 8) {
                dayofWeek = 1;
              } else {
                dayofWeek = dayofWeek + 1;
              }
            }





          }
        }



      }

      console.log(timeString);
      this.currentTimeDisplay = "Online Ordering is currently not available! " + timeString

      if (this.businessId == 3176) {
        this.currentTimeDisplay = "Online ordering currently not available. Please call the restaurant at (702) 454-4240 in the meantime. Thank you!"
      } 

    }
  }


  initiateSocket() {
    this.ws.create().subscribe(allowed => {

      var data = JSON.parse(allowed.data);
      console.log('socket message', data)
    },
      (error) => { },
      () => console.log('Completed')
    );
  }

  ngOnDestroy() {
    if (this.subscribe) this.subscribe.unsubscribe();
    document.documentElement.style.removeProperty('--background-color');
    document.documentElement.style.removeProperty('--border-Radius');
    document.documentElement.style.removeProperty('--leaf');
  }
  openDialog(item, categoryName): void {
    if (!this.isBusinessClosed || this.scheduleWay == "Later") {
      item.categoryName = categoryName;
      item.quantity = 1;
      const dialogRef = this.dialog.open(ModalComponent, {
        width: '300px',
        data: { 'item': item }
      });

      dialogRef.afterClosed().subscribe(result => {
        // this.email = result;
        if (result) {
          this.cartItems['items'].push(result);
          this.cartItems['subTotal'] += result.subTotal * result.quantity;
          console.log('modal returned', this.cartItems, result)
          localStorage.setItem("cartItems", JSON.stringify(this.cartItems));

          this.countItem = this.countItem + result.quantity;
        }

      });
    } else {
      this.alertDialogService.confirm('', this.currentTimeDisplay, 1, "Close")
        .then((confirmed) => {
        })
        .catch(() => {
        });
    }

  }

  searchMenu(searchText) {
    this.searchText = searchText;
  }


  scrollToCategory(i,event: MouseEvent) {
    event.preventDefault();
    console.log(i);
    $([document.documentElement, document.body]).animate({
      scrollTop: $("#itemDiv" + i).offset().top - 120
    }, 2000);
    $('.category-scrollbar .active').attr('class', '');
    $('#cat' + i).attr('class', 'active')
    return false
  }

  openScheduleModal(id: string) {
     console.log("time=========================================", this.isBusinessClosed);
   
  
    this.scheduleModalService.open(id);

  }

  closeScheduleModal(id: string) {
    console.log("time=========================================", localStorage.getItem("required_pickuptime"));
     if (localStorage.getItem("required_pickuptime") != undefined) {
      this.scheduleWay = "Later";
      var str = moment.utc(localStorage.getItem("required_pickuptime"));
      this.currentDateTime = moment(str).format('YYYY-MM-DD HH:mm:ss');
      localStorage.setItem("scheduleDateTimeForLater",this.currentDateTime);
      this.currentDate = moment(str).format('DD MMM, YYYY');
      this.currentTime = moment(str).format('hh:mm a');

      this.isBusinessClosed = false;
      if (this.subscribe) this.subscribe.unsubscribe();
      this.start_timer();
    }
  }

  selectSourceGroup(sourceGroup) {
    this.selectedOrderGroup = sourceGroup;
  }
  openThankYouModal(id: string) {
    this.thankYouModalService.open(id);
  }

  closeThankYouModal(id: string) {
    // this.thankYouModalService.open(id);
  }

  changeWay() {
    localStorage.removeItem("idOfHoldingClientObj")
    if (this.subscribe) this.subscribe.unsubscribe();
    this.scheduleWay = "Now";
    this.currentDateTime = moment().utcOffset(localStorage.getItem('gmt')).format('YYYY-MM-DD HH:mm:ss');
    let str = moment.utc(this.currentDateTime);
    this.currentDate = moment(str).format('DD MMM, YYYY');
    this.currentTime = moment(str).format('hh:mm a');
  }
  closeCheckoutModal(res) {
    if (this.subscribe) this.subscribe.unsubscribe();
    this.timer = "00:00";
    if (localStorage.getItem("cartItems") && localStorage.getItem("cartItems") != undefined) {
      this.cartItems = JSON.parse(localStorage.getItem("cartItems"))
    } else {
      this.cartItems = { subTotal: 0, items: [] };
      this.countItem = 0;
    }
    console.log('items are', this.cartItems)
    if (!res) {
      this.openThankYouModal('thank-you-modal');
    }

  }
  stopTimer(event) {
    if (this.subscribe) this.subscribe.unsubscribe();
    this.timer = "";
  }
  openCheckkoutModal(id: string) {
    let cartCount = 0;
    for (let i in this.cartItems.items) {
      if (this.cartItems.items[i].isItemRemoved == 0)
        cartCount++
    }
    if (cartCount > 0 && (!this.isBusinessClosed || this.scheduleWay == "Later") && this.displaySourceGroup.length > 0) {
      this.childComponent.error.isDisplay = false;
      this.childComponent.error.isPaymentInProcess = false;
      this.childComponent.error.discountCode = "";
      this.childComponent.error.showEditPayment = false;
      this.childComponent.error.showEditTime = false;

      if (this.isUserLogin == '1') {
        if (this.selectedOrderGroup.minimumOrderAmount) {
          let temp = this.cartItems['subTotal'].toFixed(2);
          let temp1 = this.selectedOrderGroup.minimumOrderAmount.toFixed(2);

          if (parseFloat(temp) < parseFloat(temp1)) {
            let text = "Minimum order amount should be " + this.currency + " " + this.selectedOrderGroup.minimumOrderAmount;
            this.alertDialogService.confirm('', text, 1, "Close")
              .then((confirmed) => {
                return;
              });
          } else {
            if (this.selectedOrderGroup.showAvgWaitTime && this.scheduleWay !='Later') {
              // let text = (this.selectedOrderGroup.name == "Delivery") ? "Your Order will be Delivered in " : "Your order will ready in ";
              // this.alertDialogService.confirm('', text + this.selectedOrderGroup.avgWaitTimeDisplayName, 1, "Close")
              //   .then((confirmed) => {
              //     this.checkoutModalService.open(id);
              //   })
              //   .catch(() => {
              //     this.checkoutModalService.open(id);
              //   });
                this.checkoutModalService.open(id);
            }else if(this.selectedOrderGroup.showAvgWaitTime  && this.scheduleWay =='Later'){
              this.alertDialogService.confirm('', 'Your Order is Scheduled On \n' + this.currentDate +' '+this.currentTime, 1, "Close")
              .then((confirmed) => {
                this.checkoutModalService.open(id);
              })
              .catch(() => {
                this.checkoutModalService.open(id);
              });
            } else
              this.checkoutModalService.open(id);
          }
        } else {
          if (this.selectedOrderGroup.showAvgWaitTime && this.scheduleWay !='Later') {
            // let text = (this.selectedOrderGroup.name == "Delivery") ? "Your Order will be Delivered in " : "Your order will ready in ";
            // this.alertDialogService.confirm('', text + this.selectedOrderGroup.avgWaitTimeDisplayName, 1, "Close")
            //   .then((confirmed) => {
            //     this.checkoutModalService.open(id);
            //   })
            //   .catch(() => {
            //     this.checkoutModalService.open(id);
            //   });
            this.checkoutModalService.open(id);
          } else if(this.selectedOrderGroup.showAvgWaitTime  && this.scheduleWay =='Later'){
            this.alertDialogService.confirm('', 'Your Order is Scheduled On \n' + this.currentDate +' '+this.currentTime, 1, "Close")
            .then((confirmed) => {
              this.checkoutModalService.open(id);
            })
            .catch(() => {
              this.checkoutModalService.open(id);
            });
          }else
            this.checkoutModalService.open(id);
        }
      } else {
        this.router.navigate(['loginasguest', this.screenMode]);
      }
    } else {
      if (this.displaySourceGroup.length == 0) {
          this.alertDialogService.confirm('', 'Business Online Ordering is currently not available! Please check again later.', 1, "Close")
            .then((confirmed) => {
            })
            .catch(() => {
            });
      } else {
        if (this.isBusinessClosed) {
          this.alertDialogService.confirm('', this.currentTimeDisplay, 1, "Close")
            .then((confirmed) => {
            })
            .catch(() => {
            });
        }
      }
    }
  }
  isEveryItemInCartValid(): boolean {
    return this.cartItems.items.some(item => item.quantity > 0);
  }

  modifyCart(index, action) {
    switch (action) {
      case "plus":
        this.cartItems['items'][index].quantity += 1;
        break;
      case "minus":
        if (this.cartItems['items'][index].quantity > 1) {
          this.cartItems['items'][index].quantity -= 1;
        } else if (this.cartItems['items'][index]['orderDetailId'] == 0) {
          this.cartItems['items'].splice(index, 1);
        } else {
          this.cartItems['items'][index]['isItemRemoved'] = 1;
          this.cartItems['items'][index].quantity -= 1;
        }
        break;
      case "remove":
        if (this.cartItems['items'][index]['orderDetailId'] == 0) {
          this.cartItems['items'].splice(index, 1);
        } else {
          this.cartItems['items'][index]['isItemRemoved'] = 1;
        }
        break;
    }
    console.log(this.cartItems['items'])

    var subTotal = 0;
    for (let i in this.cartItems['items']) {
      if (this.cartItems['items'][i]['isItemRemoved'] == 0) {
        subTotal += this.cartItems['items'][i].subTotal * this.cartItems['items'][i].quantity;
      }

    }
    this.cartItems['subTotal'] = subTotal;
    var countItem = 0;
    for (let i = 0; i < this.cartItems.items.length; i++) {
      countItem = countItem + this.cartItems.items[i].quantity;
    }
    this.countItem = countItem;
    localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
  }

  refreshCart(res) {
    if (this.subscribe) this.subscribe.unsubscribe();
    this.timer = "00:00"
    this.cartItems = (localStorage.getItem("cartItems") != undefined) ? JSON.parse(localStorage.getItem("cartItems")) : { subTotal: 0, items: [] };
    console.log('items are', this.cartItems)
    if (!res) {
      this.openThankYouModal('thank-you-modal');
    }
  }

  clearCart(e) {
    this.countItem = 0;
    if (this.subscribe) this.subscribe.unsubscribe();
    this.timer = "00:00"
    this.cartItems = (localStorage.getItem("cartItems") != undefined) ? JSON.parse(localStorage.getItem("cartItems")) : { subTotal: 0, items: [] };
    this.scheduleWay = "Now";
    this.currentDateTime = moment().utcOffset(localStorage.getItem('gmt')).format('YYYY-MM-DD HH:mm:ss');
    this.currentDate = moment().utcOffset(localStorage.getItem('gmt')).format('DD MMM, YYYY');
    this.currentTime = moment().utcOffset(localStorage.getItem('gmt')).format('hh:mm a');
  }


  timer: any = "00:00";
  subscribe: any;
  start_timer() {
    var countDownDate: any = new Date(localStorage.startDate);
    countDownDate.setMinutes(countDownDate.getMinutes() + 10);
    var killSession: any = countDownDate;
    var countDown: any = new Date(localStorage.countDown);

    const source = interval(1000);
    this.subscribe = source.subscribe(() => {
      // Get todays date and time
      var now = new Date().getTime();
      // Find the distance between now an the count down date
      var distance = countDown - now;
      //Check if user is inactive for 30 Minutes
      var inactive_distance = killSession - now;
      if (inactive_distance < 0) {
        //            alert('Your Session has expired....')
        delete localStorage.countDown;
        this.subscribe.unsubscribe();
      }
      // Time calculations for days, hours, minutes and seconds
      let minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
      minutes = (minutes < 10) ? ("0" + minutes) : minutes;
      seconds = (seconds < 10) ? ("0" + seconds) : seconds;
      // Output the result in an element with id="demo"
      this.timer = minutes + ":" + seconds;
      console.log('timer started', this.timer)
      // If the count down is over, write some text 
      if (distance < 0) {
        this.subscribe.unsubscribe();
        this.timer = "00:00";
        this.confirmationDialogService.confirm("Confirm", "Your Session is about to expire, Do you want to continue?")
          .then((confirmed) => {
            if (confirmed) {
              var countDownDate = new Date();
              countDownDate.setMinutes(countDownDate.getMinutes() + 10);
              localStorage.countDown = countDownDate;
              this.start_timer();
              this.holdSlot();
            } else {
              delete localStorage.countDown;
            }
          });
      }
    });
  }

  holdSlot() {
    var slotObj = {
      'id': localStorage.slotId,
      'updatedBy': 1,
      'createdBy': 1,
      'status': 'A',
      'slotTime': 0,
      'slotInterval': 0,
      'locationId': 0,
      'created': 0,
      'updated': 0,
    };
    var shiftSlots = {
      "shiftSlots": slotObj
    };
    var holdslot = {
      "HoldShiftSlotPacket": shiftSlots
    };
    if (localStorage.getItem("idOfHoldingClientObj") != "undefined" && localStorage.getItem("idOfHoldingClientObj")) {
      this.locationService.unHoldShiftSlotForClientId(localStorage.getItem("idOfHoldingClientObj")).subscribe((data: any) => {
        console.log('unhold', data)
      });
    }
    this.locationService.holdShiftSlotForSlotId(holdslot).subscribe((data: any) => {
      if (data > 0) {
        localStorage.setItem("idOfHoldingClientObj", data);
        var countDownDate = new Date();
        countDownDate.setMinutes(countDownDate.getMinutes() + 10);
        localStorage.slotId = localStorage.slotId;
        localStorage.countDown = countDownDate;
        localStorage.startDate = countDownDate;
      }
    });
  }
  minAttrPrice(attributeTypes) {
    var attrPrice = [];
    for (let i in attributeTypes) {
      if (attributeTypes[i].isRequired) {
        for (let j in attributeTypes[i].itemAttributeDisplayPackets) {
          attrPrice.push(attributeTypes[i].itemAttributeDisplayPackets[j].sellingPrice);
        }
      }
    }
    if (attrPrice.length > 0)
      return attrPrice.reduce((a, b) => Math.min(a, b));
    else
      return 0;
  }
  routerOnDeactivate() {
    console.log('im here router')
  }

}
