import { appServerConstants } from './../../app-url-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';


import { map, catchError } from 'rxjs/operators';

/*
  Generated class for the HttpServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class HttpServiceProvider {

    constructor(private httpClient: HttpClient) {
    }


    public authenticateUser(url, postData) {

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': "no-cache, must-revalidate",
            'Access-Control-Allow-Origin': '*',
            'Expires': "Sat, 26 Jul 1997 05:00:00 GMT",

        });

        const options = {
            headers,
        };

        let postUrl = appServerConstants.SERVER_PATH + url;

        console.log('url==>' + postUrl);
        console.log('Post Object==>' + JSON.stringify(postData));

        return this.httpClient.post(postUrl, JSON.stringify(postData), options)
            .pipe(map(response => {
                // login successful if there's a jwt token in the response
                return response;
            }),
                catchError((error) => throwError(error)))

    }


    /**Normal post with headers */
    public post(servicesName, serviceUrl, postData, globalAuthToken) {
        var headers;
        if (globalAuthToken == 1) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'auth-token': '8fc8e05d-873d-4ea5-9db4-d2442ce8983f',
            });
        } else {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem(appServerConstants.AUTH_TOKEN),
            });
        }

        const options = {
            headers,
        };

        let postUrl = appServerConstants.SERVER_PATH + servicesName + appServerConstants.VERSION + serviceUrl;
        //let putUrl= appServerConstants.SERVER_PATH + servicesName + appServerConstants.VERSION + serviceUrl;

        console.log('url==>' + postUrl);
        console.log('Post Object==>' + JSON.stringify(postData));

        return this.httpClient.post(postUrl, JSON.stringify(postData), options)
            .pipe(map(response => {
                console.log('Post response==>' + JSON.stringify(response));
                return response;
            }),
                catchError((error) => throwError(error)))

    }


    public postForUrlEncodedType(servicesName, url, postData) {

        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            
        });



        const options = {
            headers,
        };


        let postUrl = appServerConstants.SERVER_PATH + servicesName + appServerConstants.VERSION + url;

        let postParams = this.serializeData(postData);

        console.log('url==>' + postUrl);
        console.log('Post Object==>' + JSON.stringify(postData));

        return this.httpClient.post(postUrl, postParams, options)
            .pipe(map(response => {
                // login successful if there's a jwt token in the response
                return response;
            }),
                catchError((error) => throwError(error)))
    }

    public put(servicesName, serviceUrl, postData) {

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': "no-cache, must-revalidate",
            'Access-Control-Allow-Origin': '*',
            'Expires': "Sat, 26 Jul 1997 05:00:00 GMT",
            'auth_toke': localStorage.getItem(appServerConstants.AUTH_TOKEN),

        });

        const options = {
            headers,
        };

        let putUrl = appServerConstants.SERVER_PATH + servicesName + appServerConstants.VERSION + serviceUrl;

        console.log('url==>' + putUrl);
        console.log('Post Object==>' + JSON.stringify(postData));

        return this.httpClient.put(putUrl, JSON.stringify(postData), options)
            .pipe(map(response => {
                // login successful if there's a jwt token in the response
                return response;
            }),
                catchError((error) => throwError(error)))

    }

    public get<T>(servicesName, serviceUrl, globalAuthToken) {
        var headers;
        console.log("global ", globalAuthToken);
        if (globalAuthToken == 1) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'auth-token': '8fc8e05d-873d-4ea5-9db4-d2442ce8983f',
            });
        } else {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem(appServerConstants.AUTH_TOKEN),
            });
        }

        const options = {
            headers,
        };

        let getUrl = appServerConstants.SERVER_PATH + servicesName + appServerConstants.VERSION + serviceUrl;

        console.log('url==>' + getUrl);

        return this.httpClient.get(getUrl, options)
            .pipe(map(response => {
                console.log(response);
                return response;
            }),
                catchError((error) => throwError(error)))
    }


    public delete(servicesName, serviceUrl) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': "no-cache, must-revalidate",
            'Access-Control-Allow-Origin': '*',
            'auth_toke': localStorage.getItem(appServerConstants.AUTH_TOKEN),

        });
        const options = {
            headers,
        };

        let getUrl = appServerConstants.SERVER_PATH + servicesName + appServerConstants.VERSION + serviceUrl;

        console.log('url==>' + getUrl);

        return this.httpClient.delete(getUrl, options)
            .pipe(map(response => {
                return response;
            }),
                catchError((error) => throwError(error)))
    }


    public serializeData(data) {

        //        // Polyfill isArray for IE 8
        //        if (!Array.isArray) {
        //            Array.isArray = function (arg) {
        //                return Object.prototype.toString.call(arg) === '[object Array]';
        //            };
        //        }

        // If this is not an object, defer to native stringification.
        if (typeof data !== 'object') {
            return ((data == null) ? "" : data.toString());
        }
        var buffer = [];

        // Serialize each key in the object.
        for (var name in data) {
            if (!data.hasOwnProperty(name)) {
                continue;
            }
            var value = data[name];

            // If this is an array, we need to loop through all array elements
            if (Array.isArray(value)) {
                for (var i = 0; i < value.length; i++) {
                    var val = value[i];
                    buffer.push(
                        encodeURIComponent(name) + "=" +
                        encodeURIComponent((val == null) ? "" : val)
                    )
                }
            }
            else {
                buffer.push(
                    encodeURIComponent(name) + "=" +
                    encodeURIComponent((value == null) ? "" : value)
                )
            }
        }
        // Serialize the buffer and clean it up for transportation.
        var source = buffer
            .join("&")
            .replace(/%20/g, "+")
            ;
        return (source);
    }

}
