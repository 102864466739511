
export class RequestType  {
  created:Date;
  createdBy:Number;
  updated: Date ;
  updatedBy:Number;
  status:String;
  description:String;
  requestName:String;
  locationsId:Number;
  displaySequence:Number;
  displayName:String;
  id:String;


  }
