import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  LocationService, OrderService, HttpServiceProvider,
} from './services';
import { UserService } from './services/user.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
     LocationService,
     OrderService,
     HttpServiceProvider,

     UserService
  ],
  declarations: []
})
export class CoreModule { }
