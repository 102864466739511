import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';

import { MainHeaderModule } from '../shared/layout/main-header.module';
import { CommonFooterModule } from '../shared/layout/common-footer.module';
import { SliderModule } from 'angular-image-slider';
import { CommonModule } from '@angular/common';



@NgModule({
  imports: [
    HomeRoutingModule,
    CommonFooterModule,
    MainHeaderModule,
    SliderModule,
    CommonModule
  ],
  declarations: [
    HomeComponent,

  ],
  providers: [
  ],

  exports: [

  ]
})
export class HomeModule {}
