import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from './must-match';
import { UserService } from 'src/app/core/services/user.service';

import { PageProperty, LocationService, City, Country } from '../core';

import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @ViewChild(MatSelect, {static:false}) matSelect: MatSelect;
  pageProperty: PageProperty;
  isHomePage: boolean = false;
  toSelect: any;
  account_title: string;
  registrationForm: FormGroup;
  isSubmitted: boolean;
  cities: City[];
  compareWith: any;
  defaultCountry: any;
  loading: any;
  locationDetails:any;
  searchValue: string;
  page: string;
  countryData: Country[];
  filteredOptions: Country[];

  matcher = new MustMatch();
  businessId: any;
  countItem = 0;
  phoneExist: boolean = false;
  emailExist: boolean = false;
  updateAccount = 0;
  userForEdit: any;
  globaluserId: any;
  isMint: boolean = false;
  cityId = 0;

  constructor(public formBuilder: FormBuilder, private router: Router, private userService: UserService
    , private route: ActivatedRoute, private locationService: LocationService,private titleService: Title) {

      this.locationDetails = JSON.parse(localStorage.getItem("locationDetails"));


     }




  ngOnInit(): void {

    this.cities = JSON.parse(localStorage.getItem("cities"));
    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
 // this.titleService.setTitle(this.pageProperty.name);
    this.countryData = JSON.parse(localStorage.getItem("countries"));
    this.filteredOptions = this.countryData;
    this.page = this.route.snapshot.params.page;
    this.updateAccount = this.route.snapshot.params.id;
    this.businessId = localStorage.getItem("business_id");
    this.cityId = JSON.parse(localStorage.getItem("selectedAreaId"));

    if ([105, 1622, 3176].includes(Number(this.businessId))) {
      this.isMint = true;console.log("Changing to green....");
    }

    const backgroundColor = this.isMint ? '#95ca4c' : '#fc0d20';
    const borderRadius = this.isMint ? '2rem 0' : '0.35rem';
    const leaf = this.isMint ? 'url("/assets/images/Mint_Leaf_image.png") center/contain no-repeat' : '';

    document.documentElement.style.setProperty('--background-color', backgroundColor);
    document.documentElement.style.setProperty('--border-Radius', borderRadius);
    document.documentElement.style.setProperty('--leaf', leaf);
    console.log(localStorage.getItem("cartItems"));
    if (localStorage.getItem("cartItems") && localStorage.getItem("cartItems") != undefined) {
      var cartItems = JSON.parse(localStorage.getItem("cartItems"));
      var countItem = 0;
      for (var i = 0; i < cartItems.items.length; i++) {
        countItem = countItem + cartItems.items[i].quantity;
      }

      this.countItem = countItem;
    }

    // let locationDetails = localStorage.getItem("locationDetails");
    // console.log(locationDetails);
    // if()
    // let location = locationDetails['location'];
    // console.log(location);
    if (!this.countryData || this.countryData.length == 0)
      this.locationService.getAllCountries()
        .subscribe((response: any) => {
          this.countryData = response;
          this.filteredOptions = response;
          console.log(response);
          localStorage.setItem("countries", JSON.stringify(this.countryData));


        });


    this.registrationForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: [''],
        countryCode: ['', Validators.required],
        phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],

        email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],

        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required]],

      },
      {
        validator: this.checkPasswords
      }

    );

    let city = JSON.parse(localStorage.getItem("SelectedCity"));
    console.log("selected countru", city);
    if (city != null) {
      let obj = this.countryData.find(c => c.id == city.countryId);
      this.toSelect = obj;
      console.log("selected countrddu", obj);
    }
    else {
      let obj = this.countryData.find(c => c.name == "UNITED STATES");
      this.toSelect = obj;
      console.log("selected cousssntrddu", obj);
    }
    this.registrationForm.get('countryCode').setValue(this.toSelect);

    if (this.updateAccount == 1) {
      this.userForEdit = JSON.parse(localStorage.getItem("currentUser"));

      this.globaluserId = localStorage.getItem('globalUserId');

      this.registrationForm.get('firstName').setValue(this.userForEdit.firstName);
      this.registrationForm.get('lastName').setValue(this.userForEdit.lastName);
      this.registrationForm.get('email').setValue(this.userForEdit.email);
      this.registrationForm.get('phoneNumber').setValue((this.userForEdit.phone.split('-'))[1]);

      this.registrationForm.get('password').setValue(null);
      this.registrationForm.get('confirmPassword').setValue(null);

      let obj = this.countryData.find(c => c.id == this.userForEdit.countryId);
      this.toSelect = obj;
      this.registrationForm.get('countryCode').setValue(this.toSelect);

    }
  }

  ngAfterViewInit() {
    this.matSelect.openedChange.subscribe((opened) => {
      if (opened && this.searchInput) {
        setTimeout(() => {
          this.searchInput.nativeElement.focus();
        });
      }
    });
  }

  onChange(deviceValue) {
    // console.log(deviceValue);
    // this.toSelect = deviceValue;
    // this.registrationForm.get('countryCode').setValue(deviceValue);


  }

  checkPhoneExist(event) {
    let phone = this.registrationForm.value.countryCode.phoneCode + "-" + event.target.value;
    // if (this.page != "home" && this.page != "business") {
    //   // let locationId = JSON.parse(localStorage.getItem('locationInfo')).id;
    //   this.userService.getLocalUserByPhoneNumber(phone)
    //     .subscribe((res: any) => {

    //       if (res.id > 0) {
    //         console.log("hi......................................................");
    //         this.phoneExist = true;
    //       } else {
    //         this.phoneExist = false;
    //       }
    //     }, err => {
    //       console.log(err);

    //     });
    // } else {
      this.userService.getUserByPhoneNumber(phone)
        .subscribe((res: any) => {
          if (res.id > 0) {
            this.phoneExist = true;
          } else {
            this.phoneExist = false;
          }
        }, err => {
          console.log(err);

        });
    // }
  }

  checkEmailExist(event) {
    let email = event.target.value;
    // if (this.page != "home" && this.page != "business") {
    //   this.userService.getLocalUserByEmail(email)
    //     .subscribe((res: any) => {
    //       if (res.id > 0) {
    //         this.emailExist = true;
    //       } else {
    //         this.emailExist = false;
    //       }
    //     }, err => {
    //       console.log(err);

    //     });
    // } else {
      this.userService.getUserByEmail(email)
        .subscribe((res: any) => {
          if (res.id > 0) {
            this.emailExist = true;
          } else {
            this.emailExist = false;
          }
        }, err => {
          console.log(err);

        });
    // }
  }

  onKey(value) {
    console.log(value);
    value = value.toLowerCase()
    if (!value) {

      console.log('fulldayta');

      this.countryData = this.filteredOptions;
    }
    else {
      console.log('dayta');
      this.countryData = this.filteredOptions;
      this.countryData = this.search(value);
    }
  }

  search(value: any): Country[] {
    let filter = value.toLowerCase();
    return this.countryData.filter(country => {
      return (
        country.displayName
          .toLowerCase()
          .includes(value) ||
        country.phoneCode.toString()
          .toLowerCase()
          .includes(value)
      );
    });

  }







  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }

  }



  onsubmitSignup() {
    if (!this.phoneExist && !this.emailExist) {
      this.isSubmitted = true;

      if (this.registrationForm.invalid) {
        // alert("form invalid");
        return;
      } else {
        if (this.updateAccount == 1) {
          this.updateSignUpForm();
        } else {
          this.submitSignUpForm();
        }
      }
    }
  }

  clearAllForm() {

  }


  async submitSignUpForm() {

    {
      console.log(this.registrationForm.value)
      let selectedCity: any = localStorage.getItem("SelectedCity") == null ? localStorage.getItem("SelectedCity") : localStorage.getItem("SelectedCity").toString() || '0';
      let countryId: any;
      if (selectedCity == null) {
        countryId = 0;
      }
      else {
        countryId = selectedCity.countryId;
      }
      //this.registrationForm.value.countryCode + "-"
      if (this.page == "home" || this.page == "business") {
        var user = {
          "authPin": "",
          "createdBy": "1",
          "updatedBy": "1",
          "status": "A",
          "globalUsersId": "0",
          "firstName": this.registrationForm.value.firstName,
          "lastName": this.registrationForm.value.lastName,
          "username": this.registrationForm.value.phoneNumber,
          "email": this.registrationForm.value.email,
          "phone": this.registrationForm.value.countryCode.phoneCode + "-" + this.registrationForm.value.phoneNumber,
          "password": this.registrationForm.value.password,
          "countryId": 226,
        };

        console.log(user);

        var address = [];
        var user_data = {};
        let post_data = {
          "echoString": localStorage.getItem('deviceId') + " user-add-1",
          "merchantId": 1312,//localStorage.getItem("account_id"),
          "locationId": 1,////localStorage.getItem("nirvanaxpLocationId"),
          "clientId": localStorage.getItem('deviceId'),
          "localReferenceNumber": null,//localStorage.getItem('localReferenceNumber'),
          "status": "A",
          "user": user,
          'addressList': address
        };
        console.log(post_data);
        user_data = {
          "UserPostPacket": post_data
        };

      } else {
        var user = {
          "authPin": "",
          "createdBy": "1",
          "updatedBy": "1",
          "status": "A",
          "globalUsersId": "0",
          "firstName": this.registrationForm.value.firstName,
          "lastName": this.registrationForm.value.lastName,
          "username": this.registrationForm.value.phoneNumber,
          "email": this.registrationForm.value.email,
          "phone": this.registrationForm.value.countryCode.phoneCode + "-" + this.registrationForm.value.phoneNumber,
          "password": this.registrationForm.value.password,
          "countryId": 226,
        };

        console.log(user);

        var locationDetails = JSON.parse(localStorage.getItem("locationDetails"));

        var address = [];
        let post_data = {
          "echoString": localStorage.getItem('deviceId') + " user-add-1",
          "merchantId": localStorage.getItem("account_id"),
          "locationId": locationDetails.location.id,
          "clientId": localStorage.getItem('deviceId'),
          "localReferenceNumber": localStorage.getItem("auth_token"),
          "status": "A",
          "user": user,
          'addressList': address
        };
        console.log(post_data);

        user_data = {

          "UserPostPacket": post_data
        };

        console.log("user post packetttttt=================================================", user_data);
      }
      // await this.presentLoading('Loading ...');

      this.userService.signupUser(user_data)
        .subscribe((res: any) => {

          console.log(res);
          //   this.loading.dismiss();
          if (res == null || res.displayMessage) {
            alert(res.displayMessage);
            return;
          }
          localStorage.setItem('currentUser', JSON.stringify(res.nirvanaXpUser));
          localStorage.setItem('isUserLogin', '1');
          localStorage.setItem('globalUserId', res.nirvanaXpUser.globalUsersId);

          if (this.page == "home") {
            this.router.navigate(['home']);
          } else if ((this.page == "business")) {
            this.router.navigate(['business', JSON.parse(localStorage.getItem("SelectedCity")).id]);
          } else if ((this.page == "reservation")) {
            this.router.navigate(['reservation']);
          } else {
            this.router.navigate(['menu', this.businessId, this.page]);
          }

        }, error => {
          alert(error);

        });

    }
  }

  async updateSignUpForm() {

    {
      console.log(this.registrationForm.value)
      let selectedCity: any = localStorage.getItem("SelectedCity") == null ? localStorage.getItem("SelectedCity") : localStorage.getItem("SelectedCity").toString() || '0';
      let countryId: any;
      if (selectedCity == null) {
        countryId = 0;
      }
      else {
        countryId = selectedCity.countryId;
      }
      let password = "null";
      if (this.registrationForm.value.password != "" && this.registrationForm.value.password != null)
        password = this.registrationForm.value.password;

      var user = {
        "authPin": "",
        "createdBy": "1",
        "updatedBy": "1",
        "status": "A",
        "globalUsersId": this.globaluserId,
        "firstName": this.registrationForm.value.firstName,
        "lastName": this.registrationForm.value.lastName,
        "username": this.registrationForm.value.phoneNumber,
        "email": this.registrationForm.value.email,
        "phone": this.registrationForm.value.countryCode.phoneCode + "-" + this.registrationForm.value.phoneNumber,
        "password": password,
        "countryId": this.registrationForm.value.countryCode.id,
      };

      console.log(user);

      var address = [];
      var user_data = {};
      let post_data = {
        "echoString": localStorage.getItem('deviceId') + " user-add-1",
        "merchantId": 1312,//localStorage.getItem("account_id"),
        "locationId": 1,////localStorage.getItem("nirvanaxpLocationId"),
        "clientId": localStorage.getItem('deviceId'),
        "localReferenceNumber": localStorage.getItem("auth_token"),//localStorage.getItem('localReferenceNumber'),
        "status": "A",
        "user": user,
        'addressList': address
      };
      console.log(post_data);
      user_data = {
        "UserPostPacket": post_data
      };

      // await this.presentLoading('Loading ...');

      this.userService.updateNirvanaXPUserForWebsite(user_data)
        .subscribe((res: any) => {

          console.log(res);
          //   this.loading.dismiss();
          if (res == null || res.displayMessage) {
            alert(res.displayMessage);
            return;
          }
          localStorage.setItem('currentUser', JSON.stringify(res.nirvanaXpUser));
          localStorage.setItem('globalUserId', res.nirvanaXpUser.globalUsersId);

          if (this.page == "home") {
            this.router.navigate(['home']);
          } else if ((this.page == "business")) {
            this.router.navigate(['business', JSON.parse(localStorage.getItem("SelectedCity")).id]);
          } else if ((this.page == "reservation")) {
            this.router.navigate(['reservation']);
          } else {
            this.router.navigate(['menu', this.businessId, this.page]);
          }

        }, error => {
          alert(error);

        });

    }
  }
  async presentLoading(msg) {


  }

  async presentToast(msg) {

  }

  ngOnDestroy() {    
    document.documentElement.style.removeProperty('--background-color');
    document.documentElement.style.removeProperty('--border-Radius');
    document.documentElement.style.removeProperty('--leaf');
  }


}
