

export class ReservationSlot {

created:Date;
createdBy:Number;
updated: Date ;
updatedBy:Number;
status:String;
id:Number;
date:Date;
slotStartTime:Date;
slotEndTime:Date;
slotTime:Number;
reservationScheduleId:Number;
currentlyHoldedClient:Number;
currentReservationInSlot:Number;
locationId:Number;
isBlocked:Number;


get dateOfApproval(): Date {
  return new Date(this.date);
}

get dateOfSlotStart(): Date {
  return new Date(this.slotStartTime);
}

get dateOfSlotEnd(): Date {
  return new Date(this.slotEndTime);
}


constructor(data: any) {
  Object.assign(this, data);
}

 }
