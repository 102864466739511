<div mdbModal #checkoutModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">
          <mdb-icon  fas icon="shopping-cart"></mdb-icon>
          Checkout <span *ngIf="orderHeader"> - Order#{{orderHeader.orderNumber}}</span>
        </h4>
      </div>
      <div class="modal-body">
        <div #alert class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="error.isDisplay">
          <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
            <span aria-hidden="true">&times;</span>
          </button>
          {{error.Message}}
        </div>

        <div *ngIf="formattedDate" class = "alert delieveryQuote"> Your est. deilevery time is {{formattedDate}} </div>

        <div style="font-size: 11px;color:#989898;">
          Pay online (card or PayPal) and request home delivery which will be make without direct contact.
          Write in the comments field if you want us to leave the parcel outside the door.
          The delivery boy will ring the bell three times.</div>

        <div class="row justify-content-start" style="margin-top: 20px;max-height: 300px;
        overflow-y: scroll;">
          <div class="col-md-6">
            <div class="list-group">
              <a class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between" style="margin-bottom: 10px;">
                  <h6 class="mb-1"><b>
                      <mdb-icon far icon="user"></mdb-icon> CONTACT
                    </b></h6>

                </div>
                <p class="mb-1" *ngIf="currentUser"><b>{{currentUser.firstName}}
                    {{currentUser.lastName}}</b><br />{{currentUser.email}}<br />{{currentUser.phone}}</p>
              </a>
              <a class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between" style="margin-bottom: 10px;">
                  <h6 class="mb-1"><b>
                      <mdb-icon fas icon="biking"></mdb-icon> ORDERING METHOD
                    </b></h6>
                    <span *ngIf="selectedOrderGroup">
                  <span (click)="showEditAddFunc()"  *ngIf="selectedOrderGroup.name=='Delivery' && !showEditAdd && addressList?.length>0">
                    <button type="button" class="btn" 
                    style="height: 26px;padding: 5px 10px;margin: 0;"
                    >Select Address</button>
                  </span></span>
                  <button type="button" class="btn" style="height: 26px;padding: 5px 10px;margin: 0;"
                    *ngIf="showEditAdd && !showNewAddress" (click)="addAddress()">Save</button>
                </div>
                <span *ngIf="selectedOrderGroup">
                <p class="mb-1" *ngIf="!showNewAddress"><b>{{selectedOrderGroup.displayName}} 
                  <span  *ngIf="selectedOrderGroup.name=='Delivery'">To:</span>
                  </b>
                  <span *ngIf="selectedOrderGroup.name=='Delivery' && selectedAddress!=''"><br />{{selectedAddress.address1}}, 
                    {{selectedAddress.address2}}, {{selectedAddress.city}}, {{selectedAddress.state}}, {{selectedAddress.zip}}</span>
                    <select class="form-control" *ngIf="showEditAdd" (change)="changeAddress($event.target.value)">
                      <option value="">CHOOSE ADDRESS</option>
                      <option *ngFor="let address of addressList; let i = index" [value]="address.address1">{{address.address1}}, 
                        {{address.address2}}, {{address.city}}, {{address.state}}, {{address.zip}}</option>
                    </select>
                    <span *ngIf="selectedOrderGroup.name=='Delivery' && (showEditAdd || selectedAddress=='')">
                    
                      <button type="button" class="btn" *ngIf="selectedOrderGroup.name=='Delivery' && selectedAddress!=''"
                      style="height: 26px;padding: 5px 10px; margin: 20px 10px;" (click)="deleteAddress(selectedAddress)">
                      delete address</button>

                      <button type="button" class="btn"
                      style="height: 26px;padding: 5px 10px; margin: 20px 0px;" (click)="addNewAddress()">Add
                      new address</button>

                  </span>
                </p>
                </span>
                <!---->
                <div class="form-group" [hidden]="!showNewAddress">
                  <input  placeholder="search for location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search [formControl]="searchControl" autofocus>
                  
                
                </div>
                
                
                <form [formGroup]="addressForm" (ngSubmit)="onAddressSubmit()" *ngIf="showNewAddress">
                  <div *ngIf="keepFieldsDisabled">
                  <input type="text"  class="form-control mb-2" id="name" formControlName="address1"
                    placeholder="Flat No./Appt No." required disabled >
                  </div>
                  <div *ngIf="!keepFieldsDisabled">
                    <input type="text"  class="form-control mb-2" id="name" formControlName="address1"
                      placeholder="Flat No./Appt No." required >
                    </div>
                    <div *ngIf="address1.invalid && keepFieldsDisabled==false" class="error"
                    style="color: #ff1123;font-size: 13px;">
                   Flat No./Apt No required.
                  </div>
                  
                  <div *ngIf="keepFieldsDisabled">
                    <input type="text" class="form-control mb-2" id="address2" formControlName="address2"
                    placeholder="Address" disabled>
                  </div>
                  <div *ngIf="!keepFieldsDisabled">
                    <input type="text" class="form-control mb-2" id="address2" formControlName="address2"
                    placeholder="Address" [value]="autofillAddress2">
                  </div>
    
                  
                  <div *ngIf="keepFieldsDisabled">
                    <input type="text" class="form-control mb-2" id="zipcode" formControlName="zipcode"
                    placeholder="Zipcode" required disabled>
                  </div>
                  <div *ngIf="!keepFieldsDisabled">
                    <input type="text" class="form-control mb-2" id="zipcode" formControlName="zipcode"
                    placeholder="Zipcode" required>
                  </div>
                 

                  <div *ngIf="zipcode.invalid && addressForm.dirty" class="error"
                    style="color: #ff1123;font-size: 13px;">
                    Zipcode required.
                  </div>
                  <select formControlName="country" class="form-control mb-2" disabled>
                    <option *ngFor="let country of countries" [ngValue]="country">
                      {{ country.displayName }}
                    </option>
                  </select>
                  <select formControlName="state" class="form-control mb-2" disabled>
                    <option *ngFor="let state of states" [ngValue]="state">
                      {{ state.stateName }}
                    </option>
                  </select>
                  <select formControlName="city" class="form-control mb-2" disabled>
                    <option *ngFor="let city of cities" [ngValue]="city">
                      {{ city.cityName }}
                    </option>
                  </select>
                  <div class="text-center">
                    <button class="btn" type="submit">Save</button>
                    <button class="btn" type="button" (click)="closeAddAddress()">Cancel</button>
                  </div>
                </form>


              </a>
              <a class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between" style="margin-bottom: 10px;">
                  <h6 class="mb-1"><b>
                      <mdb-icon far icon="clock"></mdb-icon> CHOOSE A TIME
                    </b></h6>
                  <!-- <span  (click)="showEditTimeFunc()">
                    <mdb-icon fas icon="pencil-alt" *ngIf="!showEditTime"></mdb-icon>
                  </span> -->
                  <button type="button" class="btn" style="height: 26px;padding: 5px 10px;margin: 0;"
                    *ngIf="showEditTime" (click)="addScheduleTime()">Save</button>

                </div>
                <p class="mb-1" *ngIf="!showEditTime">
                  <b>{{scheduleWay}}</b><br />{{convertDate()}}
                </p>
                <ul class="list-group list-group-horizontal" *ngIf="showEditTime">
                  <li class="list-group-item p-2" style="width: 50%;" [class.selected_card_item]="scheduleWay=='Now'">
                    <div class="custom-control custom-radio">
                      <input type="radio" class="custom-control-input" id="scheduleWayNow" name="scheduleWayPopup"
                        mdbInput [(ngModel)]="scheduleWay" value="Now">
                      <label class="custom-control-label" for="scheduleWayNow">Now
                        <p *ngIf="scheduleWay=='Now'" class="mb-1" style="font-size: 14px;font-weight: 400;">
                          {{convertDate()}}</p></label>

                    </div>
                  </li>
                  <li class="list-group-item p-2" style="width: 50%;" [class.selected_card_item]="scheduleWay=='Later'">
                    <div class="custom-control custom-radio">
                      <input type="radio" class="custom-control-input" id="scheduleWayLater" name="scheduleWayPopup"
                        mdbInput [(ngModel)]="scheduleWay" value="Later">
                      <label class="custom-control-label" for="scheduleWayLater">Schedule for
                        Later
                        <p *ngIf="scheduleWay=='Later'" class="mb-1" style="font-size: 14px;font-weight: 400;">
                          {{currentDate}}<br>{{currentTime}}</p></label>
                    </div>
                  </li>
                </ul>
              </a>
              <a class="list-group-item list-group-item-action flex-column align-items-start"  [hidden]="isRazorPayGateway || DisableContactless">
                <div class="d-flex w-100 justify-content-between" style="margin-bottom: 10px;">
                  <h6 class="mb-1"><b>
                      <mdb-icon far icon="credit-card"></mdb-icon> PAYMENT METHOD
                    </b></h6>
                   <span (click)="showEditPay()">
                    <mdb-icon fas icon="pencil-alt" *ngIf="!showEditPayment"></mdb-icon>
                  </span> 

                  <!-- <button type="button" class="btn" style="height: 26px;padding: 5px 10px;margin: 0;"
                    *ngIf="showSaveButton && showEditPayment" (click)="addPaymentInfo()">Save</button> -->
                </div>
                <b *ngIf="!showEditPayment && selectedFourDigit!=''">Credit Card:</b>
                <p *ngIf="!showEditPayment && selectedFourDigit!=''">Ending in {{selectedFourDigit}} (Expire in
                  {{selectExpirationDate}})</p>
                <!-- <select class="form-control" [hidden]="!showEditPayment" (change)="changeCard($event)">
                  <option>Choose Card</option>
                  <option *ngFor="let card of cardDetails; let i = index" [value]="card.token">Ending in
                    {{card.lastFourDigit}} (Expire in {{card.expirationMonth}}/{{card.expirationYear}})</option>
                  <option value="newCard">Add New Card</option>
                </select> -->
                <div [hidden]="!showEditPayment" class="row">
                  <div class="col">
                    <form novalidate (ngSubmit)="getClientSecretKeyFromServer()" id="payment-form" [formGroup]="stripeTest">
                      <div id="card-element" class="field"></div>
                      <div class="text-center">
                        <button type="submit" id="payButton" class="btn"
                          style="height: 36px;padding: 11px 10px;text-transform: none;display: none;">
                          Proceed to Pay
                        </button></div>
                    </form>
                  </div>
                </div>

              </a>
              <a class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between" style="margin-bottom: 10px;">
                  <h6 class="mb-1"><b>
                    <mdb-icon far icon="comment-alt"></mdb-icon> COMMENTS
                    </b></h6>
                    
                   <span (click)="showEditCom()">
                    <mdb-icon fas icon="pencil-alt" *ngIf="!showEditComment"></mdb-icon>
                    
                  </span>
                  <button type="button" class="btn" style="height: 26px;padding: 5px 10px;margin: 0;"
                    *ngIf="showEditComment"  (click)="addComment()">Save</button>
                    
                </div>
               <div class="row" *ngIf="!showEditComment">
                 <div class="col" style="word-wrap: break-word;">{{comment}}</div>
               </div>
                <div  *ngIf="showEditComment" class="row">
                  <div class="col">
                    <textarea maxlength="256" [(ngModel)]="comment"
                    clearOnEdit="true" placeholder="Enter your comments here..." style="width: 100%;"></textarea>
                    <span *ngIf="comment == null " style="font-style: italic;">
                      <p style="margin-top:8px">You have 256 characters remaining</p>
                    </span>
                    <span *ngIf="comment != null " style="font-style: italic;">
                      <p style="margin-top:8px">You have {{256 - comment.length}} characters remaining</p>
                    </span>
                  </div>
                </div>

              </a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="list-group">
              <a class="list-group-item my-card-top" mdbWavesEffect>
                <b>
                  <div class="row">
                    <div class="col-2">Qty</div>
                    <div class="col-8">Item</div>
                    <div class="col-2">Price</div>
                  </div>
                </b>
              </a>
              <a *ngIf="cartItems" class="list-group-item list-group-item-action flex-column align-items-start">
                <b *ngFor="let item of cartItems.items; let j = index">
                  <div class="row" *ngIf="item.isItemRemoved==0">
                    <div class="col-2">{{item.quantity}} x </div>
                    <div class="col-7">
                      {{item.displayName}}<br />
                      <small *ngFor="let attrType of item.addedAttribute">
                        <span *ngFor="let attr of attrType.attr">{{attr.displayName}},</span>
                      </small>
                    </div>
                    <div class="col-3 text-right">{{currency}}{{item.subTotal*item.quantity| number:'1.2-2'}}</div>
                  </div>
                </b>
              </a>
              <span *ngIf="isOrderLoaded" class="list-group-item list-group-item-action flex-column align-items-start">
                <!-- <div class="row" style="margin-bottom: 12px;">
                  <div class="col-12">Apply Coupon Code</div>
                </div> -->
                <div class="row" style="margin-bottom: 12px;">
                  <div class="col-9">Subtotal:</div>
                  <div class="col-3  text-right">{{currency}}{{orderHeader.subTotal| number:'1.2-2'}}</div>
                </div>
                <div class="row" style="margin-bottom: 12px;" *ngIf="!orderHeader.priceDiscount">
                  <div class="col-9">
                    <input type="text" class="form-control" placeholder="Enter Coupon Code" style="width: 150px;
                    font-size: 14px;
                    padding: 5px;
                    float: left;
                    margin-right: 5px;
                    height: 36px;" [(ngModel)]="discountCode" aria-describedby="button-addon4">
                    <button type="button" class="btn"
                      style="height: 36px;padding: 11px 10px;margin: 0;" (click)="applyDiscount()">Apply</button>
                  </div>
                  <div class="col-3 text-right">
                    {{currency}}0.00
                  </div>
                </div>
                <!-- <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.priceDiscount">
                  <div class="col-8">
                    Applied Coupon - {{orderHeader.discountName}}
                  </div>
                  <div class="col-4 text-right">
                    <button type="button" class="btn"
                      style="height: 36px;padding: 11px 10px;margin: 0;" (click)="removeDiscount()">Remove</button>
                  </div>
                </div> -->



           <!--     <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.priceDiscount">
                  <div class="col-9">
                    Discount Amount({{orderHeader.discountName}}):<span (click)="removeDiscount()"
                      style="font-size: 12px;color: #ff1123;cursor: pointer;"> Remove <mdb-icon fas icon="times">
                      </mdb-icon></span>
                  </div>
                  <div class="col-3 text-right">
                    {{currency}}{{orderHeader.priceDiscount| number:'1.2-2'}}
                  </div>
                </div>-->
                


                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.totalTax || orderHeader.convenienceCharge">
                  <!-- <div class="col-9">Fees and Sales Tax <i class="fas fa-info-circle" placement="top" style="overflow: visible;color: var(--background-color,red);"
                    triggers="focus" [mdbPopover]="popTemplate" triggers="focus"
                    mdbWavesEffect></i>:</div>
                  <div class="col-3 text-right">{{currency}}{{orderHeader.totalTax + orderHeader.convenienceCharge| number:'1.2-2'}}</div> -->

                  
                    <!-- <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.convenienceCharge">
                        Fees and Sales Tax
                    </div>
                    <div class="col-12 d-flex justify-content-between" style="font-size: 16px"
                        *ngIf="orderHeader.convenienceCharge">
                        <span>{{convenienceName}}:</span>
                        <span>{{currency}}{{orderHeader.convenienceCharge | number:'1.2-2'}}</span>
                    </div> -->
                    <!-- <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax1">
                        This fee helps to operate PuneEats
                    </div> -->
                  <div class="col-12 d-flex justify-content-between" style="font-size: 16px" *ngIf="orderHeader.priceTax1">
                      <span>{{orderHeader.taxDisplayName1}}:</span>
                      <span>{{currency}}{{orderHeader.priceTax1 | number:'1.2-2'}}</span>
                  </div>
                  
                  <div class="col-12 d-flex justify-content-between" style="font-size: 16px" *ngIf="orderHeader.priceTax2">
                      <span>{{orderHeader.taxDisplayName2}}:</span>
                      <span>{{currency}}{{orderHeader.priceTax2 | number:'1.2-2'}}</span>
                  </div>
                  
                  <div class="col-12 d-flex justify-content-between" style="font-size: 16px" *ngIf="orderHeader.priceTax3">
                      <span>{{orderHeader.taxDisplayName3}}:</span>
                      <span>{{currency}}{{orderHeader.priceTax3 | number:'1.2-2'}}</span>
                  </div>
                  
                  <div class="col-12 d-flex justify-content-between" style="font-size: 16px" *ngIf="orderHeader.priceTax4">
                      <span>{{orderHeader.taxDisplayName4}}:</span>
                      <span>{{currency}}{{orderHeader.priceTax4 | number:'1.2-2'}}</span>
                  </div>
                  
                </div>
                
                <!-- <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.priceTax1">
                  <div class="col-9">{{orderHeader.taxDisplayName1}}({{orderHeader.taxRate1}}% of
                    {{orderHeader.subTotal| number:'1.2-2'}}):</div>
                  <div class="col-3 text-right">{{currency}}{{orderHeader.priceTax1| number:'1.2-2'}}</div>
                </div>
                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.priceTax2">
                  <div class="col-9">{{orderHeader.taxDisplayName2}}({{orderHeader.taxRate2}}% of
                    {{orderHeader.subTotal| number:'1.2-2'}}):</div>
                  <div class="col-3 text-right">{{currency}}{{orderHeader.priceTax2| number:'1.2-2'}}</div>
                </div>
                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.priceTax3">
                  <div class="col-9">{{orderHeader.taxDisplayName3}}({{orderHeader.taxRate3}}% of
                    {{orderHeader.subTotal| number:'1.2-2'}}):</div>
                  <div class="col-3 text-right">{{currency}}{{orderHeader.priceTax3| number:'1.2-2'}}</div>
                </div>
                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.priceTax4">
                  <div class="col-9">{{orderHeader.taxDisplayName4}}({{orderHeader.taxRate4}}% of
                    {{orderHeader.subTotal| number:'1.2-2'}}):</div>
                  <div class="col-3 text-right">{{currency}}{{orderHeader.priceTax4| number:'1.2-2'}}</div>
                </div> -->
                <div class="row" style="margin-bottom: 12px;">
                  <div class="col-9">Tips:<br/></div>
                  <div class="col-3 text-right">{{currency}}{{tip| number:'1.2-2'}}</div>
                  <div class="col-12">
                    <button type="button" class="btn tips" [ngClass]="tip==tip1? 'btn-active':'default'"
                      style="height: 36px;padding: 0px 5px;margin: 0px 2px;width: 75px;"
                      (click)="addTip(tip1)">{{currency}}{{tip1| number:'1.2-2'}}</button>
                    <button type="button" class="btn tips" [ngClass]="tip==tip2? 'btn-active':'default'"
                      style="height: 36px;padding: 0px 5px;margin: 0px 2px;width: 75px;"
                      (click)="addTip(tip2)">{{currency}}{{tip2| number:'1.2-2'}}</button>
                    <button type="button" class="btn tips" [ngClass]="tip==tip3? 'btn-active':'default'"
                      style="height: 36px;padding: 0px 5px;margin: 0px 2px;width: 75px;"
                      (click)="addTip(tip3)">{{currency}}{{tip3| number:'1.2-2'}}</button>
                      <button type="button" class="btn tips" [ngClass]="tip == 0 ? 'btn-active' : 'default'"
                      style="height: 36px; padding: 0px 5px; margin: 0px 2px; width: 75px;"
                      (click)="addTip(0)"> <span style="color: white !important;">&#10006;</span></button>
                    <input [(ngModel)]="otherTip" type="text" class="form-control" name="otherTip" placeholder="Other" value=""
                      style="width: 100px;display: inline;padding-left: 5px;margin-top: 20px;font-size: 12px;height: 36px;"
                      (change)="addTip($event.target.value)" />
                  </div>
                </div>
                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.deliveryCharges">
                  <div class="col-9">Delivery Charge and Tax<i class="fas fa-info-circle" placement="top"
                    triggers="focus" [mdbPopover]="popDeliveryCharge" triggers="focus" style="overflow: visible;color: var(--background-color,red);"
                    mdbWavesEffect></i>:</div>
                  <div class="col-3 text-right">{{currency}}{{orderHeader.deliveryCharges + orderHeader.deliveryTax| number:'1.2-2'}}</div>
                </div>
                <!-- <b>
                  <div class="row" style="margin-bottom: 12px;">
                    <div class="col-9">Total:</div>
                    <div class="col-3 text-right">{{currency}}{{orderHeader.total| number:'1.2-2'}}</div>
                  </div>
                </b> -->
                <b>
                  <div class="row" style="margin-bottom: 12px;">
                    <div class="col-9">Total:</div>
                    <div class="col-3 text-right">{{currency}}{{orderHeader.balanceDue+tip| number:'1.2-2'}}</div>
                  </div>
                </b>
              <div *ngIf="locationDetails.locationSetting.hideContactlessPay !=1 || selectedOrderGroup.name !='In Store'">By clicking Proceed To Pay you agree to our <u class="pointer" (click)="openTermsModal('Terms')">Terms and Conditions</u> & <u class="pointer" (click)="openTermsModal('Policy')">Privacy Policy</u></div><br>
              </span>
              <ng-template #popTemplate>
                <div class="row">
                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.convenienceCharge">
                        Fees and Sales Tax
                    </div>
                    <div class="pl-2" style="font-size: 16px"
                        *ngIf="orderHeader.convenienceCharge">
                        {{convenienceName}}:
                        {{currency}}{{orderHeader.convenienceCharge| number:'1.2-2'}}
                    </div>
                    <!-- <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax1">
                        This fee helps to operate PuneEats
                    </div> -->
                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax1">
                        {{orderHeader.taxDisplayName1}}:
                        {{currency}}{{orderHeader.priceTax1| number:'1.2-2'}}
                    </div>
                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax2">
                        {{orderHeader.taxDisplayName2}}:
                        {{currency}}{{orderHeader.priceTax2| number:'1.2-2'}}
                    </div>
                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax3">
                        {{orderHeader.taxDisplayName3}}:
                        {{currency}}{{orderHeader.priceTax3| number:'1.2-2'}}
                    </div>
                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax4">
                        {{orderHeader.taxDisplayName4}}:
                        {{currency}}{{orderHeader.priceTax4| number:'1.2-2'}}
                    </div>
                </div>
            </ng-template>
            <ng-template #popDeliveryCharge>
              <div class="row">
                  <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.deliveryCharges">
                      Delivery Charges and Delivery Tax
                  </div>
                  <div class="pl-2" style="font-size: 16px"
                      *ngIf="orderHeader.deliveryCharges">
                      Delivery Charges:
                      {{currency}}{{orderHeader.deliveryCharges| number:'1.2-2'}}
                  </div>
                  <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.deliveryTax">
                      Delivery Tax:
                      {{currency}}{{orderHeader.deliveryTax| number:'1.2-2'}}
                  </div>
                  
              </div>
          </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="orderHeader">
        <div class="row" style="width:100%">
          <div class="col-md-6">
            <button type="button" mdbBtn class="relative waves-light " mdbWavesEffect style="background-color:var(--background-color,red);
          color:#fff;font-weight: bold;font-size: 1em;height: 60px;text-transform: none;border-radius: var(--border-Radius);
    margin: 5px;padding: 18px 12px;
    width: 90%;" (click)="closeModal()" *ngIf="selectedOrderGroup.name=='In Store'">Save Menu Selection & Pay
              Later</button>
          </div>
          <div class="col-md-6" *ngIf="!isRazorPayGateway">
            <button type="button" mdbBtn class="relative waves-light " mdbWavesEffect style="background-color:var(--background-color,red);
          color:#fff;font-weight: bold;font-size: 1.1em;height: 60px;text-transform: none;border-radius: var(--border-Radius);
    margin: 5px;padding: 18px 12px;
    width: 90%;" (click)="authoriseCard()" *ngIf="selectedOrderGroup.name=='In Store' && !isPaymentInProcess && locationDetails.locationSetting.hideContactlessPay !=1">
              <div style="float: left;
    font-size: 13px;margin-top: -6px">Total
                <br />{{currency}} {{orderHeader.total+tip| number:'1.2-2'}} </div>Pay Now
            </button>

            <button type="button" mdbBtn class="relative waves-light " mdbWavesEffect style="background-color:var(--background-color,red);
          color:#fff;font-weight: bold;font-size: 1.1em;height: 60px;text-transform: none;border-radius: var(--border-Radius);
    margin: 5px;padding: 18px 12px;
    width: 90%;" (click)="authoriseCard()" *ngIf="selectedOrderGroup.name!='In Store' && !isPaymentInProcess">
              <div style="float: left;
    font-size: 13px;margin-top: -6px">Total
                <br />{{currency}} {{orderHeader.total+tip| number:'1.2-2'}}</div>
              Proceed To Pay
            </button>
            <button type="button" mdbBtn class="relative waves-light " mdbWavesEffect disabled *ngIf="isPaymentInProcess" style="background-color:var(--background-color,red);
            color:#fff;font-weight: bold;font-size: 1.1em;height: 60px;text-transform: none;border-radius: var(--border-Radius);
      margin: 5px;padding: 18px 12px;
      width: 90%;">
              Payment in process...
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
          <div class="col-md-6" *ngIf="isRazorPayGateway">
            <button type="button" mdbBtn class="relative waves-light " mdbWavesEffect style="background-color:var(--background-color,red);
          color:#fff;font-weight: bold;font-size: 1.1em;height: 60px;text-transform: none;border-radius: var(--border-Radius);
    margin: 5px;padding: 18px 12px;
    width: 90%;" (click)="payWithRazorPay()" [disabled]="locationDetails.locationSetting.hideContactlessPay==1 "  *ngIf="selectedOrderGroup.name=='In Store' && !isPaymentInProcess">
              <div style="float: left;
    font-size: 13px;margin-top: -6px">Total
                <br />{{currency}} {{orderHeader.total+tip| number:'1.2-2'}}</div>Pay Now
            </button>

            <button type="button" mdbBtn class="relative waves-light " mdbWavesEffect style="background-color:var(--background-color,red);
          color:#fff;font-weight: bold;font-size: 1.1em;height: 60px;text-transform: none;border-radius: var(--border-Radius);
    margin: 5px;padding: 18px 12px;
    width: 90%;" (click)="payWithRazorPay()" *ngIf="selectedOrderGroup.name!='In Store' && !isPaymentInProcess">
              <div style="float: left;
    font-size: 13px;margin-top: -6px">Total
                <br />{{currency}} {{orderHeader.total+tip| number:'1.2-2'}}</div>
              Proceed To Pay
            </button>
            <button type="button" mdbBtn class="relative waves-light " mdbWavesEffect disabled *ngIf="isPaymentInProcess" style="background-color:var(--background-color,red);
            color:#fff;font-weight: bold;font-size: 1.1em;height: 60px;text-transform: none;border-radius: var(--border-Radius);
      margin: 5px;padding: 18px 12px;
      width: 90%;">
              Payment in process...
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>