import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TermsConditionsModalComponent } from './terms-conditions-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TermConditionsService {

  constructor(private dialog: MatDialog) { }

  openDialog(Terms):void {
    this.dialog.open(TermsConditionsModalComponent, {
      // width: '400px', // Specify the width of the dialog
      data: {
        id: Terms
      }
    });
  }
}
