import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'convertFrom24To12Format'})
export class Time24To12Format implements PipeTransform {
     transform(time: any): any {

        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
           // If time format correct
          time = time.slice(1);
          // Remove full string match value

          time[3] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12;
          let part =  time[0];
          if (part.toString().length == 1) {
            part = "0" + part;
           }
          time[0]  = part;             // Adjust hours

        }
        return time.join('');
       }
   }
