<div mdbModal #thankYouModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">
                    <mdb-icon far icon="comment-alt"></mdb-icon>
                    Your order is received
                </h4>
            </div>
            <div class="modal-body">
                <h4>Order Confirmation</h4>
                <div style="font-size: 11px;color:#989898;">
                    Thank you, your order has been received. We have send order details on your registered mobile
                    number.
                </div>
                <div class="row justify-content-start" style="margin-top: 20px;max-height: 50vh;
        overflow-y: scroll;">
                    <div class="col-md-6" *ngIf="orderHeader">
                        <div class="list-group">
                            <a class="list-group-item list-group-item-action flex-column align-items-start">
                                <p class="mb-1 row">
                                    <b class="col-6 text-left">Order No.:</b><b
                                        class="col-6 text-left">#{{orderHeader.orderNumber}}</b>
                                    <b class="col-6 text-left">Date:</b><b
                                        class="col-6 text-left">{{convertDate()}}</b>
                                    <b class="col-6 text-left">Total:</b><b
                                        class="col-6 text-left">{{currency}}{{orderHeader.total + orderHeader.tips | number:'1.2-2'}}</b>
                                    <b class="col-6 text-left" *ngIf="orderHeader.paymentMethod">Payment Method:</b><b
                                        class="col-6 text-left">{{orderHeader.paymentMethod}}</b>
                                </p>
                            </a>
                            <a class="list-group-item list-group-item-action flex-column align-items-start">
                                <div class="d-flex w-100 justify-content-between" style="margin-bottom: 10px;">
                                    <h6 class="mb-1"><b>
                                            <mdb-icon fas icon="biking"></mdb-icon> ORDERING METHOD
                                        </b></h6>
                                </div>
                                <p class="mb-1" *ngIf="selectedOrderGroup.name=='Delivery'">
                                    <b>{{selectedOrderGroup.displayName}} to:</b>
                                    <br />
                                    {{getAddress(orderHeader).address1}},
                                    {{getAddress(orderHeader).address2}},
                                    {{getAddress(orderHeader).city}},
                                    {{getAddress(orderHeader).state}},
                                    {{getAddress(orderHeader).zip}}
                                    <br />
                                    <!-- <span *ngIf="selectedOrderGroup.showAvgWaitTime && scheduleWay !='Later'">Your Order will be Delivered in {{selectedOrderGroup.avgWaitTimeDisplayName}}</span> -->
                                    <span *ngIf = "delieveryEstimate"> {{delieveryEstimate}} </span>
                                </p>
                                <p class="mb-1" *ngIf="scheduleWay =='Later' && selectedOrderGroup.name =='Delivery'">
                                    <b>{{scheduleWay}}</b><br />{{convertDate()}}
                                  </p>
                                <p class="mb-1" *ngIf="selectedOrderGroup.name!='Delivery'">
                                    <b>{{selectedOrderGroup.displayName}}  from:</b><br />
                                    {{getAddress(orderHeader).address1}},
                                    {{getAddress(orderHeader).address2}},
                                    {{getAddress(orderHeader).city}},
                                    {{getAddress(orderHeader).state}},
                                    {{getAddress(orderHeader).zip}}
                                    <br />
                                    <span *ngIf="selectedOrderGroup.showAvgWaitTime && scheduleWay !='Later'">Your order will ready in {{selectedOrderGroup.avgWaitTimeDisplayName}}</span>
                                   
                                </p>
                                <p class="mb-1" *ngIf="scheduleWay =='Later' && selectedOrderGroup.name!='Delivery'">
                                    <b>{{scheduleWay}}</b><br />{{convertDate()}}
                                  </p>
                            </a>
                            <a class="list-group-item list-group-item-action flex-column align-items-start">
                                <div class="d-flex w-100 justify-content-between" style="margin-bottom: 10px;">
                                    <h6 class="mb-1"><b>
                                            <mdb-icon far icon="comment"></mdb-icon> Comments
                                        </b></h6>
                                </div>
                                <p class="mb-1">
                                    <b style="word-wrap: break-word;">{{orderHeader.comment}}</b>
                                </p>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-group">
                            <a class="list-group-item my-card-top" mdbWavesEffect>
                                <b>
                                    <div class="row">
                                        <div class="col-2">Qty</div>
                                        <div class="col-8">Item</div>
                                        <div class="col-2">Price</div>
                                    </div>
                                </b>
                            </a>
                            <a *ngIf="cartItems"
                                class="list-group-item list-group-item-action flex-column align-items-start">
                                <b *ngFor="let item of cartItems.items; let j = index">
                                    <div class="row" *ngIf="item.isItemRemoved==0">
                                        <div class="col-2">{{item.quantity}} x </div>
                                        <div class="col-7">
                                            {{item.displayName}}<br />
                                            <small *ngFor="let attrType of item.addedAttribute">
                                                <span *ngFor="let attr of attrType.attr">{{attr.displayName}},</span>
                                            </small>
                                        </div>
                                        <div class="col-3 text-right">{{currency}}{{item.subTotal*item.quantity| number:'1.2-2'}}
                                        </div>
                                    </div>
                                </b>
                            </a>
                            <span *ngIf="orderHeader"
                                class="list-group-item list-group-item-action flex-column align-items-start">
                                <!-- <div class="row" style="margin-bottom: 12px;">
                  <div class="col-12">Apply Coupon Code</div>
                </div> -->
                                <div class="row" style="margin-bottom: 12px;">
                                    <div class="col-9">Subtotal:</div>
                                    <div class="col-3  text-right">{{currency}}{{orderHeader.subTotal| number:'1.2-2'}}</div>
                                </div>
                                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.priceDiscount">
                                    <div class="col-8">
                                        Discount Amount({{orderHeader.discountName}})
                                    </div>
                                    <div class="col-4 text-right">
                                        -{{currency}}{{orderHeader.priceDiscount| number:'1.2-2'}}
                                    </div>
                                </div>
                                
                                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.totalTax || orderHeader.convenienceCharge">
                                    <!-- <div class="col-9">Fees and Sales Tax <i class="fas fa-info-circle" placement="top" style="overflow: visible;color: var(--background-color,red);"
                                      triggers="focus" [mdbPopover]="popTemplate" triggers="focus"
                                      mdbWavesEffect></i>:</div>
                                    <div class="col-3 text-right">{{currency}}{{orderHeader.totalTax + orderHeader.convenienceCharge| number:'1.2-2'}}</div> -->
                  
                                    
                                      <!-- <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.convenienceCharge">
                                        Fees and Sales Tax
                                    </div>
                                    <div class="col-12 d-flex justify-content-between" style="font-size: 16px"
                                        *ngIf="orderHeader.convenienceCharge">
                                        <span>{{convenienceName}}:</span>
                                        <span>{{currency}}{{orderHeader.convenienceCharge | number:'1.2-2'}}</span>
                                    </div> -->
                                    <!-- <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax1">
                                        This fee helps to operate PuneEats
                                    </div> -->
                                <div class="col-12 d-flex justify-content-between" style="font-size: 16px" *ngIf="orderHeader.priceTax1">
                                    <span>{{orderHeader.taxDisplayName1}}:</span>
                                    <span>{{currency}}{{orderHeader.priceTax1 | number:'1.2-2'}}</span>
                                </div>
                                
                                <div class="col-12 d-flex justify-content-between" style="font-size: 16px" *ngIf="orderHeader.priceTax2">
                                    <span>{{orderHeader.taxDisplayName2}}:</span>
                                    <span>{{currency}}{{orderHeader.priceTax2 | number:'1.2-2'}}</span>
                                </div>
                                
                                <div class="col-12 d-flex justify-content-between" style="font-size: 16px" *ngIf="orderHeader.priceTax3">
                                    <span>{{orderHeader.taxDisplayName3}}:</span>
                                    <span>{{currency}}{{orderHeader.priceTax3 | number:'1.2-2'}}</span>
                                </div>
                                
                                <div class="col-12 d-flex justify-content-between" style="font-size: 16px" *ngIf="orderHeader.priceTax4">
                                    <span>{{orderHeader.taxDisplayName4}}:</span>
                                    <span>{{currency}}{{orderHeader.priceTax4 | number:'1.2-2'}}</span>
                                </div>
                                  </div>
                                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.tips">
                                    <div class="col-9">Tips:</div>
                                    <div class="col-3 text-right">{{currency}}{{orderHeader.tips| number:'1.2-2'}}</div>
                                </div>
                                <div class="row" style="margin-bottom: 12px;" *ngIf="orderHeader.deliveryCharges">
                                    <div class="col-9">Delivery Charge and Tax<i class="fas fa-info-circle" placement="top"
                                        triggers="focus" [mdbPopover]="popDeliveryCharge" triggers="focus" style="overflow: visible;color: var(--background-color,red);"
                                        mdbWavesEffect></i>:</div>
                                      <div class="col-3 text-right">{{currency}}{{orderHeader.deliveryCharges + orderHeader.deliveryTax| number:'1.2-2'}}</div>
                                    </div>
                                <b>
                                    <div class="row" style="margin-bottom: 12px;">
                                        <div class="col-9">Total:</div>
                                        <div class="col-3 text-right">{{currency}}{{orderHeader.total + orderHeader.tips | number:'1.2-2'}}</div>
                                    </div>
                                </b>
                                <!-- <b>
                                    <div class="row" style="margin-bottom: 12px;">
                                        <div class="col-9">Amount Due:</div>
                                        <div class="col-3 text-right">{{currency}}{{orderHeader.balanceDue| number:'1.2-2'}}</div>
                                    </div>
                                </b> -->
                            </span>
                            <ng-template #popDeliveryCharge>
                                <div class="row">
                                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.deliveryCharges">
                                        Delivery Charges and Delivery Tax
                                    </div>
                                    <div class="pl-2" style="font-size: 16px"
                                        *ngIf="orderHeader.deliveryCharges">
                                        Delivery Charges:
                                        {{currency}}{{orderHeader.deliveryCharges| number:'1.2-2'}}
                                    </div>
                                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.deliveryTax">
                                        Delivery Tax:
                                        {{currency}}{{orderHeader.deliveryTax| number:'1.2-2'}}
                                    </div>
                                    
                                </div>
                            </ng-template>
                            <ng-template #popTemplate>
                                <div class="row">
                                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.convenienceCharge">
                                        Fees and Sales Tax
                                    </div>
                                    <div class="pl-2" style="font-size: 16px"
                                        *ngIf="orderHeader.convenienceCharge">
                                        {{convenienceName}}:
                                        {{currency}}{{orderHeader.convenienceCharge| number:'1.2-2'}}
                                    </div>
                                    <!-- <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax1">
                                        This fee helps to operate PuneEats
                                    </div> -->
                                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax1">
                                        {{orderHeader.taxDisplayName1}}:
                                        {{currency}}{{orderHeader.priceTax1| number:'1.2-2'}}
                                    </div>
                                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax2">
                                        {{orderHeader.taxDisplayName2}}:
                                        {{currency}}{{orderHeader.priceTax2| number:'1.2-2'}}
                                    </div>
                                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax3">
                                        {{orderHeader.taxDisplayName3}}:
                                        {{currency}}{{orderHeader.priceTax3| number:'1.2-2'}}
                                    </div>
                                    <div class="pl-2" style="font-size: 16px" *ngIf="orderHeader.priceTax4">
                                        {{orderHeader.taxDisplayName4}}:
                                        {{currency}}{{orderHeader.priceTax4| number:'1.2-2'}}
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row" style="width:100%">
                    <div class="col-md-12">
                        <button *ngIf="this.selectedOrderGroup && this.selectedOrderGroup.name != 'In Store'" type="button" mdbBtn class="relative waves-light " mdbWavesEffect style="background-color:var(--background-color,red);
                  color:#fff;font-weight: bold;font-size: 1em;height: 60px;text-transform: none;width: 100%;border-radius: var(--border-Radius);
            margin: 5px;" (click)="closeModal()">Track your order</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>