import { Component, OnInit } from '@angular/core';
import { PageProperty } from '../../core/models/pageProperty.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '../../core/services/location.service';
import { Title, Meta } from '@angular/platform-browser';
import { UserService } from '../../core/services/user.service';
import { Country } from '../../core/models/country.model';
import { City } from '../../core/models/city.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { appServerConstants } from '../../app-url-constants';
import { AlertDialogService } from '../../shared/alert-msg-dialog/alert-dialog.service';



@Component({
  selector: 'app-business-signup',
  templateUrl: './business-signup.component.html',
  styleUrls: ['./business-signup.component.css']
})
export class BusinessSignupComponent implements OnInit  {

  pageProperty: PageProperty;
  isHomePage: boolean = false;
  toSelect: any;
  account_title: string;
  registrationForm: FormGroup;
  isSubmitted: boolean;
  cities: City[];
  compareWith: any;
  defaultCountry: any;
  loading: any;
  searchValue: string;
  page: string;
  countryData: Country[];
  filteredOptions: Country[];

  businessId: any;
  countItem = 0;
  phoneExist: boolean = false;
  emailExist: boolean = false;
  updateAccount = 0;
  userForEdit: any;
  globaluserId: any;
  propertyId:any;
  isUserLogin:any;
  deviceInfo:any;

  constructor(public formBuilder: FormBuilder, private router: Router, private userService: UserService
    , private route: ActivatedRoute, private locationService: LocationService,private titleService: Title,
     private deviceService: DeviceDetectorService,
    private metaTagService: Meta,private confirmationDialogService: AlertDialogService
    ) { }




  ngOnInit(): void {

     this.registrationForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],

        email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        restaurant:[''],
        area:[''],

       
      },
      

    );


    this.deviceInfo = this.deviceService.getDeviceInfo();
    localStorage.setItem('auth_token', '937e45c7-37ae-4249-b131-1c726693104c');
   
    var deviceId = this.deviceService.userAgent;

    localStorage.setItem('deviceId', deviceId);
    // localStorage.setItem('localReferenceNumber', '0');
    //localStorage.setItem('account_id', '1312');

    
    this.propertyId = appServerConstants.PROPERTY_ID;
    this.locationService.getAllOnPageProperty(this.propertyId)
      .subscribe(tags => {
        this.pageProperty = <PageProperty>tags;
        console.log(tags);
     // this.titleService.setTitle(this.pageProperty.name);


        localStorage.setItem("pageProperty", JSON.stringify(this.pageProperty));
        localStorage.setItem("convenienceName", this.pageProperty.convenienceName);
        // this.locationService.getCityByPropertyId(this.pageProperty.id)
        //   .subscribe((response: any) => {
        //     this.cities = response;
        //     console.log(response);
        //     var metaStr = this.pageProperty.name + ", " + this.pageProperty.subTitle + ", restaurants, order food, order online, order food online, food, " +
        //       "delivery, food delivery, home delivery, fast, hungry, quickly, offer, discount, contactless menu, " +
        //       "contactless pickup, contactless delievry, Reserve and pre-select option, Real Reviews, Order history, Order status,Reservation History";
        //     var cityName = "";
        //     for (var key in this.cities) {
        //       metaStr = metaStr + ", " + this.cities[key].cityName;
        //       cityName = cityName + this.cities[key].cityName + ", ";
        //     }
        //     this.metaTagService.addTags([
        //       { name: 'keywords', content: metaStr },
        //       { name: 'robots', content: 'index, follow' },
        //       { name: 'author', content: 'Uzma Shaikh' },
        //       { name: 'description', content: 'With ' + this.pageProperty.name + ', your favorite local restaurants are just a few taps away. Order safely and securely from ' + this.pageProperty.name + ' restaurants to satisfy your cravings. We offer a wide selection of ' + this.pageProperty.name + ' restaurants and world cuisines - from Italian to Thai and Indian to Continental. serving in ' + cityName },
        //       { charset: 'UTF-8' }
        //     ]);
        //     localStorage.setItem("cities", JSON.stringify(this.cities));

        //   });

      });





    // this.cities =JSON.parse(localStorage.getItem("cities"));


    this.pageProperty = JSON.parse(localStorage.getItem("pageProperty"));
 // this.titleService.setTitle(this.pageProperty.name);
    this.countryData = JSON.parse(localStorage.getItem("countries"));
    this.filteredOptions = this.countryData;
   

    // let locationDetails = localStorage.getItem("locationDetails");
    // console.log(locationDetails);
    // if()
    // let location = locationDetails['location'];
    // console.log(location);
    
      // this.locationService.getAllCountries()
      //   .subscribe((response: any) => {
      //     this.countryData = response;
      //     this.filteredOptions = response;
      //     console.log(response);
      //     localStorage.setItem("countries", JSON.stringify(this.countryData));
      //     let obj = this.countryData.find(c => c.phoneCode == 91);
      //     this.toSelect = obj;
      //     console.log("selected cousssntrddu", obj);
    
      //     //this.registrationForm.get('countryCode').setValue(this.toSelect);

      //   });


   

    
    

    this.cities = JSON.parse(localStorage.getItem("cities"));
    this.page = this.route.snapshot.params.page;
    

  }

  onChange(deviceValue) {
    // console.log(deviceValue);
    // this.toSelect = deviceValue;
    // this.registrationForm.get('countryCode').setValue(deviceValue);


  }

  

  onKey(value) {
    console.log(value);
    value = value.toLowerCase()
    if (!value) {

      console.log('fulldayta');

      this.countryData = this.filteredOptions;
    }
    else {
      console.log('dayta');
      this.countryData = this.filteredOptions;
      this.countryData = this.search(value);
    }
  }

  search(value: any): Country[] {
    let filter = value.toLowerCase();
    return this.countryData.filter(country => {
      return (
        country.displayName
          .toLowerCase()
          .includes(value) ||
        country.phoneCode.toString()
          .toLowerCase()
          .includes(value)
      );
    });

  }







 


  onsubmitSignup() {
  
      this.isSubmitted = true;

      if (this.registrationForm.invalid) {
        // alert("form invalid");
        return;
      } else {
        
          this.submitSignUpForm();
        
      }
    
  }

  clearAllForm() {

  }


  async submitSignUpForm() {

    
      console.log(this.registrationForm.value)
    
      

        var user = {
          
          "firstName": this.registrationForm.value.firstName,
          "lastName": "NA",
          "email": this.registrationForm.value.email,
          "phone":     this.registrationForm.value.phoneNumber,
          "restaurant" :this.registrationForm.value.restaurant,
          "area":this.registrationForm.value.area,
        };

        if(user.area=="")
        {
            user.area = "NA"
        }

        if(user.restaurant =="")
        {
          user.restaurant = "NA"
        }
        console.log(user);

        
      
      // await this.presentLoading('Loading ...');

      this.userService.signupForBusiness(user)
        .subscribe((res: any) => {

          console.log(res);
          //   this.loading.dismiss();
          if (res == null || res.displayMessage) {
            alert(res.displayMessage);
            return;
          }
          
         this.openSuccessDialog();

        }, error => {
          alert(error);

        });

    
  }

openSuccessDialog() {

    this.confirmationDialogService.confirm('Thank you for Information!', "We will get in touch with you shortly.", 1, "Close")
      .then((confirmed) => {
        console.log('User confirmed:', confirmed)

        this.router.navigate(['home']);

      })
      .catch(() => {
        this.router.navigate(['home']);

      });
     
      }

  async presentLoading(msg) {


  }

  async presentToast(msg) {

  }


} 
  


