<div class="modal-header" style="border-bottom: 0 none;width:max-content; min-width: 500px;">

  <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button> -->
 
</div>
<div class="modal-body text-center" style="color: #000000; font-size:16px; " *ngIf="numberOfButton===2">
  {{ message }}
</div>
<div class="modal-body text-center" style="color: #ff1123; font-size:16px; "*ngIf="numberOfButton===1">
 <p *ngIf="title.length>4"> {{ title }}</p>
  {{ message }}
</div>
<div class="modal-footer row justify-content-center" style="border-top: 0 none;border-bottom: 0 none;" *ngIf="numberOfButton===1">

    <button  type="button" class="btn" style = "
    background-color: var(--background-color,red);
border: none;
color: white;
padding: 10px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 12px;
margin: 5px;
cursor: pointer;
font-family: 'Poppins-Regular';
border-radius: var(--border-Radius);width:100px"(click)="accept()">{{ btnOkText }}</button>
    </div>
  <div class="modal-footer row justify-content-center" *ngIf="numberOfButton===2" style="border-top: 0 none;">
    <button type="button" class="btn"
    style = "
    background-color: var(--background-color,red);
border: none;
color: white;
padding: 10px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 12px;
margin: 5px;
cursor: pointer;
font-family: 'Poppins-Regular';
border-radius: var(--border-Radius);width:100px"
    (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn" style = "
    background-color: var(--background-color,red);
border: none;
color: white;
padding: 10px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 12px;
margin: 5px;
cursor: pointer;
font-family: 'Poppins-Regular';
border-radius: var(--border-Radius);width:100px" (click)="accept()">{{ btnOkText }}</button>
  </div>
