<div class="mainlayout">

  <header style="box-shadow:   none !important;;">
<app-main-header [isHomePage]="true" [pageName]="'home'"></app-main-header>
</header>

<main>

      <h6 class="text-center heading" style="margin-top: 48px;">{{pageProperty?.subTitle}}</h6>
      <div class="container">
        <div *ngFor="let item of areas; let i = index">
          <div *ngIf="i % 4 == 0" class="row justify-content-center p-3 m-4">
            <div class="col-lg-3 col-md-6 mb-6"  *ngIf="i + 0 < areas.length">


              <div class="card" style="padding-top:20px;background-color: white;height: 200px; width: 200px;" (click)="cityHomeCliked(areas[i+0])">
                <img class="mx-auto d-block" src="./assets/image/location-icon.png" alt="image" style="width:50%;float:center">
                <div class="card-body">

                  <p class="text-center">{{areas[i+0].name}}</p>
                </div>
              </div>
            </div>

              <!-- <div class="card-block" (click)="cityHomeCliked(cities[i+0])" style="cursor: pointer;">
                <img class="location-logo"  src="assets/image/location-icon.png" alt="logo" />
                <div class="centered">{{cities[i+0].cityName}}</div>
              </div>
            </div> -->

            <div class="col-lg-3 col-md-6 mb-6" *ngIf="i +1 < areas.length">

              <div class="card" style="padding-top:20px;background-color: white;height: 200px; width: 200px;" (click)="cityHomeCliked(areas[i+1])">
                <img class="mx-auto d-block" src="assets/image/location-icon.png" alt="image" style="width:50%;float:center">
                <div class="card-body">

                  <p class="text-center">{{areas[i+1].name}}</p>
                </div>
              </div>

            </div>

            <div class="col-lg-3 col-md-6 mb-6" *ngIf="i + 2 < areas.length">

              <div class="card" style="padding-top:20px;background-color: white;height: 200px; width: 200px;" (click)="cityHomeCliked(areas[i+2])">
                <img class="mx-auto d-block" src="assets/image/location-icon.png" alt="image" style="width:50%;float:center">
                <div class="card-body">

                  <p class="text-center">{{areas[i+2].name}}</p>
                </div>
              </div>

            </div>
            <div  class="col-lg-3 col-md-6 mb-6" *ngIf="i + 3 < areas.length">
              <div class="card" style="padding-top:20px;background-color: white;height: 200px; width: 200px;" (click)="cityHomeCliked(areas[i+3])">
                <img class="mx-auto d-block" src="assets/image/location-icon.png" alt="image" style="width:50%;float:center">
                <div class="card-body">

                  <p class="text-center">{{areas[i+3].name}}</p>
                </div>
              </div>


            </div>
            <!-- <div class="w-100"></div> -->


        </div>

        </div>

      <!-- <div class="row flex-row flex-nowrap" id="box" *ngFor = "let city of cities;let i = index;">
          <div class="col-md-3 mx-md-auto" >



          </div>-->



      </div>

    </main>





<!-- <app-common-footer [pageProperty]="pageProperty" [areas]="areas" ></app-common-footer> -->
</div>



