<div class="container text-center">
    <img [src]="pageProperty?.logo" width="200"  alt="" />
 
  <h3 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-family: 'Poppins-Regular';
  margin-bottom: 20px;margin-top: 20px;">{{pageProperty?.subTitle}}</h3>
  <h3 style="
  text-align: center;
  color: #000000;
  width: 100%;
  font-family: 'Poppins-SemiBold';
  margin-bottom: 20px;">Signup For Business</h3>
  
  <form [formGroup]="registrationForm" (ngSubmit)="onsubmitSignup()" class="form-horizontal">

    <mat-card>
      <mat-card-content>


        <div class="form-group">
          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" placeholder="Name"
                  formControlName="firstName" required>
                <mat-error>
                  Please provide a valid data
                </mat-error>
              </mat-form-field>


            </div>

         
          </div>






          <div class="row">
           
            <div class="col-md-12 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" type="tel" placeholder="Mobile Number"
                  formControlName="phoneNumber" required >
                <mat-error>
                  Please provide a valid mobile number
                </mat-error>
                
              </mat-form-field>
            </div>
          </div>





          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" type="email" placeholder="Email address"
                  formControlName="email" required >
                <mat-error>
                  Please enter valid email address
                </mat-error>
               
              </mat-form-field>

            </div>

          </div>

          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" type="text" placeholder="Business Name"
                  formControlName="restaurant" required >
               
                  <mat-error>
                    Please enter valid email address
                  </mat-error>
              </mat-form-field>

            </div>

          </div>

          <div class="row">
            <div class="col-md-12 coldesign">
              <mat-form-field>
                <input color="primary" matInput style="height: 20px;" type="text" placeholder="Area"
                  formControlName="area"  >
              
               
              </mat-form-field>

            </div>

          </div>

        </div>


      </mat-card-content>
      <mat-card-footer>
        <div class="row">
          <div class="form-group col-md-12" style="padding: 0px !important;">
            <button class="button"> Signup </button>

          </div>
        </div>
      </mat-card-footer>

    </mat-card>
  </form>
 

</div>

<!-- <app-common-footer></app-common-footer> -->